import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { MarkdownProse } from "./MarkdownProse";
import { ReactProse } from "./ReactProse";
export const Prose = ({ id, testId, as = "div", format = "React", children, }) => {
    const Element = as;
    let content = _jsx(_Fragment, {});
    switch (format) {
        case "Markdown":
            if (typeof children !== "string") {
                console.warn("Invalid markdown string passed into Prose component");
                break;
            }
            else {
                content = _jsx(MarkdownProse, { children: children });
                break;
            }
        case "MDX":
            content = _jsx(_Fragment, { children: children });
            break;
        default:
            content = _jsx(ReactProse, { children: children });
    }
    return (_jsx(Element, { id: id, "data-test-id": testId, "data-eco-component": "prose", className: "prose prose-sm md:prose-base dark:prose-invert max-w-full", children: content }));
};
