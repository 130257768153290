import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from "react";
import { WizardActions } from "./types";
const wizardReducer = (state, action) => {
    const { currentStep, steps } = state;
    let incrementValue = currentStep < steps.length - 1 ? currentStep + 1 : currentStep;
    let decrementValue = currentStep > 0 ? currentStep - 1 : currentStep;
    switch (action.type) {
        case WizardActions.SET_STEPS:
            return Object.assign(Object.assign({}, state), { 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                steps: action.payload.steps, 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                completedSteps: new Array(action.payload.steps.length).fill(0) });
        case WizardActions.INCREMENT_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { currentStep: incrementValue, canContinue: incrementValue < steps.length - 1 });
        case WizardActions.DECREMENT_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { currentStep: decrementValue, canContinue: decrementValue < steps.length - 1 });
        case WizardActions.SET_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { currentStep: action.payload.currentStep, canContinue: action.payload.currentStep < steps.length - 1 });
        case WizardActions.SET_CAN_CONTINUE:
            return Object.assign(Object.assign({}, state), { canContinue: action.payload.canContinue });
        case WizardActions.SET_RESTRICTIVE:
            return Object.assign(Object.assign({}, state), { restrictive: action.payload.restrictive });
        case WizardActions.ADD_COMPLETED_STEP:
            // Note: Developers must call this PRIOR to WizardActions.INCREMENT_CURRENT_STEP || WizardActions.DECREMENT_CURRENT_STEP.
            return Object.assign(Object.assign({}, state), { completedSteps: [...state.completedSteps].map((val, idx) => {
                    if (idx === state.currentStep)
                        return 1;
                    return val;
                }) });
        case WizardActions.REMOVE_COMPLETED_STEP:
            // Note: Developers must call this PRIOR to WizardActions.INCREMENT_CURRENT_STEP || WizardActions.DECREMENT_CURRENT_STEP.
            return Object.assign(Object.assign({}, state), { completedSteps: [...state.completedSteps].map((val, idx) => {
                    if (idx === state.currentStep)
                        return 0;
                    return val;
                }) });
        default:
            return state;
    }
};
export const WizardStateContext = createContext(undefined);
export const WizardDispatchContext = createContext(undefined);
export const WizardProvider = ({ startingIndex, children, }) => {
    const [state, dispatch] = useReducer(wizardReducer, {
        currentStep: startingIndex,
        canContinue: false,
        steps: [],
    });
    return (_jsx(WizardStateContext.Provider, { value: state, children: _jsx(WizardDispatchContext.Provider, { value: dispatch, children: children }) }));
};
