var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { Checkbox } from "../form";
import { IconV2 } from "../icon_v2";
export const ComplexMenuOption = ({ name, description, checked, value, selectedItems, }) => {
    const valueObj = { id: name, name, value };
    return (_jsx(Listbox.Option, { value: valueObj, as: Fragment, children: ({ active, selected }) => (_jsxs("li", { className: `text-primary bg-components-baseComponent dark:bg-dark-components-baseComponent hover:bg-buttons-tertiary-bg-hover hover:dark:bg-dark-buttons-tertiary-bg-active flex cursor-pointer rounded-md px-4 py-1`, children: [_jsx("div", { className: "mr-2 flex items-center justify-center", children: _jsx(Checkbox, { label: name, checked: checked, hideLabel: true, value: name }) }), _jsxs("div", { children: [_jsx("div", { className: "text-primaryText dark:text-dark-primaryText", children: name }), description !== undefined && (_jsx("div", { className: "text-tertiary dark:text-dark-tertiary text-xs", children: description }))] })] })) }));
};
export const ComplexMenuCategory = ({ name }) => {
    return (_jsx("li", { className: "text-tertiary dark:text-dark-tertiary px-2 pb-1 pt-4 text-sm", children: name }));
};
export const ComplexMenuAction = ({ url, icon, label, }) => {
    return (_jsxs(_Fragment, { children: [_jsx("hr", { className: "text-border-light dark:text-dark-border-light -mx-[0.5rem] my-1" }), _jsxs("a", { href: url, className: `hover:bg-buttons-tertiary-bg-hover hover:dark:bg-dark-buttons-tertiary-bg-active text-link dark:text-dark-link flex h-10 items-center justify-between whitespace-nowrap rounded-md px-2 py-1`, children: [_jsx("span", { children: label }), icon !== undefined && (_jsx("div", { className: "flex items-center", children: _jsx(IconV2, { type: "fa", icon: { icon: icon } }) }))] })] }));
};
export const ComplexMenu = (_a) => {
    var { id, testId, icon, label, onChange, children } = _a, props = __rest(_a, ["id", "testId", "icon", "label", "onChange", "children"]);
    const [selected, setSelected] = useState([]);
    const handleChange = () => {
        onChange(selected);
    };
    const updateSelected = (test) => {
        if (!selected.some((item) => item.value === test.at(-1).value)) {
            setSelected((prev) => [...prev, test.at(-1)]);
        }
        else {
            setSelected(selected.filter((item) => item.value !== test.at(-1).value));
        }
    };
    useEffect(() => {
        handleChange();
    }, [selected]);
    return (_jsx("div", { children: _jsxs(Listbox, { value: selected, onChange: updateSelected, multiple: true, children: [_jsx(Listbox.Button, { className: "dark:text-dark-primaryText text-primaryText mb-2 flex gap-x-2", children: icon !== undefined ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "sr-only", children: label }), _jsx(IconV2, { type: "fa", icon: { icon: icon } })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: "font-bold", children: label }), _jsx(IconV2, { type: "fa", icon: { icon: ["far", "chevron-down"] } })] })) }), _jsx(Listbox.Options, { className: "border-light dark:border-dark-light bg-components-baseComponent dark:bg-dark-components-baseComponent absolute z-10 max-w-80 overflow-hidden rounded-md border px-1 py-2 shadow-md", children: React.Children.map(children, (child, index) => {
                        if (React.isValidElement(child) &&
                            child.type === ComplexMenuOption) {
                            // console.log()
                            return React.cloneElement(child, Object.assign({ selectedItems: selected, checked: selected.some((item) => item.value ===
                                    child.props.value) }, props));
                        }
                        return child;
                    }) })] }) }));
};
ComplexMenu.Option = ComplexMenuOption;
ComplexMenu.Category = ComplexMenuCategory;
ComplexMenu.Action = ComplexMenuAction;
ComplexMenuOption.displayName = "ComplexMenu.Option";
ComplexMenuCategory.displayName = "ComplexMenu.Category";
ComplexMenuAction.displayName = "ComplexMenu.Action";
