import { jsx as _jsx } from "react/jsx-runtime";
const Badge = ({ items, firstValue }) => {
    const title = items.join(", ");
    const { length } = items;
    let label = `+ ${length}`;
    if (firstValue === "all") {
        label = `${length} selected`;
    }
    return _jsx("div", { title: title, children: label });
};
export default Badge;
