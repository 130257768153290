import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { IconV2 } from "../icon_v2";
export const CountdownTimer = ({ id, testId, label, seconds, minutes = 0, pause = false, restart = false, actionIcon, actionHandler, size = "md", onStart, onComplete, children, }) => {
    const [minutesLeft, setMinutesLeft] = useState(minutes);
    const [secondsLeft, setSecondsLeft] = useState(seconds);
    const [timerComplete, setTimerComplete] = useState(false);
    let interval;
    // call any outside methods that are listening for the Timer to start
    useEffect(() => {
        onStart === null || onStart === void 0 ? void 0 : onStart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // here is where the tick actually happens
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        interval = setInterval(() => {
            if (!pause) {
                if (secondsLeft > 0) {
                    setSecondsLeft((s) => s - 1);
                }
                else {
                    if (minutesLeft > 0) {
                        setMinutesLeft((m) => m - 1);
                        setSecondsLeft(59);
                    }
                    else {
                        setSecondsLeft(0);
                        setTimerComplete(true);
                        clearInterval(interval);
                        onComplete === null || onComplete === void 0 ? void 0 : onComplete();
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [secondsLeft, pause]);
    const restartTimer = (sec, min) => {
        setSecondsLeft(sec);
        setMinutesLeft(min);
        setTimerComplete(false);
        onStart === null || onStart === void 0 ? void 0 : onStart();
    };
    useEffect(() => {
        restartTimer(seconds, minutes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restart]);
    const transformToClock = (num) => {
        return num < 10 ? "0" + num : num.toString();
    };
    // TODO: XD file has a "refresh" action but it is very specific to a Hermes use case. I don't think we want this baked into the timer.
    // Having the refresh available internally can be used now for restarting/refreshing the timer or if the user declares a "refreshHandler", that can be used
    const actionClickHandler = () => {
        if (actionHandler) {
            actionHandler();
        }
    };
    const sizeVariant = {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-base",
        lg: "text-lg",
        xl: "text-xl",
        "2xl": "text-2xl",
        "3xl": "text-3xl",
        "4xl": "text-4xl",
    };
    const styles = `flex items-center w-fit ${sizeVariant[size]} space-x-2 text-pill-text-info dark:text-dark-pill-text-info rounded-full bg-pill-bg-info dark:bg-dark-pill-bg-info py-2 px-4`;
    return (_jsx(_Fragment, { children: _jsxs("div", { className: styles, id: id, "data-test-id": testId, "data-eco-component": "countdown-timer", children: [actionIcon !== undefined && (_jsx("button", { id: "countdown-timer-action-icon", onClick: () => actionClickHandler(), className: "cursor-pointer hover:text-blue-400", children: _jsx(IconV2, { type: "fa", icon: {
                            icon: ["far", actionIcon],
                            fixedWidth: true,
                        } }) })), label !== undefined && _jsx("div", { id: "countdown-timer-label", children: label }), _jsxs("div", { className: "flex", children: [minutes !== 0 && (_jsxs(_Fragment, { children: [_jsx("div", { id: "countdown-timer-minutes", children: transformToClock(minutesLeft) }), _jsx("div", { className: "px-1", children: ":" })] })), _jsxs("div", { id: "countdown-timer-seconds", children: [transformToClock(secondsLeft), minutes === 0 && `s`] })] }), timerComplete && _jsx(_Fragment, { children: children })] }) }));
};
export default CountdownTimer;
