import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
import { Tooltip } from "../../tooltip";
export const Label = ({ id, testId, htmlFor, label, name, optional, required, children, hideLabel, disabled, tooltipText = "", labelPosition = "top", enableChangeIndicatorUI, initialValueChanged, handleChangeIndicatorReset, }) => {
    const srOnlyClass = hideLabel ? `relative` : ``;
    const hideLabelClass = hideLabel === true ? ` sr-only` : `flex`;
    const disabledClasses = disabled === true ? "" : "cursor-pointer";
    const spacingClass = hideLabel === true ? "" : "space-y-0.5";
    if (labelPosition === "top") {
        return (_jsxs("label", { id: `${id}-label`, htmlFor: htmlFor, "data-test-id": testId, "data-eco-component": "label", className: `text-label dark:text-dark-label block ${spacingClass} font-normal leading-5 sm:text-sm ${disabledClasses} ${srOnlyClass} ${enableChangeIndicatorUI !== undefined &&
                enableChangeIndicatorUI &&
                initialValueChanged === true
                ? "relative"
                : " "}`, children: [enableChangeIndicatorUI !== undefined &&
                    enableChangeIndicatorUI &&
                    initialValueChanged === true ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex", children: _jsx("span", { className: hideLabelClass, children: _jsxs("span", { className: "flex w-full justify-between", children: [_jsx("span", { children: label }), _jsxs("span", { children: [(required !== null && required !== void 0 ? required : false) && "Required", (optional !== null && optional !== void 0 ? optional : false) && "Optional"] })] }) }) }), _jsx("a", { onClick: handleChangeIndicatorReset, className: "text-sm text-blue-600 sm:block md:absolute md:right-0 md:top-0 md:inline", "aria-hidden": enableChangeIndicatorUI, role: "button", tabIndex: 0, children: "reset to default" })] })) : (_jsx(_Fragment, { children: _jsx("span", { className: hideLabelClass, children: _jsxs("span", { className: "flex w-full justify-between", children: [_jsxs("span", { className: "flex text-sm", children: [label, tooltipText && (_jsx("span", { className: "dark:text-dark-primaryText ml-2 flex items-center", children: _jsx(Tooltip, { id: "tooltip-id", label: tooltipText, tooltipOffset: 12, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", className: "fill-text-primaryText dark:fill-dark-text-primaryText h-[14px] w-[14px]", children: _jsx("path", { d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" }) }) }) }))] }), _jsxs("span", { className: "flex items-center", children: [(required !== null && required !== void 0 ? required : false) && "Required", (optional !== null && optional !== void 0 ? optional : false) && "Optional"] })] }) }) })), children] }));
    }
    return (_jsx("div", { id: id, "data-test-id": testId, className: "@container/label", children: _jsxs("div", { className: `@xl:grid @xl/label:grid-cols-3 @xl/label:items-stretch @xl/label:gap-4 ${disabledClasses}`, children: [_jsx("label", { id: `${id}-label`, "data-test-id": testId, "data-eco-component": "label", htmlFor: htmlFor, className: "text-label block flex items-center text-sm font-normal leading-5 dark:text-gray-300", children: _jsxs("span", { className: hideLabelClass, children: [label, (required !== null && required !== void 0 ? required : false) && "*", tooltipText && (_jsx("span", { className: "dark:text-dark-primaryText ml-2 flex items-center", children: _jsx(Tooltip, { id: "tooltip-id", label: tooltipText, tooltipOffset: 12, children: _jsx(IconV2, { type: "fa", icon: { icon: ["fas", "circle-info"] } }) }) }))] }) }), _jsxs("div", { className: "@xl/label:col-span-2 @xl/label:mt-0 mt-1", children: [enableChangeIndicatorUI !== undefined &&
                            enableChangeIndicatorUI &&
                            initialValueChanged === true && (_jsx("span", { onClick: handleChangeIndicatorReset, className: "flex justify-end text-sm text-blue-600", "aria-hidden": enableChangeIndicatorUI, role: "button", tabIndex: 0, children: "reset to default" })), children] })] }) }));
};
export default Label;
