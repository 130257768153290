// Interfaces for use-environment.tsx
export var Viewport;
(function (Viewport) {
    Viewport["XL"] = "xl";
    Viewport["L"] = "l";
    Viewport["M"] = "m";
    Viewport["S"] = "s";
})(Viewport || (Viewport = {}));
export var Orientation;
(function (Orientation) {
    Orientation["Landscape"] = "landscape";
    Orientation["Portrait"] = "portrait";
})(Orientation || (Orientation = {}));
