import { jsx as _jsx } from "react/jsx-runtime";
import { components } from "react-select";
import { IconV2 } from "../../icon_v2";
const MultiValueRemove = (props) => {
    const selectedValues = props.selectProps.value;
    const { selectProps } = props;
    const { collapsedSelectedValues } = selectProps;
    const [first] = selectedValues;
    if (collapsedSelectedValues === true && props.data.value !== first.value) {
        return (_jsx(components.MultiValueRemove, Object.assign({}, props, { children: _jsx("div", { className: "bg-pill-text-default dark:bg-dark-pill-text-default text-dark-pill-text-default dark:text-pill-text-default mx-1 block flex size-4 min-w-[16px] items-center justify-center rounded-full", children: _jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "xmark"],
                        fixedWidth: true,
                        size: "xs",
                    } }) }) })));
    }
    return (_jsx(components.MultiValueRemove, Object.assign({}, props, { children: _jsx("div", { className: "bg-pill-text-default dark:bg-dark-pill-text-default text-dark-pill-text-default dark:text-pill-text-default mx-1 block flex size-4 min-w-[16px] items-center justify-center rounded-full", children: _jsx(IconV2, { type: "fa", icon: {
                    icon: ["far", "xmark"],
                    fixedWidth: true,
                    size: "xs",
                } }) }) })));
};
export default MultiValueRemove;
