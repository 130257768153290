import { jsx as _jsx } from "react/jsx-runtime";
import { IconV2, convertIcon, needsConversion } from "../icon_v2";
export const Button = ({ onClick, disabled, icon, arialabel, }) => {
    const convertedIcon = icon !== undefined && !Array.isArray(icon)
        ? needsConversion(icon)
            ? convertIcon(icon)
            : icon
        : icon !== undefined && Array.isArray(icon)
            ? icon
            : undefined;
    return (_jsx("button", { type: "button", className: `text-buttons-tertiary-text dark:text-dark-buttons-tertiary-text hover:bg-buttons-tertiary-bg-hover hover:dark:bg-dark-buttons-tertiary-bg-hover focus:ring-buttons-tertiary-focus active:bg-buttons-tertiary-bg-active disabled:text-buttons-text-disabled shrink-0 basis-11 bg-transparent px-2 py-2.5 first:rounded-l-md last:rounded-r-md focus:ring-2 disabled:opacity-50 disabled:hover:bg-transparent disabled:active:bg-transparent`, onClick: onClick, disabled: disabled, tabIndex: 0, "aria-label": arialabel, children: _jsx("span", { className: "pointer-events-none flex items-center justify-center", children: _jsx(IconV2, { type: "fa", icon: { icon: convertedIcon } }) }) }));
};
export default Button;
