import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Icon } from "../../icon";
import withDeprecation from "../../helpers/deprecated";
export const Heading = withDeprecation(({ as = "h3", size, mode = "default", variant = "default", useAnchor = false, testId, id, children, }) => {
    const Element = as;
    let headingSize = size;
    if (size) {
        headingSize = size;
    }
    else {
        switch (as) {
            case "h1":
                headingSize = "xl";
                break;
            case "h2":
                headingSize = "lg";
                break;
            case "h3":
                headingSize = "md";
                break;
            default:
                headingSize = "sm";
        }
    }
    const lineHeight = {
        default: {
            h1: "leading-10",
            h2: "leading-8",
            h3: "leading-6",
            h4: "leading-6",
            h5: "leading-6",
            h6: "leading-4",
            p: "leading-4",
        },
        display: {
            h1: "leading-15",
            h2: "leading-14",
            h3: "leading-13",
            h4: "leading-12",
            h5: "leading-11",
            h6: "leading-10",
            p: "",
        },
    };
    const customFontSize = {
        sm: "text-sm md:text-base",
        md: "text-md md:text-lg",
        lg: "text-lg md:text-xl",
        xl: "text-xl md:text-2xl",
        xxl: "text-3xl md:text-4xl",
        xxxl: "text-4xl md:text-5xl",
    };
    const fontSize = {
        default: {
            h1: "text-3xl",
            h2: "text-2xl",
            h3: "text-xl",
            h4: "text-lg",
            h5: "text-base",
            h6: "text-sm",
            p: "text-sm",
        },
        display: {
            h1: "text-9xl",
            h2: "text-8xl",
            h3: "text-7xl",
            h4: "text-6xl",
            h5: "text-5xl",
            h6: "text-4xl",
            p: "",
        },
    };
    const fontColor = {
        primary: "text-primaryText dark:text-dark-primaryText",
        positive: "text-success dark:text-dark-success",
        negative: "text-error dark:text-dark-error",
        caution: "text-orange-600",
        warning: "text-warning-600",
        default: "text-primaryText dark:text-dark-primaryText",
    };
    const [hovering, setHovering] = useState(false);
    const setAnchor = () => {
        var _a;
        window.location.hash = `#${id}`;
        (_a = document.querySelector(`#${id}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "auto" });
    };
    const useAnchorStyles = useAnchor ? `cursor-pointer scroll-m-18` : ``;
    return (_jsx(_Fragment, { children: _jsxs(Element, { id: id, "data-test-id": testId, "data-eco-component": "heading", className: `font-bold ${size !== undefined
                ? customFontSize[headingSize]
                : fontSize[mode][as]} ${lineHeight[mode][as]} ${fontColor[variant]} ${useAnchorStyles} [&>a]:text-link [&>a]:dark:text-link [&>a]:hover:text-link-hover [&>a]:dark:hover:text-dark-link-hover text-primaryText dark:text-dark-primaryText block pr-2`, onMouseOver: useAnchor ? () => setHovering(true) : undefined, onMouseOut: useAnchor ? () => setHovering(false) : undefined, onClick: useAnchor ? () => setAnchor() : undefined, children: [children, useAnchor && (_jsx("div", { className: hovering
                        ? `visible ml-2 inline-flex`
                        : `invisible ml-2 inline-flex`, children: _jsx(Icon, { icon: "fa-regular fa-link", size: "sm", color: "primary" }) }))] }) }));
}, "The Heading component no longer uses the variant prop");
