var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { RadiogroupRadio } from "./RadiogroupRadio";
export const Radiogroup = (_a) => {
    var _b, _c;
    var { children } = _a, props = __rest(_a, ["children"]);
    const { group, selected, disabled, invalid } = props;
    const [selectedRadio, setSelectedRadio] = useState(selected);
    const handleChange = (e) => {
        setSelectedRadio(e.target.value);
        props.onChange(e);
    };
    useEffect(() => {
        setSelectedRadio(selected !== null && selected !== void 0 ? selected : "");
    }, [selected]);
    // rename group disabled so the disabled prop doesn't override the disable prop
    const groupDisable = disabled !== null && disabled !== void 0 ? disabled : false;
    const labelClasses = `mb-3 font-bold${props.hideLabel === true ? "sr-only" : ""} ${groupDisable
        ? "text-gray-400 dark:disabled:text-gray-300"
        : "text-gray-800 dark:text-gray-300"}`;
    return (_jsxs("div", { role: "radiogroup", id: props.id, "data-test-id": props.testId, "data-eco-component": "radiogroup", onChange: handleChange, "aria-labelledby": `rg${group}`, "aria-invalid": invalid, children: [_jsx("div", { className: labelClasses, id: `rg${group}`, children: _jsxs("span", { className: "flex w-full justify-between", children: [_jsx("span", { children: props.label }), _jsxs("span", { className: "font-normal", children: [((_b = props.required) !== null && _b !== void 0 ? _b : false) && "Required", ((_c = props.optional) !== null && _c !== void 0 ? _c : false) && "Optional"] })] }) }), _jsx("div", { className: "space-y-3", children: React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            group,
                            selected: selectedRadio,
                            groupDisable,
                            invalid,
                        });
                    }
                    return child;
                }) })] }));
};
Radiogroup.Radio = RadiogroupRadio;
RadiogroupRadio.displayName = "Radiogroup.Radio";
