import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastContainer, toast, Flip, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import "./snackbar.css";
import { IconV2 } from "../icon_v2";
export const SnackbarContent = ({ closeToast, variant, message, onAction, id, testId, onDismiss, }) => {
    let variantType = variant !== null && variant !== void 0 ? variant : "positive";
    let variantBgColor;
    let icon;
    switch (variantType) {
        case "positive":
            variantBgColor =
                "bg-alerts-background-success dark:bg-dark-alerts-background-success dark:text-dark-light text-light";
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-check"], size: "xl" } }));
            break;
        case "negative":
            variantBgColor =
                "bg-alerts-background-error dark:bg-dark-alerts-background-error dark:text-dark-light text-light";
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-exclamation"], size: "xl" } }));
            break;
        case "info":
        default:
            variantBgColor =
                "bg-alerts-background-information dark:bg-dark-alerts-background-information dark:text-dark-light text-light";
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-info"], size: "xl" } }));
            break;
    }
    const handleDismiss = () => {
        if (onDismiss !== undefined)
            onDismiss();
        toast.clearWaitingQueue();
        if (closeToast !== undefined)
            closeToast();
    };
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "snackbar", className: "align-center dark:border-dark-light dark:bg-dark-container-light dark:text-dark-primaryText text-primaryText bg-container-light z-10 flex h-11 min-w-[260px] max-w-[400px] justify-between overflow-hidden rounded-md shadow-md dark:border", children: [_jsxs("div", { className: "flex items-center justify-center", children: [_jsx("div", { className: `${variantBgColor} mr-3 flex min-h-11 w-11 items-center justify-center p-4`, "data-eco-component": "snackbar-variant", children: icon }), _jsx("div", { className: "flex-wrap self-center text-xs font-normal", children: message })] }), _jsxs("div", { className: "flex items-center justify-center gap-x-1 pl-3 pr-1", children: [onAction !== undefined && (_jsx("button", { className: "sm dark:text-dark-alerts-text-information text-alerts-text-information w-9 text-sm underline underline-offset-2 hover:text-blue-600", onClick: onAction, children: "Undo" })), _jsxs("button", { className: "flex h-8 w-8 items-center justify-center text-sm font-normal", "data-eco-component": "snackbar-close", onClick: handleDismiss, children: [_jsx("span", { className: "sr-only", children: "Dismiss" }), _jsx("svg", { className: "h-2.5 w-2.5", stroke: "currentColor", fill: "none", viewBox: "0 0 8 8", children: _jsx("path", { strokeLinecap: "round", strokeWidth: "1.5", d: "M1 1l6 6m0-6L1 7" }) })] })] })] }));
};
export const displaySnackbar = ({ message, variant, onAction, onDismiss, containerId, }) => {
    const toastId = toast(_jsx(SnackbarContent, { variant: variant, message: message, onAction: onAction, onDismiss: onDismiss }), {
        containerId,
    });
    return toastId;
};
export const Snackbar = ({ id, testId, autoClose, containerId, position = "bottom-right", }) => {
    if (position === "custom") {
        if (containerId === undefined) {
            console.log("containerId is required when position is `custom`");
        }
    }
    const transition = position === "custom" ? Flip : Slide;
    const customPlacementCSS = position === "custom" ? "!static" : "";
    const customPosition = position === "custom" ? "bottom-right" : position;
    const customPlacementAutoClose = position === "custom" && autoClose === undefined
        ? false
        : autoClose !== null && autoClose !== void 0 ? autoClose : 4000;
    return (_jsx("div", { id: id, "data-testid": testId, "data-eco-component": "snackbar-container", children: _jsx(ToastContainer, { autoClose: customPlacementAutoClose, className: `${customPlacementCSS}`, closeButton: false, closeOnClick: false, containerId: containerId, enableMultiContainer: true, hideProgressBar: true, limit: 5, position: customPosition, transition: transition, toastClassName: `shadow-none bg-transparent` }) }));
};
