import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Select } from "../form";
export const MapSettings = ({ id, testId, selectedBaseMap = "DARK", selectedObservationDuration = "1hr", selectedForecastDuration = "1hr", selectedAnimationSpeed = 1, selectedDwell = "none", onObservationDurationChange, onForecastDurationChange, onAnimationSpeedChange, onDwellChange, onBaseMapChange, }) => {
    const [baseMap, setBaseMap] = useState(selectedBaseMap);
    const [observationDuration, setObservationDuration] = useState(selectedObservationDuration);
    const [forecastDuration, setForecastDuration] = useState(selectedForecastDuration);
    const [animationSpeed, setAnimationSpeed] = useState(selectedAnimationSpeed);
    const [dwell, setDwell] = useState(selectedDwell);
    const mapStyleOptions = [
        { value: "STREETS", label: "Streets" },
        { value: "OUTDOORS", label: "Outdoors" },
        { value: "LIGHT", label: "Light" },
        { value: "DARK", label: "Dark" },
        { value: "SATELLITE", label: "Satellite" },
        { value: "SATELLITE-STREET", label: "Satellite Street" },
        { value: "NAVIGATION-DAY", label: "Navigation Day" },
        { value: "NAVIGATION-NIGHT", label: "Navigation Night" },
    ];
    const observationDurationOptions = [
        { label: "1 Hour", value: "1hr" },
        { label: "3 Hours", value: "3hr" },
        { label: "6 Hours", value: "6hr" },
        { label: "12 Hours", value: "12hr" },
        { label: "24 Hours", value: "24hr" },
        { label: "48 Hours", value: "48hr" },
    ];
    const forecastDurationOptions = [
        { label: "1 Hour", value: "1hr" },
        { label: "3 Hours", value: "3hr" },
        { label: "6 Hours", value: "6hr" },
        { label: "12 Hours", value: "12hr" },
        { label: "24 Hours", value: "24hr" },
        { label: "2 Days", value: "2d" },
        { label: "5 Days", value: "5d" },
        { label: "7 Days", value: "7d" },
        { label: "10 Days", value: "10d" },
        { label: "14 Days", value: "14d" },
    ];
    const animationSpeedOptions = [
        { label: "0.25x", value: "0.25" },
        { label: "0.5x", value: "0.5" },
        { label: "1x", value: "1" },
        { label: "2x", value: "2" },
        { label: "4x", value: "4" },
    ];
    const dwellOptions = [
        { label: "None", value: "none" },
        { label: "Short", value: "short" },
        { label: "Normal", value: "normal" },
        { label: "Long", value: "long" },
    ];
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "eco-map-settings", className: "bg-container-light dark:bg-dark-container-light\n      text-primaryText dark:text-dark-primaryText flex h-full flex-col space-y-4 p-2", children: [_jsx("div", { className: "text-tertiary dark:text-dark-tertiary", children: "Settings" }), _jsx(Select, { id: "map-style", options: mapStyleOptions, label: "Base Map", name: "baseMap", onChange: (e) => {
                    setBaseMap(e.target.value);
                    onBaseMapChange(e);
                }, selected: baseMap }), _jsx(Select, { id: "observation-duration", options: observationDurationOptions, label: "Observation Duration", name: "observationDuration", onChange: (e) => {
                    setObservationDuration(e.target.value);
                    onObservationDurationChange(e);
                }, selected: observationDuration }), _jsx(Select, { id: "forecast-duration", options: forecastDurationOptions, label: "Forecast Duration", name: "forecastDuration", onChange: (e) => {
                    setForecastDuration(e.target.value);
                    onForecastDurationChange(e);
                }, selected: forecastDuration }), _jsx(Select, { id: "animation-speed", options: animationSpeedOptions, label: "Animation Speed", name: "animationSpeed", onChange: (e) => {
                    setAnimationSpeed(parseInt(e.target.value, 10));
                    onAnimationSpeedChange(e);
                }, selected: animationSpeed.toString() }), _jsx(Select, { id: "dwell", options: dwellOptions, label: "Dwell", name: "dwell", onChange: (e) => {
                    setDwell(e.target.value);
                    onDwellChange(e);
                }, selected: dwell })] }));
};
