import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { CustomIcon } from "./CustomIcon";
export const Icon = ({ id, testId, className, icon, size = "md", color, family, altText, }) => {
    /* This is "loaded" var is a workaround for react 18 hydration error with FA, we
       should be able to remove this when fontawesome npm packages are added
       https://github.com/facebook/react/issues/23068 */
    const [loaded, setLoaded] = useState(false);
    const isUrl = icon.includes("http");
    useEffect(() => {
        setLoaded(true);
    }, []);
    let iconSize;
    switch (size) {
        case "xxs":
            iconSize = isUrl ? "h-10 w-10" : "w-2 h-2";
            break;
        case "xs":
            iconSize = isUrl ? "h-12 w-12" : "w-4 h-4";
            break;
        case "sm":
            iconSize = isUrl ? "h-16 w-16" : "w-5 h-5";
            break;
        case "lg":
            iconSize = isUrl ? "h-24 w-24" : "w-12 h-12";
            break;
        case "xl":
            iconSize = isUrl ? "h-32 w-32" : "w-14 h-14";
            break;
        case "md":
        default:
            iconSize = isUrl ? "h-20 w-20" : "w-6 h-6";
            break;
    }
    let iconColor;
    switch (color) {
        case "white":
            iconColor = "text-white";
            break;
        case "red":
            iconColor = "text-red-600";
            break;
        case "orange":
            iconColor = "text-orange-500";
            break;
        case "amber":
            iconColor = "text-amber-500";
            break;
        case "yellow":
            iconColor = "text-amber-500";
            break;
        case "lime":
            iconColor = "text-lime-500";
            break;
        case "green":
            iconColor = "text-green-500";
            break;
        case "emerald":
            iconColor = "text-emerald-500";
            break;
        case "teal":
            iconColor = "text-teal-500";
            break;
        case "cyan":
            iconColor = "text-sky-500";
            break;
        case "sky":
            iconColor = "text-sky-500";
            break;
        case "blue":
            iconColor = "text-blue-500";
            break;
        case "indigo":
            iconColor = "text-indigo-500";
            break;
        case "violet":
            iconColor = "text-indigo-500";
            break;
        case "purple":
            iconColor = "text-purple-500";
            break;
        case "fuchsia":
            iconColor = "text-purple-500";
            break;
        case "pink":
            iconColor = "text-pink-500";
            break;
        case "rose":
            iconColor = "text-rose-500";
            break;
        case "slate":
            iconColor = "text-slate-500";
            break;
        case "gray":
            iconColor = "text-gray-500";
            break;
        case "primary":
            iconColor = "text-blue-500";
            break;
        case "positive":
            iconColor = "text-green-500";
            break;
        case "negative":
            iconColor = "text-red-500";
            break;
        case "caution":
            iconColor = "text-orange-500";
            break;
        case "warning":
            iconColor = "text-warning-500";
            break;
        case "base":
            iconColor = "text-gray-500";
            break;
        default:
            iconColor = "text-current";
            break;
    }
    const customStyle = className === undefined ? `${iconSize} ${iconColor}` : `${className}`;
    if (isUrl) {
        return (_jsx("div", { id: id, "data-eco-component": "icon-component", children: _jsx("img", { src: icon, className: `inline-flex items-center align-middle ${customStyle}`, alt: altText }) }));
    }
    if (family !== undefined && !icon.includes("fa-")) {
        return (_jsx(CustomIcon, { id: id, testId: testId, "data-eco-component": "icon-component", icon: icon, size: size, className: customStyle, family: family }));
    }
    return (_jsx("span", { children: loaded && (_jsx("i", { id: id, suppressHydrationWarning: true, "data-test-id": testId, "data-eco-component": "icon-component", className: `${icon} inline-flex justify-center align-middle ${customStyle}` }, icon)) }, `wrapper-${icon}`));
};
