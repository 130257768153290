var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
export const CustomIcon = ({ id, testId, icon, size, className, family = "weather-1", }) => {
    const baseUrl = "https://static-assets.dtn.com";
    const path_family = family === "eco-1" ? "icons/eco/v1" : "icons/weather-conditions/v1";
    let [requestedIcon, setRequestedIcon] = useState();
    let iconPath = `${baseUrl}/${path_family}`;
    let iconSize;
    switch (size) {
        case "xxs":
            iconSize = "h-10 w-10";
            break;
        case "xs":
            iconSize = "h-12 w-12";
            break;
        case "sm":
            iconSize = "h-16 w-16";
            break;
        case "lg":
            iconSize = "h-24 w-24";
            break;
        case "xl":
            iconSize = "h-32 w-32";
            break;
        case "md":
        default:
            iconSize = "h-20 w-20";
            break;
    }
    let isMounted = useRef(true);
    useEffect(() => {
        isMounted.current = true;
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield (yield fetch(`${iconPath}/${icon}.svg`)).text();
            let parser = new DOMParser();
            let elem = parser.parseFromString(response, "image/svg+xml");
            let svgElem = elem.documentElement;
            if (isMounted.current) {
                svgElem.setAttribute("aria-describedby", icon);
                setRequestedIcon(svgElem);
            }
        }))();
        return () => {
            isMounted.current = false;
        };
    }, [icon, iconPath]);
    if (family === "eco-1") {
        requestedIcon === null || requestedIcon === void 0 ? void 0 : requestedIcon.setAttribute("class", className);
    }
    // eslint-disable-next-line no-console
    console.warn(`ECO DEPRECATION WARNING: Icon "${icon}" is deprecated, please use "fa-regular fa-${icon}".`);
    if (requestedIcon) {
        if (family === "eco-1") {
            return (_jsx("span", { id: id, "data-test-id": testId, "data-eco-component": "custom-icon", dangerouslySetInnerHTML: {
                    __html: DOMPurify.sanitize(requestedIcon.outerHTML),
                } }));
        }
        return (_jsx("div", { id: id, "data-eco-component": "weather-icon", children: _jsx("img", { src: `${iconPath}/${icon}.svg`, className: iconSize, alt: icon }) }));
    }
    return null;
};
