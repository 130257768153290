import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox } from "react-instantsearch-hooks-web";
// Include only the reset
// import 'instantsearch.css/themes/reset.css';
// or include the full Satellite theme
import "instantsearch.css/themes/satellite.css";
import { Icon as IconComponent } from "../index";
import CustomNoResultsBoundary from "./CustomNoResultsBoundary";
import CustomNoResults from "./CustomNoResults";
import CustomHitsHook from "./CustomHitsHook";
export const Search = ({ appID, apiKey, indexName, isSearchActive = false, setIsSearchActive, id, }) => {
    const searchClient = algoliasearch(appID, apiKey);
    return (_jsx(Transition, { appear: true, show: isSearchActive, as: Fragment, children: _jsxs(Dialog, { as: "div", id: id, "data-eco-component": "search", className: "relative z-[110]", onClose: setIsSearchActive, children: [_jsx(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: _jsx("div", { className: "fixed inset-0 bg-black bg-opacity-25" }) }), _jsx(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95", children: _jsx("div", { className: "fixed bottom-0 left-0 right-0 top-2 overflow-y-auto", children: _jsx("div", { className: "flex items-center justify-center p-4", children: _jsxs(Dialog.Panel, { className: "w-full max-w-lg transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all", children: [_jsx("div", { className: "block h-6", children: _jsxs("button", { type: "button", className: "absolute right-4 top-1.5 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-6 lg:top-1.5", onClick: () => 
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
                                            setIsSearchActive((prevState) => !prevState), children: [_jsx("span", { className: "sr-only", children: "Close" }), _jsx(IconComponent, { icon: "x", size: "xs" })] }) }), _jsxs(InstantSearch, { searchClient: searchClient, indexName: indexName, children: [_jsx(SearchBox, { placeholder: "Search", autoFocus: true, classNames: {
                                                    input: "pl-10",
                                                } }), _jsx(CustomNoResultsBoundary, { fallback: _jsx(CustomNoResults, {}), children: _jsx(CustomHitsHook, {}) })] })] }) }) }) })] }) }));
};
export default Search;
