import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useInstantSearch } from "react-instantsearch-hooks-web";
const CustomNoResultsBoundary = ({ children, fallback, }) => {
    const { results } = useInstantSearch();
    // The `__isArtificial` flag makes sure to not display the No Results message
    // when no hits have been returned yet.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!results.__isArtificial && results.nbHits === 0) {
        return (_jsxs(_Fragment, { children: [fallback, _jsx("div", { hidden: true, children: children })] }));
    }
    return children;
};
export default CustomNoResultsBoundary;
