import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { isValidElement } from "react";
import { DescriptionListItem } from "./DescriptionListItem";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const DescriptionList = ({ id, testId, title, description, striped = false, children }) => {
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "description-list", children: [title !== undefined && (_jsx("h3", { className: classNames(striped ? "px-4" : "", "text-lg font-medium leading-6 text-gray-900 dark:text-gray-300"), children: title })), description !== undefined && (_jsx("p", { className: classNames(striped ? "px-4" : "", "mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-300"), children: description })), _jsx("div", { className: "mt-5 border-t border-gray-200 dark:border-gray-800", children: _jsx("dl", { className: classNames(striped ? "" : "divide-y divide-gray-200 dark:divide-gray-800"), children: React.Children.map(children, (child) => {
                        if (isValidElement(child)) {
                            return _jsx(DescriptionListItem, Object.assign({}, child.props, { striped: striped }));
                        }
                        return child;
                    }) }) })] }));
};
DescriptionList.Item = DescriptionListItem;
DescriptionListItem.displayName = "DescriptionList.Item";
export default DescriptionList;
