var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from "react";
import { Tooltip } from "../tooltip";
import { IconV2, convertIcon, needsConversion } from "../icon_v2";
const ButtonForGroup = (_a) => {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var { id, testId, onClick, onBlur, onFocus, icon, iconName, rightIcon, index, length, size, children } = _a, props = __rest(_a, ["id", "testId", "onClick", "onBlur", "onFocus", "icon", "iconName", "rightIcon", "index", "length", "size", "children"]);
    let disabled = (_b = props.disabled) !== null && _b !== void 0 ? _b : false;
    let type = (_c = props.type) !== null && _c !== void 0 ? _c : "button";
    let hideLabel = (_d = props.hideLabel) !== null && _d !== void 0 ? _d : false;
    let checked = (_e = props.checked) !== null && _e !== void 0 ? _e : false;
    let borderStyle = "border border-transparent";
    let layout = (_f = props.layout) !== null && _f !== void 0 ? _f : "horizontal";
    let as = (_g = props.as) !== null && _g !== void 0 ? _g : "toolbar";
    // Since the new IconV2 uses prefix and icon names without the `fa-` prefix like old Icon does, we need to convert those strings to a format that is usable by the new IconV2.
    // This will allow us to start the deprecation process or warning developers they need to start converting their Button components that use icons over to defining icon names that use the IconV2 format
    // But we must test for `iconname` being an Array because to prepare for the move to IconV2, developers might want to use the icon name format of `[<prefix>,<iconName>]`
    const convertedIcon = iconName !== undefined && !Array.isArray(iconName)
        ? needsConversion(iconName)
            ? convertIcon(iconName)
            : iconName
        : iconName !== undefined && Array.isArray(iconName)
            ? iconName
            : undefined;
    const rightConvertedIcon = rightIcon !== undefined && !Array.isArray(rightIcon)
        ? needsConversion(rightIcon)
            ? convertIcon(rightIcon)
            : rightIcon
        : rightIcon !== undefined && Array.isArray(rightIcon)
            ? rightIcon
            : undefined;
    const buttonRef = useRef(null);
    const selectable = as === "radio" || as === "toolbar";
    if (selectable) {
        (_h = buttonRef.current) === null || _h === void 0 ? void 0 : _h.setAttribute("role", as);
    }
    else {
        (_j = buttonRef.current) === null || _j === void 0 ? void 0 : _j.removeAttribute("role");
    }
    const [isChecked, setIsChecked] = useState(checked);
    useEffect(() => {
        if (selectable && checked) {
            buttonRef.current.setAttribute("aria-checked", "true");
        }
        if (selectable && !checked) {
            buttonRef.current.setAttribute("aria-checked", "false");
        }
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.attributeName === "aria-checked") {
                    if (buttonRef.current.getAttribute("aria-checked") === "true") {
                        setIsChecked(true);
                    }
                    else {
                        setIsChecked(false);
                    }
                }
            }
        });
        observer.observe(buttonRef.current, { attributes: true });
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const colorStyle = `${selectable && !isChecked
        ? "bg-components-baseComponent dark:bg-dark-components-baseComponent"
        : "bg-buttons-secondary-bg-active dark:bg-dark-buttons-secondary-bg-active"} hover:bg-buttons-secondary-bg-active active:bg-buttons-secondary-bg-active focus:outline-none focus:ring-buttons-secondary-forecolor focus:ring-2 text-buttons-secondary-forecolor dark:text-dark-buttons-secondary-forecolor dark:bg-dark-components-baseComponent dark:hover:bg-dark-buttons-secondary-bg-active dark:focus:ring-dark-buttons-secondary-forecolor dark:active:bg-dark-buttons-secondary-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text`;
    if (!disabled) {
        borderStyle =
            "border border-1 border-buttons-secondary-forecolor dark:border-dark-buttons-secondary-forecolor focus:border-buttons-secondary-forecolor focus:dark:border-dark-buttons-secondary-forecolor/0";
    }
    let buttonSize;
    let textSize;
    switch (size) {
        case "lg":
            buttonSize = "h-16 px-6";
            textSize = "text-lg";
            break;
        case "sm":
            buttonSize = "h-8 px-3";
            textSize = "text-sm";
            break;
        default:
            buttonSize = "h-11 px-4";
            textSize = "text-base";
            break;
    }
    if (icon !== undefined) {
        // eslint-disable-next-line no-console
        console.warn("icon is deprecated. Use iconName instead.");
    }
    let buttonStyle = `-ml-px relative ${borderStyle} inline-flex items-center self-center px-3 py-2 leading-4 font-medium focus:z-[1] focus:outline-none ${colorStyle} disabled:opacity-50 ${buttonSize}`;
    if (index === 0) {
        buttonStyle = `${buttonStyle} rounded-l-md ml-0`;
    }
    if (index === length - 1) {
        buttonStyle = `${buttonStyle} rounded-r-md`;
    }
    if (layout === "vertical") {
        buttonStyle = `relative ${borderStyle} [&:not(:last-of-type)]:border-b-0 px-3 py-2 text-sm font-medium focus:z-[1] focus:outline-none ${colorStyle} disabled:opacity-50 flex justify-center`;
        if (index === 0) {
            buttonStyle = `${buttonStyle} rounded-t-md`;
        }
        if (index === length - 1) {
            buttonStyle = `${buttonStyle} rounded-b-md`;
        }
    }
    return (_jsx("button", Object.assign({ ref: buttonRef, id: id, "data-test-id": `button-${testId !== null && testId !== void 0 ? testId : "test"}`, type: type, "data-eco-component": "buttongroup-button", disabled: disabled, onClick: onClick, onBlur: onBlur, onFocus: onFocus, className: buttonStyle }, (hideLabel
        ? { "aria-label": children, title: children }
        : {}), { children: _jsxs("span", { className: `pointer-events-none flex items-center ${textSize} gap-2`, children: [convertedIcon !== undefined ? (_jsx(IconV2, { type: "fa", icon: { icon: convertedIcon } })) : null, hideLabel ? null : children, rightConvertedIcon !== undefined ? (_jsx(IconV2, { type: "fa", icon: { icon: rightConvertedIcon } })) : null] }) })));
};
export const ButtonGroupButton = (_a) => {
    var _b;
    var { id, testId, iconName, tooltip = false, tooltipPlacement = "top", children } = _a, props = __rest(_a, ["id", "testId", "iconName", "tooltip", "tooltipPlacement", "children"]);
    let tooltipLabel = (_b = props.tooltipLabel) !== null && _b !== void 0 ? _b : children;
    if (tooltip) {
        return (_jsx(Tooltip, { id: `buttonTooltip-${id}`, placement: tooltipPlacement, label: tooltipLabel, testId: `buttonTooltip-${testId !== null && testId !== void 0 ? testId : "test"}`, children: _jsx(ButtonForGroup, Object.assign({ id: id, testId: testId, iconName: iconName }, props, { children: children })) }));
    }
    return (_jsx(ButtonForGroup, Object.assign({ id: id, testId: testId, iconName: iconName }, props, { children: children })));
};
