import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Checkbox } from "../checkbox";
import { IconV2 } from "../../icon_v2";
/**
 * Override react-select's GroupHeading:
 *
 * Component that renders the data of a group.
 */
const GroupHeading = (props) => {
    var _a, _b;
    const { selectProps, data } = props;
    const { selectedIndidacatorInMenu, optionSelected } = selectProps;
    const { label, options } = data;
    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
    const [selectAllIndeterminate, setSelectAllIndeterminate] = useState(false);
    // check if all options in the group have been selected
    const allOptionsSelected = () => {
        let allSelected = false;
        if (optionSelected) {
            allSelected = options.every((opt) => optionSelected.some((x) => opt.value === x.value));
        }
        return allSelected;
    };
    const handleSelectAll = () => {
        const vals = optionSelected !== null && optionSelected !== void 0 ? optionSelected : [];
        if (!allOptionsSelected()) {
            //select all!
            const newVals = [
                ...vals,
                ...props.data.options,
            ];
            selectProps.onChange(newVals, {
                action: "select-option",
                option: {
                    value: `select-eco-group-${label !== null && label !== void 0 ? label : "undefined"}`,
                    label: label !== null && label !== void 0 ? label : "undefined-group",
                },
            });
        }
        else {
            // deselect all
            const newVals = vals.filter((optSel) => !options.includes(optSel));
            selectProps.onChange(newVals, {
                action: "deselect-option",
                removedValues: options,
                option: {
                    value: `remove-eco-group-${label !== null && label !== void 0 ? label : "undefined"}`,
                    label: label !== null && label !== void 0 ? label : "undefined-group",
                },
            });
        }
    };
    useEffect(() => {
        let allSelected = false, noneSelected = true;
        if (optionSelected) {
            allSelected = options.every((opt) => optionSelected.some((x) => opt.value === x.value));
            noneSelected = options.every((opt) => !optionSelected.some((x) => opt.value === x.value));
        }
        if (allSelected) {
            setSelectAllCheckbox(true);
            setSelectAllIndeterminate(false);
        }
        else if (noneSelected) {
            setSelectAllCheckbox(false);
            setSelectAllIndeterminate(false);
        }
        else {
            setSelectAllCheckbox(false);
            setSelectAllIndeterminate(true);
        }
        // console.log({optionSelected}, {options}, {allSelected})
    }, [optionSelected, options]);
    return (_jsxs("button", { className: `text-primaryText dark:text-dark-primaryText hover:bg-buttons-tertiary-bg-active hover:dark:bg-dark-buttons-tertiary-bg-active flex w-full flex-row
  ${selectedIndidacatorInMenu === "icon" && "justify-between"} 
  px-3 py-2`, id: props.id, onClick: () => handleSelectAll(), children: [selectedIndidacatorInMenu === "checkbox" ? (_jsx(Checkbox, { label: (_a = props.data.label) !== null && _a !== void 0 ? _a : "", hideLabel: true, checked: selectAllCheckbox, indeterminate: selectAllIndeterminate, value: (_b = props.data.label) !== null && _b !== void 0 ? _b : "" })) : (""), props.data.label, selectAllCheckbox && selectedIndidacatorInMenu === "icon" ? (_jsx(IconV2, { type: "fa", icon: {
                    icon: ["far", "check"],
                    fixedWidth: true,
                    size: "lg",
                } })) : ("")] }));
};
export default GroupHeading;
