import { jsx as _jsx } from "react/jsx-runtime";
export const TypeSkeletonUtil = ({ lineCount = 1, shortenLastLine = false, leading = "normal", }) => {
    const shortenEnd = shortenLastLine ? "last:w-4/5" : "";
    let leadingMargin = "";
    switch (leading) {
        case "snug":
            leadingMargin = "[&:not(:last-child)]:mb-1";
            break;
        case "relaxed":
            leadingMargin = "[&:not(:last-child)]:mb-6";
            break;
        default:
            leadingMargin = "[&:not(:last-child)]:mb-5";
    }
    let lines = [...Array(lineCount)].map((line, i) => {
        return (_jsx("div", { className: `odd:bg-components-medium odd:dark:bg-dark-components-light even:bg-components-light even:dark:bg-dark-components-medium h-4 ${leadingMargin} ${shortenEnd}`, "data-testid": "skeleton-line" }, i));
    });
    return (_jsx("div", { "data-eco-component": "type-skeleton-util", className: "block w-full", children: lines }));
};
export default TypeSkeletonUtil;
