var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initAuth0, } from "@auth0/nextjs-auth0";
import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { request } from "universal-rxjs-ajax";
import getConfig from "next/config";
const { serverRuntimeConfig } = getConfig();
let segmentWriteKey = undefined;
export const getEnvAccessToken = () => {
    var _a, _b;
    let authUrl = (_a = serverRuntimeConfig.DTN_AUTH_URL) !== null && _a !== void 0 ? _a : "https://api.auth.dtn.com";
    let audienceUrl = (_b = serverRuntimeConfig.DTN_IDENTITY_AUDIENCE) !== null && _b !== void 0 ? _b : "https://identity.auth.dtn.com";
    return request({
        method: "POST",
        url: `${authUrl}/v1/tokens/authorize`,
        headers: { Accept: "application/json", "content-type": "application/json" },
        body: {
            grant_type: "client_credentials",
            client_id: serverRuntimeConfig.DTN_CLIENT_ID,
            client_secret: serverRuntimeConfig.DTN_CLIENT_SECRET,
            audience: audienceUrl,
        },
        async: true,
        timeout: 60000,
        crossDomain: true,
        withCredentials: false,
        responseType: "json",
    }).pipe(switchMap((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return of(data.response.data);
    }));
};
const getAuthData = (accessResponse) => {
    var _a;
    const { publicRuntimeConfig } = getConfig();
    const { DTN_PRODUCT_CODE } = publicRuntimeConfig;
    if (DTN_PRODUCT_CODE === undefined) {
        return EMPTY;
    }
    let identityHost = (_a = serverRuntimeConfig.DTN_IDENTITY_HOST) !== null && _a !== void 0 ? _a : "https://identity.auth.dtn.com";
    // request identity endpoint with access token and get authData
    return request({
        method: "GET",
        url: `${identityHost}/v1/products/${DTN_PRODUCT_CODE}/auth-info`,
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: `Bearer ${accessResponse.access_token}`,
        },
        async: true,
        timeout: 0,
        crossDomain: false,
        withCredentials: false,
        responseType: "",
    }).pipe(switchMap((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const authData = JSON.parse(data.response);
        segmentWriteKey = authData.data.segment_key;
        return of(authData);
    }), catchError((error) => {
        // eslint-disable-next-line no-console
        console.error("error: ", error);
        return EMPTY;
    }));
};
const initAuth = () => {
    var _a, _b;
    let audienceUrl = (_a = serverRuntimeConfig.DTN_AUTH_AUDIENCE) !== null && _a !== void 0 ? _a : "https://api.auth.dtn.com";
    const basePath = (_b = serverRuntimeConfig.APP_BASE_PATH) !== null && _b !== void 0 ? _b : "";
    return getEnvAccessToken().pipe(switchMap((data) => {
        return getAuthData(data);
    }), map((data) => {
        const authInfo = data;
        return initAuth0({
            issuerBaseURL: `https://${authInfo.data.auth0_issuer_base_url}`,
            clientID: authInfo.data.client_id,
            secret: authInfo.data.auth0_secret,
            baseURL: serverRuntimeConfig.DTN_APP_DOMAIN,
            authorizationParams: {
                response_type: "code",
                scope: "openid profile email offline_access",
                audience: audienceUrl,
            },
            clientSecret: authInfo.data.client_secret,
            routes: {
                login: `${basePath}/api/auth/login`,
                callback: `${basePath}/api/auth/callback`,
            },
        });
    }));
};
let authInstance = undefined;
export const getAuthInstance = () => __awaiter(void 0, void 0, void 0, function* () {
    if (authInstance === undefined) {
        // eslint-disable-next-line require-atomic-updates, @typescript-eslint/no-unsafe-assignment
        authInstance = yield initAuth().toPromise();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return authInstance;
});
export const auth0 = () => __awaiter(void 0, void 0, void 0, function* () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const auth = yield getAuthInstance();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return auth;
});
export const getSegmentKey = () => __awaiter(void 0, void 0, void 0, function* () {
    yield getAuthInstance();
    return segmentWriteKey;
});
export const getAccessToken = (req, res, accessTokenRequest) => __awaiter(void 0, void 0, void 0, function* () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const auth = yield getAuthInstance();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion
    return auth.getAccessToken(req, res, accessTokenRequest);
});
export const getSession = (req, res) => __awaiter(void 0, void 0, void 0, function* () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const auth = yield getAuthInstance();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion
    return auth.getSession(req, res);
});
