import { jsx as _jsx } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
const DropdownIndicator = (props) => {
    if (props.selectProps.menuIsOpen) {
        return (_jsx("div", { className: `${props.isDisabled ? "opacity-50" : ""} text-secondary dark:text-dark-primaryText mr-2 py-2`, children: _jsx(IconV2, { type: "fa", icon: {
                    icon: ["far", "angle-up"],
                    fixedWidth: true,
                    size: "xl",
                } }) }, "angle-up"));
    }
    return (_jsx("div", { className: `${props.isDisabled ? "opacity-50" : ""} text-secondary dark:text-dark-primaryText mr-2 py-2`, children: _jsx(IconV2, { type: "fa", icon: {
                icon: ["far", "angle-down"],
                fixedWidth: true,
                size: "xl",
            } }) }, "angle-down"));
};
export default DropdownIndicator;
