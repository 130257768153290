import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneElement, useEffect, useState } from "react";
import { IconV2 } from "../../icon_v2";
const FieldErrorList = ({ children }) => {
    return _jsx("ul", { children: children });
};
const FieldError = ({ children, $showError }) => {
    const hiddenClass = $showError === false ? `hidden` : ``;
    return (_jsx("li", { className: `mt-1 list-none text-xs font-normal leading-4 text-red-600 ${hiddenClass}`, children: children }));
};
const FieldControl = ({ control, errors, displayError, }) => {
    const [hasError, setHasError] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isInputTypeField, setIsInputTypeField] = useState(true);
    useEffect(() => {
        if (displayError === true) {
            setShowError(true);
        }
        else {
            // Check Form Fields such as Inputs, Textarea which would be invoked by the handleBlur method.
            const nonRadioGroupFields = 
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            errors && control.props.name in errors && showError;
            // Check Radio Group, it won't invoke handleBlur logic
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const radioGroupFields = errors && control.props.group in errors;
            if (radioGroupFields === true) {
                setIsInputTypeField(false);
            }
            if (nonRadioGroupFields === true || radioGroupFields === true) {
                setHasError(true);
            }
            else {
                setHasError(false);
                if (showError) {
                    setShowError(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, showError, displayError]);
    const handleBlur = () => {
        setShowError(true);
    };
    return (_jsxs("div", { onBlur: handleBlur, children: [cloneElement(control, { invalid: hasError }), _jsxs(FieldErrorList, { children: [errors &&
                        isInputTypeField &&
                        (() => {
                            var _a;
                            let controlName = control.props
                                .name;
                            if (!controlName) {
                                controlName = control.props.id;
                            }
                            return (_a = errors[controlName]) === null || _a === void 0 ? void 0 : _a.map((error, index) => {
                                return (_jsx(_Fragment, { children: _jsx(FieldError, { "data-control": controlName, "$showError": showError, children: _jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "mr-1", children: _jsx(IconV2, { type: "fa", icon: { icon: ["far", "triangle-exclamation"] } }) }), _jsx("span", { className: "mt-0.5 text-red-600", children: error })] }) }, index) }));
                            });
                        })(), errors &&
                        !isInputTypeField &&
                        (() => {
                            var _a;
                            let controlGroupName = control.props
                                .group;
                            return (_a = errors[controlGroupName]) === null || _a === void 0 ? void 0 : _a.map((error, index) => {
                                return (_jsx(FieldError, { "data-control": controlGroupName, "$showError": showError, children: _jsx("span", { className: "text-red-600", children: error }) }, index));
                            });
                        })()] })] }));
};
export const Field = ({ id, testId, controls, errors, displayError = false, children, }) => {
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "field", children: [Array.isArray(controls) ? (_jsxs("div", { className: "items-top flex justify-center", children: [_jsx(FieldControl, { control: controls[0], errors: errors, displayError: displayError, className: "flex-1" }), _jsx("span", { className: "self-center p-2", children: "\u2013" }), _jsx(FieldControl, { control: controls[1], errors: errors, displayError: displayError, className: "flex-1" })] })) : (_jsx(FieldControl, { control: controls, errors: errors, displayError: displayError })), children] }));
};
export default Field;
