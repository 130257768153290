import { useReducer, useCallback } from "react";
import { isGroup, } from "./interfaces";
const findGroup = (grps, label) => grps.find((grp) => grp.label === label);
const getUnmodifiedGroups = (grps, label) => grps.filter((grp) => grp.label !== label);
const collapsedGroupsReducer = (state, action) => {
    switch (action.type) {
        case "INITIALIZE_GROUPS": {
            let newGroups = [];
            action.newGroups.forEach((grp) => {
                if (isGroup(grp)) {
                    newGroups.push({
                        label: grp.label,
                        totalOptions: grp.options.length,
                        selectedOptions: 0,
                        collapsed: true,
                    });
                }
            });
            return {
                groups: [...newGroups],
            };
        }
        case "SELECT_IN_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: group.selectedOptions + 1,
                collapsed: group.collapsed,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        case "SELECT_ALL_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: group.totalOptions,
                collapsed: group.collapsed,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        case "REMOVE_FROM_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: group.selectedOptions - 1,
                collapsed: group.collapsed,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        case "REMOVE_ALL_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: 0,
                collapsed: group.collapsed,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        case "COLLAPSE_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: group.selectedOptions,
                collapsed: true,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        case "EXPAND_GROUP": {
            const group = findGroup(state.groups, action.label);
            if (group === undefined) {
                return Object.assign({}, state);
            }
            const newGroup = {
                label: group.label,
                totalOptions: group.totalOptions,
                selectedOptions: group.selectedOptions,
                collapsed: false,
            };
            const untouchedGroups = getUnmodifiedGroups(state.groups, action.label);
            return {
                groups: [...untouchedGroups, newGroup],
            };
        }
        default:
            return Object.assign({}, state);
    }
};
const useCollapsedGroups = () => {
    const initialState = { groups: [] };
    const [collapsedGroupState, dispatch] = useReducer(collapsedGroupsReducer, initialState);
    const initializeGroups = useCallback((newGroups) => {
        dispatch({
            type: "INITIALIZE_GROUPS",
            newGroups,
        });
    }, []);
    const selectInGroup = useCallback((label) => {
        dispatch({
            type: "SELECT_IN_GROUP",
            label,
        });
    }, []);
    const selectAllGroup = useCallback((label) => {
        dispatch({
            type: "SELECT_ALL_GROUP",
            label,
        });
    }, []);
    const removeFromGroup = useCallback((label) => {
        dispatch({
            type: "REMOVE_FROM_GROUP",
            label,
        });
    }, []);
    const removeAllGroup = useCallback((label) => {
        dispatch({
            type: "REMOVE_ALL_GROUP",
            label,
        });
    }, []);
    const collapseGroup = useCallback((label) => {
        dispatch({
            type: "COLLAPSE_GROUP",
            label,
        });
    }, []);
    const expandGroup = useCallback((label) => {
        dispatch({
            type: "EXPAND_GROUP",
            label,
        });
    }, []);
    return {
        collapsedGroupState,
        initializeGroups,
        selectInGroup,
        selectAllGroup,
        removeFromGroup,
        removeAllGroup,
        collapseGroup,
        expandGroup,
    };
};
export { useCollapsedGroups };
