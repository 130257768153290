import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Label } from "../label";
import AdvancedDatePickerOptions from "./AdvancedDatePickerOptions";
import { AdvancedDatePickerProvider } from "./AdvancedDatePickerProvider";
import Dropdown from "./Dropdown";
import TriggerButton from "./TriggerButton";
import ClearButton from "./ClearButton";
export const AdvancedDatePicker = ({ id, testId, locale = "", name, label, required, hideLabel, invalid, startOfWeek = 0, disabled = false, suggestedTimeRangeOptions = [], i18nStrings = {}, showTimePicker, customPickerValueFormat = undefined, customDateFormat, pickerValue, dateFormat, minDate, maxDate, selected, dateRangeOptions, onChange, }) => {
    var _a, _b, _c;
    const rootRef = useRef(null);
    const triggerRef = useRef(null);
    const clearRef = useRef(null);
    return (_jsx(AdvancedDatePickerProvider, { children: _jsxs("div", { "data-eco-component": "advanced-datepicker", ref: rootRef, id: id, "data-test-id": testId, role: "presentation", className: "relative sm:max-w-sm", children: [_jsx(Label, { name: name, label: label, required: required, hideLabel: hideLabel, disabled: disabled }), _jsxs("div", { className: "flex w-full min-w-[320px] items-center", children: [_jsx(Dropdown, { render: () => (_jsx("div", { className: "rounded-md", children: _jsx(AdvancedDatePickerOptions, { id,
                                    startOfWeek,
                                    showTimePicker,
                                    i18nStrings,
                                    suggestedTimeRangeOptions,
                                    locale,
                                    pickerValue,
                                    dateFormat,
                                    minDate,
                                    maxDate,
                                    dateRangeOptions,
                                    onChange }) })), ariaLabelledby: (_a = i18nStrings.ariaLabelledby) !== null && _a !== void 0 ? _a : `${name}-dropdown`, ariaDescribedby: (_b = i18nStrings.ariaDescribedby) !== null && _b !== void 0 ? _b : `${name}-dropdown-content`, children: _jsx("div", { className: "trigger-wrapper flex-1", children: _jsx(TriggerButton, { customDateFormat: customDateFormat, ref: triggerRef, disabled: disabled, invalid: invalid, placeholder: (_c = i18nStrings.placeholderText) !== null && _c !== void 0 ? _c : "Select a date and time range", selectedValue: selected, customPickerValueFormat: customPickerValueFormat }) }) }), _jsx("div", { className: `align-center opacity z-10 -ml-[30px] mt-0.5 flex h-11 justify-center ${disabled ? "opacity-50" : ""}`, children: _jsx(ClearButton, { ref: clearRef, onChange: onChange }) })] })] }) }));
};
export default AdvancedDatePicker;
