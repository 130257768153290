var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from "react";
export const Checkbox = (_a) => {
    var { id, testId, bgColor = "white", indeterminate, onChange, onBlur, onFocus, checked } = _a, props = __rest(_a, ["id", "testId", "bgColor", "indeterminate", "onChange", "onBlur", "onFocus", "checked"]);
    let ref = useRef(null);
    useEffect(() => {
        let checkbox = ref.current;
        if (indeterminate !== undefined) {
            checkbox.indeterminate = indeterminate;
        }
        if (checked !== undefined) {
            checkbox.checked = checked;
        }
    }, [indeterminate, checked]);
    let backgroundColor;
    if (props.invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (bgColor === "grey" || bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    const invalidClasses = props.invalid === true
        ? `border-error dark:border-dark-error focus:ring-error focus:border-error focus:dark:border-dark-error text-error dark:text-dark-error`
        : `border-interactive dark:border-dark-interactive checked:border-brand-accent dark:checked:border-dark-brand-accent text-brand-accent dark:text-brand-accent focus:ring-brand-accent focus:dark:ring-dark-brand-accent`;
    const disabledClasses = props.disabled === true ? "opacity-40" : "cursor-pointer";
    const hideLabelClass = props.hideLabel === true ? ` sr-only` : ``;
    return (_jsxs("div", { className: "relative flex items-center", id: id, "data-eco-component": "checkbox", children: [_jsx("input", { id: `${id}-checkbox`, "data-test-id": testId, className: `checked:bg-brand-accent dark:checked:bg-dark-brand-accent h-6 w-6 rounded ${invalidClasses} ${backgroundColor} indeterminate:dark:bg-dark-brand-accent indeterminate:dark:border-dark-brand-accent focus:border-0 focus:outline-0 focus:ring-offset-0 disabled:opacity-40`, type: "checkbox", name: props.name, value: props.value, required: props.required, disabled: props.disabled, "aria-invalid": props.invalid, "aria-describedby": props.name, onChange: onChange, onBlur: onBlur, onFocus: onFocus, ref: ref }), _jsx("div", { className: "ml-2 text-base", children: _jsx("label", { htmlFor: `${id}-checkbox`, className: `text-primaryText dark:text-dark-primaryText align-middle text-sm font-medium ${disabledClasses}`, children: _jsx("span", { className: `${hideLabelClass}`, children: props.label }) }) })] }));
};
