import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypeSkeletonUtil } from "../TypeSkeletonUtil";
export const ListSkeleton = ({ id, testId, listCount = 1, showImages = false, }) => {
    let list = [...Array(listCount)].map((item, i) => {
        if (showImages) {
            return (_jsxs("div", { className: "flex flex-row gap-4", children: [_jsx("div", { className: "bg-components-medium dark:bg-dark-components-light size-10 shrink-0 grow-0 rounded", "data-eco-component": "image-skeleton" }), _jsx(TypeSkeletonUtil, { lineCount: 2, shortenLastLine: true, leading: "snug" })] }, i));
        }
        return (_jsx(TypeSkeletonUtil, { lineCount: 2, shortenLastLine: true, leading: "snug" }, i));
    });
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "list-skeleton", className: `animate-pulse space-y-3`, children: [list, _jsx("span", { className: "sr-only", children: "Loading" })] }));
};
export default ListSkeleton;
