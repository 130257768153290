import { jsx as _jsx } from "react/jsx-runtime";
import { DisclosureFull } from "./DisclosureFull";
import { DisclosureCompact } from "./DisclosureCompact";
export const DisclosureItem = ({ disclosure, mode, }) => {
    if (mode === "full") {
        return (_jsx(DisclosureFull, { label: disclosure.label, id: disclosure.id, testId: `${disclosure.id}-test`, panel: disclosure.panel, defaultOpen: disclosure.defaultOpen, onToggle: disclosure.onToggle }));
    }
    return (_jsx(DisclosureCompact, { label: disclosure.label, id: disclosure.id, testId: `${disclosure.id}-test`, panel: disclosure.panel, defaultOpen: disclosure.defaultOpen, onToggle: disclosure.onToggle }));
};
export default DisclosureItem;
