import { useRef } from "react";
let counter = 0;
export function generateUniqueId(prefix) {
    return `${prefix !== undefined ? prefix : ""}${counter++}-${Date.now()}-${Math.round(Math.random() * 10000)}`;
}
export function useUniqueId(prefix) {
    const idRef = useRef(null);
    if (idRef.current === null) {
        idRef.current = generateUniqueId(prefix);
    }
    return idRef.current;
}
