import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRouter } from "next/router";
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Highlight, useHits, } from "react-instantsearch-hooks-web";
import { Pill as PillComponent, Icon as IconComponent, Heading, Paragraph, } from "../index";
export const CustomHit = ({ hit }) => {
    const router = useRouter();
    const handleHitClick = (e) => {
        e.preventDefault();
        if (hit.path) {
            router.push(`${hit.path}`);
            return;
        }
        let name = hit.name.toLowerCase();
        if (name.indexOf(" ") >= 0) {
            name = name.split(" ").join("");
        }
        router.push(`/components/elements/${name}`);
    };
    const hitPkgs = hit.packages || [];
    return (_jsxs("li", { className: "flex items-center justify-between p-2", children: [_jsxs("div", { className: "w-2/3", children: [_jsx(Heading, { mode: "display", size: "lg", as: "h1", children: _jsx(Highlight, { attribute: "name", hit: hit }) }), _jsx(Paragraph, { children: _jsx(Highlight, { attribute: "description", hit: hit }) })] }), _jsx("div", { children: hitPkgs.map((pkg, idx) => (
                // Add Link
                _jsx(PillComponent, { variant: "primary", message: pkg, dismissible: false }, idx))) }), _jsx("div", { children: _jsx("button", { onClick: (e) => handleHitClick(e), children: _jsx(IconComponent, { icon: "fa-regular fa-angle-right", size: "md" }) }) })] }, hit.objectID));
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
export const CustomHitsHook = (props) => {
    const { results } = useHits(props);
    // Query
    const query = results === null || results === void 0 ? void 0 : results.query;
    const validQuery = query.length >= 3;
    // Results
    const noResults = (results === null || results === void 0 ? void 0 : results.hits.length) === 0;
    const withResults = ((results === null || results === void 0 ? void 0 : results.hits) && results.hits.length > 0) || false;
    return (_jsxs(_Fragment, { children: [noResults && validQuery && (_jsx("p", { className: "mt-3", children: "No results were found!" })), withResults && validQuery && (
            // <div className="overflow-auto h-1/3 min-h-full">
            _jsx("div", { className: "max-h-96 overflow-x-scroll", children: _jsx("ol", { className: "mt-2 divide-y divide-gray-200", children: results === null || results === void 0 ? void 0 : results.hits.map((hit) => (_jsx(CustomHit, { hit: hit }, hit.objectID))) }) }))] }));
};
export default CustomHitsHook;
