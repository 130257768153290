var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState, } from "react";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
export const ToggleButton = (_a) => {
    var { id, testId, form, onClick, onBlur, onFocus, icon, children } = _a, props = __rest(_a, ["id", "testId", "form", "onClick", "onBlur", "onFocus", "icon", "children"]);
    let { variant, type, disabled, hideLabel, faIcon, faIconVariant, selectedVariant, } = props;
    const [buttonSelected, setButtonSelected] = useState(false);
    if (!icon) {
        if (!faIconVariant) {
            faIconVariant = faIcon;
        }
        // eslint-disable-next-line no-param-reassign
        icon = (_jsx(Icon, { icon: buttonSelected ? faIconVariant : faIcon, color: buttonSelected ? "white" : "gray", size: "sm" }));
    }
    const handleMouseEventAction = (e) => {
        e.stopPropagation();
        const update = !buttonSelected;
        setButtonSelected(update);
        onClick(e, update);
    };
    return (_jsx(Button, { type: type, id: id, "data-eco-component": "toggle-button", icon: icon, variant: buttonSelected ? selectedVariant : "secondary", 
        //@ts-expect-error TODO: check event type
        onClick: (e) => handleMouseEventAction(e), onBlur: onBlur, onFocus: onFocus, children: children }));
};
