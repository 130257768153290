"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEnvironment = exports.Orientation = exports.Viewport = void 0;
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
const react_1 = require("react");
var Viewport;
(function (Viewport) {
    Viewport["XL"] = "xl";
    Viewport["L"] = "l";
    Viewport["M"] = "m";
    Viewport["S"] = "s";
})(Viewport || (exports.Viewport = Viewport = {}));
var Orientation;
(function (Orientation) {
    Orientation["Landscape"] = "landscape";
    Orientation["Portrait"] = "portrait";
})(Orientation || (exports.Orientation = Orientation = {}));
const useEnvironment = () => {
    const getViewport = () => {
        if (typeof window !== "undefined") {
            if (window.matchMedia("(min-width: 1280px)").matches) {
                return Viewport.XL;
            }
            else if (window.matchMedia("(min-width: 960px)").matches) {
                return Viewport.L;
            }
            else if (window.matchMedia("(min-width: 600px)").matches) {
                return Viewport.M;
            }
            return Viewport.S;
        }
        return Viewport.S;
    };
    const getOrientation = () => {
        if (typeof window !== "undefined") {
            if (window.matchMedia("(orientation: portrait)").matches) {
                return Orientation.Portrait;
            }
            return Orientation.Landscape;
        }
        return Orientation.Landscape;
    };
    const [environment, setEnvironment] = (0, react_1.useState)({
        viewport: getViewport(),
        orientation: getOrientation(),
    });
    (0, react_1.useEffect)(() => {
        function handleResize() {
            setEnvironment({
                viewport: getViewport(),
                orientation: getOrientation(),
            });
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return environment;
};
exports.useEnvironment = useEnvironment;
