var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAccessToken, getEnvAccessToken, getSession } from "../utils/auth0";
import { intersectionBy, unionBy, uniqBy } from "lodash";
import { lastValueFrom } from "rxjs";
import getConfig from "next/config";
const { serverRuntimeConfig } = getConfig();
export const getAllUsersAndGroups = (req, res) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    // Set Headers for Access Token
    res.setHeader("Cache-Control", "no-store");
    const accessTokenObservable = getEnvAccessToken();
    const { access_token } = yield lastValueFrom(accessTokenObservable);
    // Get users for Customer IDs
    const user = yield getSession(req, res).then((session) => session);
    const customerIds = user === null || user === void 0 ? void 0 : user.user["https://auth.dtn.com/customerId"];
    const identityHost = (_a = serverRuntimeConfig.DTN_IDENTITY_HOST) !== null && _a !== void 0 ? _a : "https://identity.auth.dtn.com";
    let recipients = [];
    if (customerIds.length > 0 && customerIds !== "unknown") {
        for (const customerId of customerIds) {
            const response = yield fetch(`${identityHost}/v1/mgmt/users?customer_id=${customerId}&page=0&per_page=999999`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "content-type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const users = yield response.json().then(({ data }) => {
                return data.users;
            });
            recipients = unionBy(recipients, users, "user_id");
        }
    }
    const apps = user === null || user === void 0 ? void 0 : user.user["https://auth.dtn.com/appInfo"];
    let groupEndpoints = [];
    for (const app in apps) {
        if ("customers" in apps[app]) {
            const allGroups = (_b = apps[app]) === null || _b === void 0 ? void 0 : _b.customers.map((customer) => {
                return customer.data.group;
            }).filter((group) => group !== undefined);
            groupEndpoints = [...groupEndpoints, ...allGroups];
        }
    }
    let groups = [];
    if (groupEndpoints.length > 0) {
        const { accessToken } = yield getAccessToken(req, res);
        for (const groupUrl of groupEndpoints) {
            const response = yield fetch(`${groupUrl}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const allUsers = yield response
                .clone()
                .json()
                .then(({ data }) => {
                return data.users;
            })
                .catch((error) => 
            // eslint-disable-next-line no-console
            console.error(`Request error for group users: ${groupUrl}`, error));
            const groupList = yield response
                .clone()
                .json()
                .then(({ data }) => {
                return data.groups;
            })
                .catch((error) => 
            // eslint-disable-next-line no-console
            console.error(`Request error for group: ${groupUrl}`, error));
            if (groupList && groupList.length > 0) {
                for (const group of groupList) {
                    const validGroupUsers = intersectionBy(group.members, recipients, "user_id");
                    groups.push({
                        guid: group.guid,
                        name: group.name,
                        members: validGroupUsers,
                    });
                }
                //reset recipients to be only valid group users
                recipients = [];
                for (const group of groups) {
                    if (allUsers) {
                        recipients = uniqBy([...recipients, ...group.members, ...allUsers], "user_id");
                    }
                    else {
                        recipients = uniqBy([...recipients, ...group.members], "user_id");
                    }
                }
            }
        }
    }
    res.status(200).json({ data: { recipients, groups } });
});
