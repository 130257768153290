import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Pagination from "../pagination/Pagination";
import "./datatable.css";
// Note: This import resolves ECO-4408.
// Find more information on issue here: https://github.com/TanStack/table/issues/3962#issuecomment-1136532672
import "regenerator-runtime";
import SortUpIcon from "./SortUpIcon";
import SortDownIcon from "./SortDownIcon";
import SortIcon from "./SortIcon";
export const DataTable = ({ id, testId, layout, pageIndex, pageCount, pageSize, totalRows, onPrevPage, onNextPage, onGotoPage, onPageSizeChange, scrollable = false, enableZebraStripedRows = true, children, }) => {
    const tableLayout = layout !== null && layout !== void 0 ? layout : "default";
    const overflowClass = scrollable ? "overflow-x-auto" : "";
    const zebraStripes = enableZebraStripedRows ? "zebraStripes" : "";
    return (_jsx("div", { className: "flex flex-col", "data-eco-component": "datatable", id: id, "data-test-id": testId, children: _jsxs("div", { children: [_jsx("div", { className: `stratos-datatable ${zebraStripes} ${overflowClass}`, children: _jsx("div", { className: tableLayout, children: children }) }), totalRows === 0 && (_jsx("div", { className: "p-5 text-center", children: "No results found." })), pageCount !== undefined && pageCount > 0 && (_jsx(Pagination, { id: id !== undefined ? `${id}-pagination` : undefined, layout: tableLayout, totalRows: totalRows, pageSize: pageSize, pageIndex: pageIndex !== null && pageIndex !== void 0 ? pageIndex : 0, pageCount: pageCount, onPrevPage: onPrevPage, onNextPage: onNextPage, onGotoPage: onGotoPage, onPageSizeChange: onPageSizeChange, testId: `${testId}-pagination` }))] }) }));
};
export const TableIconSort = () => {
    return _jsx(SortIcon, {});
};
export const TableIconSortUp = () => {
    return _jsx(SortUpIcon, {});
};
export const TableIconSortDown = () => {
    return _jsx(SortDownIcon, {});
};
export default { DataTable, TableIconSort, TableIconSortUp, TableIconSortDown };
