import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import useWizard from "./useWizard";
import { Menu } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
export const WizardSteps = ({ children }) => {
    const [{ currentStep, completedSteps }, { setSteps }] = useWizard();
    // https://www.smashingmagazine.com/2021/08/react-children-iteration-methods/
    const childrenArray = React.Children.toArray(children);
    let adjustedIdx = currentStep + 1;
    const totalSteps = children.length;
    // Enhancement: Do we need steps[]?
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const stepperSteps = [...childrenArray]
            .filter((child) => {
            const isFunc = typeof child.type === "function";
            return isFunc;
        })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            .map((step) => {
            return step.props;
        });
        setSteps(stepperSteps);
    }, []);
    // @ts-expect-error:fix API later
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const currentStepTitle = childrenArray[currentStep].props.children;
    return (_jsxs("div", { id: "eco-wizard-steps", children: [_jsxs("div", { className: "bg-container-light dark:bg-dark-container-light mb-5 hidden w-full flex-col gap-1 md:flex md:grid md:grid-cols-4", role: "list", children: [" ", React.Children.map(children, (child, index) => {
                        const isSelected = index === currentStep;
                        const isLast = index === children.length - 1;
                        let completed;
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
                        if (completedSteps && completedSteps !== undefined) {
                            completed = completedSteps[index] === 1 ? true : false;
                        }
                        // Pass the necessary props to {children}
                        return React.cloneElement(child, {
                            idx: index,
                            isSelected,
                            isLast,
                            completed,
                        });
                    })] }), _jsx("div", { className: "relative md:hidden", children: _jsxs(Menu, { children: [_jsx(Menu.Button, { className: "border-interactive dark:border-dark-interactive mb-4 flex w-full flex-row justify-between rounded-md border px-4 py-2", children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col items-start", children: [_jsxs("h5", { className: "text-placeholder dark:text-dark-placeholder text-sm", children: ["Step ", adjustedIdx, " of ", totalSteps] }), _jsx("h4", { className: "text-primaryText dark:text-dark-primaryText font-bold", children: currentStepTitle })] }), !open && (_jsx(IconV2, { type: "fa", icon: {
                                            icon: ["far", "chevron-down"],
                                            size: "sm",
                                            fixedWidth: true,
                                        } })), open && (_jsx(IconV2, { type: "fa", icon: {
                                            icon: ["far", "chevron-up"],
                                            size: "sm",
                                            fixedWidth: true,
                                        } }))] })) }), _jsx(Menu.Items, { className: "border-light bg-components-baseComponent dark:border-dark-light dark:bg-dark-components-baseComponent top absolute z-30 flex w-full flex-col gap-2 rounded-md border p-4 shadow-md", children: _jsx(Menu.Item, { children: _jsx(_Fragment, { children: React.Children.map(children, (child, index) => {
                                        const isSelected = index === currentStep;
                                        const isLast = index === children.length - 1;
                                        let completed;
                                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
                                        if (completedSteps && completedSteps !== undefined) {
                                            completed = completedSteps[index] === 1 ? true : false;
                                        }
                                        // Pass the necessary props to {children}
                                        return React.cloneElement(child, {
                                            idx: index,
                                            isSelected,
                                            isLast,
                                            completed,
                                        });
                                    }) }) }) })] }) })] }));
};
export default WizardSteps;
