var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable require-unicode-regexp */
/* eslint-disable prefer-named-capture-group */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO : fix lint errors
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect, Fragment } from "react";
import { Code } from "../code";
import { Icon } from "../../icon";
import { Heading } from "../heading";
import ReactDOMServer from "react-dom/server";
import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
import { useEnvironment, Viewport } from "@eco/stratos-runtime";
export const ReactProse = (props) => {
    const [Content, setContent] = useState(_jsx(_Fragment, {}));
    let { viewport } = useEnvironment();
    useEffect(() => {
        let markup = ReactDOMServer.renderToStaticMarkup(props.children);
        unified()
            .use(rehypeParse, { fragment: true })
            .use(rehypeReact, {
            createElement: React.createElement,
            components: {
                h2: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx(Heading, { as: "h2", useAnchor: props["data-useanchor"], id: props.id, children: props.children }));
                },
                h3: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx(Heading, { as: "h3", useAnchor: props["data-useanchor"], id: props.id, children: props.children }));
                },
                code: (_a) => {
                    var _b;
                    var props = __rest(_a, []);
                    const match = /language-(\w+)/.exec((_b = props.className) !== null && _b !== void 0 ? _b : "");
                    const language = match ? match[1] : "";
                    const inline = match ? false : true;
                    return (_jsx("span", { className: `relative mt-4 ${inline ? "inline" : "flex flex-col"}`, children: _jsx(Code, { id: props.id, language: language, inline: inline, filename: props["data-filename"], children: props.children }) }));
                },
                img: (_a) => {
                    var props = __rest(_a, []);
                    if (props["data-icon"]) {
                        return _jsx(Icon, { icon: props["data-icon"] });
                    }
                    return _jsx(_Fragment, { children: props.children });
                },
                // TODO: refactor Table so that classes are stored in DRYer react componentes
                table: (_a) => {
                    var props = __rest(_a, []);
                    if (viewport === Viewport.L || viewport === Viewport.XL) {
                        return (_jsx("div", { className: "not-prose py-2", children: _jsx("table", Object.assign({ className: "relative min-w-full border-separate", style: { borderSpacing: 0 } }, props, { children: props.children })) }));
                    }
                    let tableHead = props.children[0].props.children[0].props.children;
                    let tableRows = props.children[1].props.children;
                    return (_jsx("div", { className: "py-2", children: tableRows.map((row, i) => {
                            const rowCells = row.props.children;
                            return (_jsxs("div", { className: "my-5", children: [_jsx("h3", { className: "pl-2", children: rowCells[0].props.children[0] }), _jsx("table", { className: "not-prose min-w-full border-separate", style: { borderSpacing: 0 }, children: _jsx("tbody", { className: "divide-y", children: rowCells.map((cell, j) => {
                                                return (_jsxs("tr", { className: "even:bg-container-baseContainer hover:bg-components-lighter dark:even:bg-dark-container-baseContainer dark:hover:bg-dark-components-lighter odd:bg-container-light dark:odd:bg-dark-container-light border-b transition duration-300 ease-in-out", children: [tableHead !== undefined && (_jsx("th", { className: "break-normal px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-800 backdrop-blur backdrop-filter", scope: "row", children: tableHead[j].props.children[0] })), _jsx("td", { className: "text-secondary dark:text-dark-secondary break-normal px-2 py-2 text-left text-xs", children: cell.props.children })] }, `r-${j}`));
                                            }) }) })] }, `t-${i}`));
                        }) }));
                },
                thead: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx("thead", { className: "z-sticky relative", children: props.children }));
                },
                th: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx("th", { scope: "col", className: "bg-container-medium dark:bg-dark-container-medium text-primaryText dark:text-dark-primaryText sticky top-0 bg-opacity-75 px-2 py-2.5 text-left text-sm font-semibold backdrop-blur backdrop-filter first-of-type:rounded-tl-md last-of-type:rounded-tr-md", children: props.children }));
                },
                td: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx("td", { className: "text-secondary dark:text-dark-secondary break-normal px-2 py-2 text-left text-sm", children: props.children }));
                },
                tr: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx("tr", Object.assign({ className: "even:bg-container-baseContainer dark:even:bg-dark-container-baseContainer hover:bg-components-lighter dark:hover:bg-dark-components-lighter odd:bg-container-light dark:odd:bg-dark-container-light border-b transition duration-300 ease-in-out" }, props, { children: props.children })));
                },
                tbody: (_a) => {
                    var props = __rest(_a, []);
                    return _jsx("tbody", Object.assign({}, props, { children: props.children }));
                },
            },
        })
            .process(markup)
            .then((file) => {
            setContent(file.result);
        })
            .catch((error) => {
            console.warn(error);
        });
        return () => {
            setContent(_jsx(_Fragment, {})); // This worked for me
        };
    }, []);
    return _jsx(Fragment, { children: Content });
};
