/**
 * This function checks to see if the string passed in requires conversion from the old Icon naming format to the IconV2 naming format
 * @param icon
 * @returns TRUE if the string has `fa-` prefixed before the name. FALSE if not which means it is already in IconV2 format
 */
export const needsConversion = (icon) => {
    return icon.search(/fa-/u) !== -1;
};
/**
 * This function takes the icon string used in the Icon component and converts it to usable prop types for use in the IconV2 component.
 * It has to check for strings in the format of `fa-prefix fa-iconname` or just `fa-iconname`.
 *
 * @param iconStr String value usually in the form of <icon-name> or <icon-prefix icon-name> with the "fa-" prefixing both.
 * @returns An instance of IconProp<IconName | [IconPrefix, IconName]>
 */
export const convertIcon = (iconStr) => {
    var _a, _b;
    // These elements come directly from the IconProp.IconPrefix. If they change, this will break.
    const prefixes = {
        "fa-solid": "fas",
        "fa-regular": "far",
        "fa-light": "fal",
        "fa-thin": "fat",
        "fa-duotone": "fad",
        "fa-brands": "fab",
    };
    const arr = iconStr.split(" ");
    let icon;
    if (arr.length === 2) {
        const prefix = prefixes[arr[0]];
        icon = (_a = arr[1]) === null || _a === void 0 ? void 0 : _a.replace("fa-", "");
        return [prefix, icon];
    }
    icon = (_b = arr[0]) === null || _b === void 0 ? void 0 : _b.replace("fa-", "");
    return icon;
};
