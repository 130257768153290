import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { IconV2 } from "../icon_v2";
const Carousel = ({ items, onPrevious, onNext }) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        beforeChange: (current, next) => setCurrentSlide(next),
    };
    const goToPrevious = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
        if (onPrevious) {
            onPrevious();
        }
    };
    const goToNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
        if (onNext) {
            onNext();
        }
    };
    const isLastSlide = currentSlide === items.length - 1;
    const buttonStyle = "border-2 border-buttons-secondary dark:border-dark-buttons-secondary rounded-md hover:bg-buttons-secondary-bg-hover p-2 cursor-pointer disabled:cursor-not-allowed disabled:border-dark dark:disabled:border-dark-dark disabled:bg-buttons-disabled-background dark:disabled-bg-dark-buttons-disabled-background disabled:text-buttons-disabled-text dark:disabled:text-dark-buttons-disabled-text disabled:opacity-50";
    return (_jsxs("div", { id: "legend-carousel", className: "legend-carousel relative mr-[2rem] flex items-center", children: [_jsxs("div", { className: "ml-2", children: [_jsx("button", { onClick: goToPrevious, className: `${buttonStyle} mr-4`, disabled: currentSlide === 0, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                        icon: "angle-left",
                                        fixedWidth: true,
                                    } }), _jsx("span", { className: "sr-only", children: "Previous" })] }) }), _jsx("button", { onClick: goToNext, className: buttonStyle, disabled: isLastSlide, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                        icon: "angle-right",
                                        fixedWidth: true,
                                    } }), _jsx("span", { className: "sr-only", children: "Next" })] }) })] }), _jsx("div", { className: "carousel-container", children: _jsx(Slider, Object.assign({ ref: sliderRef }, settings, { children: items.map((item, index) => (_jsx("div", { className: "p-5 text-center", children: item }, index))) })) })] }));
};
export default Carousel;
