import { jsx as _jsx } from "react/jsx-runtime";
import { components } from "react-select";
/**
 * Override react-select's MultiValueContainer:
 *
 * MultiValueContainer wraps the Label and Remove in a Multi Value
 *
 * Styles are 2 Parts:
 *  1. This Component.
 *  2. React Select `styles` props for multiValue.
 */
const CustomMultiValueContainer = (props) => {
    return (_jsx("div", { className: "bg-pill-bg-default dark:bg-dark-pill-bg-default flex items-center justify-center space-x-2 break-all rounded-full py-1 pl-1 pr-2 text-base text-sm font-normal leading-4 text-gray-900 ", children: _jsx(components.MultiValueContainer, Object.assign({}, props)) }));
};
export default CustomMultiValueContainer;
