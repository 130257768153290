import { useCallback, useContext } from "react";
import { WizardActions, } from "./types";
import { WizardDispatchContext, WizardStateContext } from "./WizardProvider";
export const useWizardState = () => {
    const slideOverState = useContext(WizardStateContext);
    if (typeof slideOverState === "undefined") {
        throw new Error("useWizardState must be used within a WizardProvider");
    }
    return slideOverState;
};
export const useWizardDispatch = () => {
    const dispatch = useContext(WizardDispatchContext);
    if (typeof dispatch === "undefined") {
        throw new Error("useWizardDispatch must be used within a WizardProvider");
    }
    const incrementCurrentStep = useCallback(() => {
        dispatch({
            type: WizardActions.INCREMENT_CURRENT_STEP,
        });
        dispatch({
            type: WizardActions.SET_CAN_CONTINUE,
            payload: {
                canContinue: false,
            },
        });
    }, [dispatch]);
    const decrementCurrentStep = useCallback(() => {
        dispatch({
            type: WizardActions.DECREMENT_CURRENT_STEP,
        });
        dispatch({
            type: WizardActions.SET_CAN_CONTINUE,
            payload: {
                canContinue: false,
            },
        });
    }, [dispatch]);
    const setSteps = useCallback((steps) => dispatch({ type: WizardActions.SET_STEPS, payload: { steps } }), [dispatch]);
    const setCurrentStep = useCallback((stepIndex) => {
        dispatch({
            type: WizardActions.SET_CURRENT_STEP,
            payload: { currentStep: stepIndex },
        });
        dispatch({
            type: WizardActions.SET_CAN_CONTINUE,
            payload: {
                canContinue: false,
            },
        });
    }, [dispatch]);
    const markCompleted = useCallback((completedStep) => {
        dispatch({
            type: WizardActions.ADD_COMPLETED_STEP,
            payload: { completedStep },
        });
    }, [dispatch]);
    const unMarkCompleted = useCallback((completedStep) => {
        dispatch({
            type: WizardActions.REMOVE_COMPLETED_STEP,
            payload: { completedStep },
        });
    }, [dispatch]);
    return {
        incrementCurrentStep,
        decrementCurrentStep,
        setSteps,
        setCurrentStep,
        markCompleted,
        unMarkCompleted,
    };
};
export const useWizard = () => [
    useWizardState(),
    useWizardDispatch(),
];
export default useWizard;
