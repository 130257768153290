var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAllUsersAndGroups } from "./users";
import { getAuthInstance } from "../utils/auth0";
import { getPortalToken } from "./portalToken";
export const handleAuth = (userHandlers) => {
    return (req, res) => __awaiter(void 0, void 0, void 0, function* () {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const auth = yield getAuthInstance();
        let { query: { auth0: route }, } = req;
        route = Array.isArray(route) ? route[0] : route;
        if (route === "users") {
            return getAllUsersAndGroups(req, res);
        }
        if (route === "portalToken") {
            return getPortalToken(req, res);
        }
        if (route === "session") {
            try {
                const token = yield auth.getSession(req, res);
                const exp = token.accessTokenExpiresAt;
                if (exp < Math.floor(Date.now() / 1000)) {
                    yield auth.handleProfile(req, res, {
                        refetch: true,
                    });
                }
                res.status(200).json({ timestamp: exp });
            }
            catch (error) {
                res.status(error.status || 500).end();
            }
        }
        const customHandlers = {
            profile() {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield auth.handleProfile(req, res, {
                            refetch: true,
                        });
                    }
                    catch (error) {
                        res.status(error.status || 500).end();
                    }
                });
            },
            login() {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion
                        yield auth.handleLogin(req, res, {
                            returnTo: req.headers.referer,
                        });
                    }
                    catch (error) {
                        res.status(error.status || 500).end();
                    }
                });
            },
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
        return auth.handleAuth(Object.assign(Object.assign({}, customHandlers), userHandlers))(req, res);
    });
};
export default handleAuth();
