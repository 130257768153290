export const weatherIcons = [
    {
        code: 1,
        description: "Clear skies",
        icon: "clear%20skies",
        variant: true,
    },
    {
        code: 2,
        description: "Fair",
        icon: "fair",
        variant: true,
    },
    {
        code: 3,
        description: "Partly cloudy",
        icon: "partly_cloudy",
        variant: true,
    },
    {
        code: 4,
        description: "Mostly Cloudy",
        icon: "mostly_cloudy",
        variant: true,
    },
    {
        code: 5,
        description: "Cloudy",
        icon: "cloudy",
    },
    {
        code: 6,
        description: "Mist",
        icon: "mist",
    },
    {
        code: 7,
        description: "Fog",
        icon: "fog",
    },
    {
        code: 8,
        description: "Drizzle",
        icon: "drizzle",
    },
    {
        code: 9,
        description: "Rain",
        icon: "rain",
    },
    {
        code: 10,
        description: "Heavy rain",
        icon: "heavy_rain",
    },
    {
        code: 11,
        description: "Freezing drizzle",
        icon: "freezing_drizzle",
    },
    {
        code: 12,
        description: "Freezing rain",
        icon: "freezing_rain",
    },
    {
        code: 13,
        description: "Freezing heavy rain",
        icon: "freezing_heavy_rain",
    },
    {
        code: 14,
        description: "Rain and snow",
        icon: "rain_and_snow",
    },
    {
        code: 15,
        description: "Heavy rain and snow",
        icon: "heavy_rain_and_snow",
    },
    {
        code: 16,
        description: "Snow",
        icon: "snow",
    },
    {
        code: 17,
        description: "Heavy snow",
        icon: "heavy_snow",
    },
    {
        code: 18,
        description: "Blizzard",
        icon: "blizzard",
    },
    {
        code: 19,
        description: "Ice pellets",
        icon: "ice_pellets",
    },
    {
        code: 20,
        description: "Rain showers",
        icon: "rain_showers",
    },
    {
        code: 21,
        description: "Heavy rain showers",
        icon: "heavy_rain_showers",
    },
    {
        code: 22,
        description: "Freezing rain showers",
        icon: "freezing_rain_showers",
    },
    {
        code: 23,
        description: "Heavy freezing rain showers",
        icon: "heavy_freezing_rain_showers",
    },
    {
        code: 24,
        description: "Rain and snow showers",
        icon: "rain_and_snow_showers",
    },
    {
        code: 25,
        description: "Heavy rain and snow showers",
        icon: "heavy_rain_and_snow_showers",
    },
    {
        code: 26,
        description: "Snow showers",
        icon: "snow_showers",
    },
    {
        code: 27,
        description: "Heavy snow showers",
        icon: "heavy_snow_showers",
    },
    {
        code: 28,
        description: "Hail showers",
        icon: "hail_showers",
    },
    {
        code: 29,
        description: "Heavy hail showers",
        icon: "heavy_hail_showers",
    },
    {
        code: 30,
        description: "Thunderstorms",
        icon: "thunderstorms",
    },
    {
        code: 31,
        description: "Severe thunderstorms",
        icon: "heavy_thunderstorms",
    },
    {
        code: 32,
        description: "Windy",
        icon: "windy",
    },
];
