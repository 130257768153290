import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Switch } from "@headlessui/react";
export const Toggle = ({ id, testId, name, label, labelPosition = "left", hideLabel = false, disabled, checked = false, value = "ON", onChange, onBlur, onFocus, }) => {
    const [active, setActive] = useState(checked);
    const handleOnChange = (state) => {
        setActive(state);
        if (typeof onChange === "function") {
            onChange(state);
        }
    };
    const baseClasses = `${active
        ? "bg-brand-accent dark:bg-dark-brand-accent hover:bg-buttons-primary-bg-hover dark:hover:bg-blue-300"
        : "bg-components-light dark:bg-dark-components-light hover:bg-components-medium dark:hover:bg-dark-components-medium"} relative inline-flex h-6 w-12 items-center rounded transition-colors focus:outline-none`;
    const disabledClasses = `opacity-50 cursor-not-allowed`;
    const toggleClasses = disabled === true ? `${disabledClasses} ${baseClasses}` : `${baseClasses}`;
    return (_jsx(Switch.Group, { as: "div", id: id, "data-eco-component": "toggle", children: _jsxs("div", { className: "flex items-center", children: [labelPosition === "left" && (_jsx(Switch.Label, { hidden: hideLabel, "aria-label": label, as: "label", className: "text-secondary dark:text-dark-secondary mr-4 text-sm font-medium", children: label })), _jsxs(Switch, { as: "button", "data-id": `${id !== undefined ? id : ""}-toggle}`, "data-test-id": testId, "data-eco-component": "toggle-button", name: name, checked: active, value: value, disabled: disabled, onChange: handleOnChange, onBlur: onBlur, onFocus: onFocus, className: toggleClasses, children: [_jsx("span", { className: `${active ? "translate-x-7" : "translate-x-1"} dark:bg-dark-components-baseComponent bg-components-baseComponent inline-block h-4 w-4 transform rounded transition-transform` }), _jsx("span", { className: `${active
                                ? "text-light dark:text-dark-light translate-x-[6px]"
                                : "text-primaryText dark:text-dark-primaryText translate-x-[25px]"} absolute transform text-xs `, children: active ? "On" : "Off" })] }), labelPosition === "right" && (_jsx(Switch.Label, { hidden: hideLabel, "aria-label": label, as: "label", className: "text-secondary dark:text-dark-secondary ml-4 text-sm font-medium", children: label }))] }) }));
};
