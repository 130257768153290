var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Code } from "../code";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import raw from "rehype-raw";
import { useRouter } from "next/router";
export const MarkdownProse = ({ children }) => {
    const route = useRouter();
    const anchorify = (heading) => {
        return heading[0].replace(/ /g, "-").toLowerCase();
    };
    return (_jsx(ReactMarkdown, { remarkPlugins: [gfm], rehypePlugins: [raw], components: {
            img(_a) {
                var _b;
                var { node } = _a, props = __rest(_a, ["node"]);
                // images in our markdown files are stored in a /assets/<imgName> location so that they render
                // properly in the repo. But those images don't come across in the parsing of the markdown file
                // in React so there is a copy (unfortunately) of the image in the doc's site /public/<pageRoute>/<imgName>
                // location. Because of that, we need to parse the image src URL from the markdown file
                // and change it to point to that doc site directory.
                const src = (_b = node.properties) === null || _b === void 0 ? void 0 : _b.src;
                const fix = src.split("/");
                const currRouteRoot = route.pathname.split("/")[1];
                const imgName = fix[fix.length - 1];
                let assetPathFix = `/${currRouteRoot}/${imgName}`;
                return _jsx("img", { src: assetPathFix });
            },
            div(_a) {
                var _b;
                var { node } = _a, props = __rest(_a, ["node"]);
                if (((_b = node.properties) === null || _b === void 0 ? void 0 : _b.id) === "toc") {
                    return null;
                }
                return _jsx("div", { children: props.children });
            },
            hr(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return _jsx("p", { className: "mb-2 mt-2", children: props.children });
            },
            h1(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("h1", { id: anchorify(props.children), className: "scroll-m-16", children: props.children }));
            },
            h2(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("h2", { id: anchorify(props.children), className: "scroll-m-16", children: props.children }));
            },
            h3(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("h3", { id: anchorify(props.children), className: "scroll-m-16", children: props.children }));
            },
            h4(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("h4", { id: anchorify(props.children), className: "scroll-m-16", children: props.children }));
            },
            code(_a) {
                var _b;
                var { node, inline } = _a, props = __rest(_a, ["node", "inline"]);
                const match = /language-(\w+)/.exec((_b = props.className) !== null && _b !== void 0 ? _b : "");
                const language = match ? match[1] : "";
                return (_jsx(Code, { language: language, inline: inline, children: props.children }));
            },
            // TODO: refactor Table so that classes are stored in DRYer react componentes
            table(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("div", { className: "not-prose py-2", children: _jsx("table", Object.assign({ className: "relative min-w-full border-separate", style: { borderSpacing: 0 } }, props, { children: props.children })) }));
            },
            thead(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return _jsx("thead", { className: "z-sticky relative", children: props.children });
            },
            th(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("th", { scope: "col", className: "bg-container-medium dark:bg-dark-container-medium text-primaryText dark:text-dark-primaryText sticky top-0 bg-opacity-75 px-2 py-2.5 text-left text-sm font-semibold backdrop-blur backdrop-filter first-of-type:rounded-tl-md last-of-type:rounded-tr-md", children: props.children }));
            },
            td(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("td", { className: "text-secondary dark:text-dark-secondary break-normal px-2 py-2 text-left text-sm", children: props.children }));
            },
            tr(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return (_jsx("tr", Object.assign({ className: "even:bg-container-baseContainer dark:even:bg-dark-container-baseContainer hover:bg-components-lighter dark:hover:bg-dark-components-lighter odd:bg-container-light dark:odd:bg-dark-container-light border-b transition duration-300 ease-in-out" }, props, { children: props.children })));
            },
            tbody(_a) {
                var { node } = _a, props = __rest(_a, ["node"]);
                return _jsx("tbody", Object.assign({}, props, { children: props.children }));
            },
        }, children: children }));
};
