import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
export const SelectedFile = ({ path, size, icon, errors, handleRemove, }) => {
    let border = errors
        ? "border-error dark:border-dark-error border-2"
        : "border-dark dark:border-dark-dark border-1";
    const errorMsg = errors === null || errors === void 0 ? void 0 : errors.join(", ");
    return (_jsxs("div", { className: `${border} flex flex-row rounded-md border p-3`, children: [_jsxs("div", { className: "grow space-y-2", children: [_jsx("h5", { className: "text-primaryText dark:text-dark-primaryText text-sm", children: path }), !errors && (_jsxs("p", { className: "text-tertiary dark:text-dark-tertiary text-xs", children: [(size / Math.pow(1024, 2)).toFixed(2), " MB"] })), errors && (_jsxs("div", { className: "text-error dark:text-dark-error flex flex-row items-center gap-2", children: [_jsx(IconV2, { type: "fa", icon: {
                                    icon: ["fas", "triangle-exclamation"],
                                    fixedWidth: true,
                                    size: "sm",
                                } }), _jsx("p", { className: " text-xs", children: errorMsg })] }))] }), !errors && (_jsx("div", { "data-file": path, children: _jsx("button", { onClick: (e) => handleRemove(e), className: "text-primaryText dark:text-dark-primaryText hover:text-link-hover dark:hover:text-dark-link-hover dark:hover:bg-dark-components-light hover:bg-components-light flex size-4 items-center justify-center rounded-full leading-none", children: _jsx(IconV2, { type: "fa", icon: {
                            icon: ["far", "xmark"],
                            fixedWidth: true,
                        } }) }) }))] }));
};
export default SelectedFile;
