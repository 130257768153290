import { useState, useEffect } from "react";
import { Viewport, Orientation } from "./types";
export const useEnvironment = () => {
    const getViewport = () => {
        if (typeof window !== "undefined") {
            if (window.matchMedia("(min-width: 1280px)").matches) {
                return Viewport.XL;
            }
            else if (window.matchMedia("(min-width: 960px)").matches) {
                return Viewport.L;
            }
            else if (window.matchMedia("(min-width: 600px)").matches) {
                return Viewport.M;
            }
            return Viewport.S;
        }
        return Viewport.S;
    };
    const getOrientation = () => {
        if (typeof window !== "undefined") {
            if (window.matchMedia("(orientation: portrait)").matches) {
                return Orientation.Portrait;
            }
            return Orientation.Landscape;
        }
        return Orientation.Landscape;
    };
    const [environment, setEnvironment] = useState({
        viewport: getViewport(),
        orientation: getOrientation(),
    });
    useEffect(() => {
        function handleResize() {
            setEnvironment({
                viewport: getViewport(),
                orientation: getOrientation(),
            });
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return environment;
};
