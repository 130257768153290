import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Disclosure } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const DisclosureFull = ({ id, testId, label, panel, defaultOpen, onToggle, }) => {
    return (_jsx("div", { id: id, "data-test-id": testId, children: _jsx(Disclosure, { defaultOpen: defaultOpen, children: ({ open }) => (_jsxs("div", { className: "py-2", children: [_jsxs(Disclosure.Button, { className: classNames(open
                            ? "text-blue-700 dark:text-blue-400"
                            : "text-gray-900 dark:text-gray-300", "flex w-full items-center justify-between gap-6 py-2 text-left font-semibold"), "data-eco-component": "disclosure-button", onClick: onToggle, children: [_jsx("span", { className: "hover:text-blue-700 dark:hover:text-blue-300", children: label }), _jsx("div", { className: classNames(open
                                    ? "text-link dark:text-dark-link rotate-180"
                                    : "text-secondary dark:text-dark-secondary rotate-0", " flex h-6 w-6 shrink-0 grow-0 items-center justify-center rounded-full transition-transform"), children: _jsx(IconV2, { type: "fa", icon: { icon: ["far", "chevron-down"], fixedWidth: true } }) })] }), _jsx(Disclosure.Panel, { "data-eco-component": "disclosure-panel", className: "mb-2", children: panel })] })) }) }));
};
export default Disclosure;
