import { jsx as _jsx } from "react/jsx-runtime";
import { ModalIconVariants } from "./types";
import { IconV2 } from "../icon_v2";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const ModalIcon = ({ variant = ModalIconVariants.INFO, }) => {
    let icon;
    let iconBgColor, iconColor = "";
    switch (variant) {
        case "error":
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "triangle-exclamation"], size: "xl" } }));
            iconBgColor = "bg-pill-bg-danger dark:bg-dark-pill-bg-danger";
            iconColor = "text-red-600 dark:text-red-400";
            break;
        case "success":
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-check"], size: "xl" } }));
            iconBgColor = "bg-pill-bg-positiveBg dark:bg-dark-pill-bg-positiveBg";
            iconColor = "text-green-600 dark:text-green-400";
            break;
        case "warning":
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-exclamation"], size: "xl" } }));
            iconBgColor = "bg-pill-bg-warningBg dark:bg-dark-pill-bg-warningBg";
            iconColor = "text-orange-600 dark:text-orange-400";
            break;
        case "destructive":
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "triangle-exclamation"], size: "xl" } }));
            iconBgColor = "bg-pill-bg-danger dark:bg-dark-pill-bg-danger";
            iconColor = "text-red-600 dark:text-red-400";
            break;
        default:
            icon = (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-info"], size: "xl" } }));
            iconBgColor = "bg-pill-bg-info dark:bg-dark-pill-bg-info";
            iconColor = "text-blue-600 dark:text-blue-400";
            break;
    }
    return (_jsx("div", { className: classNames("mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:mr-4", iconBgColor, iconColor), children: icon }));
};
export default ModalIcon;
