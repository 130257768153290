import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useMemo } from "react";
import mergeRefs from "react-merge-refs";
import { useAdvancedDatePicker } from "../AdvancedDatePickerProvider";
import { subMinutes, subHours, subDays, subWeeks, subMonths, subYears, } from "date-fns";
export default React.forwardRef(function RadioButton({ name, label, selectedTimeRange, checked, onChange: onDateChanged, }, ref) {
    const radioButtonRef = useRef(null);
    const { value, handleDateChangedValue, handleDropdownState, handleSelectedSuggestion, } = useAdvancedDatePicker();
    const refs = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    () => mergeRefs([ref, radioButtonRef]), [ref]);
    const isSelectedClass = checked
        ? `[&:not(:hover)]:bg-blue-700 dark:[&:not(:hover)]:bg-blue-800 [&:not(:hover)]:text-white`
        : ``;
    const formatSuggestedDate = (unit) => {
        var _a, _b, _c, _d, _e, _f;
        const dateValue = {
            startDate: "",
            endDate: new Date().toISOString(),
        };
        const minuteValue = Object.assign(Object.assign({}, dateValue), { startDate: subMinutes(new Date(), (_a = selectedTimeRange.amount) !== null && _a !== void 0 ? _a : 0).toISOString() });
        const hourValue = Object.assign(Object.assign({}, dateValue), { startDate: subHours(new Date(), (_b = selectedTimeRange.amount) !== null && _b !== void 0 ? _b : 0).toISOString() });
        const dayValue = Object.assign(Object.assign({}, dateValue), { startDate: subDays(new Date(), (_c = selectedTimeRange.amount) !== null && _c !== void 0 ? _c : 0).toISOString() });
        const weekValue = Object.assign(Object.assign({}, dateValue), { startDate: subWeeks(new Date(), (_d = selectedTimeRange.amount) !== null && _d !== void 0 ? _d : 0).toISOString() });
        const monthValue = Object.assign(Object.assign({}, dateValue), { startDate: subMonths(new Date(), (_e = selectedTimeRange.amount) !== null && _e !== void 0 ? _e : 0).toISOString() });
        const yearValue = Object.assign(Object.assign({}, dateValue), { startDate: subYears(new Date(), (_f = selectedTimeRange.amount) !== null && _f !== void 0 ? _f : 0).toISOString() });
        switch (unit) {
            case "minute":
                handleDateChangedValue(minuteValue);
                onDateChanged(minuteValue);
                break;
            case "hour":
                handleDateChangedValue(hourValue);
                onDateChanged(hourValue);
                break;
            case "day":
                handleDateChangedValue(dayValue);
                onDateChanged(dayValue);
                break;
            case "week":
                handleDateChangedValue(weekValue);
                onDateChanged(weekValue);
                break;
            case "month":
                handleDateChangedValue(monthValue);
                onDateChanged(monthValue);
                break;
            case "year":
                handleDateChangedValue(yearValue);
                onDateChanged(yearValue);
                break;
            default:
        }
    };
    return (_jsxs("div", { className: `text-primaryText dark:text-dark-primaryText hover:bg-buttons-secondary-bg-hover dark:hover:bg-dark-buttons-secondary-bg-hover relative px-3 py-1 ${isSelectedClass}`, "aria-checked": checked, children: [_jsx("span", { children: label }), _jsx("input", { className: "absolute left-0 top-0 h-full w-full cursor-pointer opacity-0 ", type: "button", ref: refs, name: name, 
                // empty handler to suppress React controllability warning
                onChange: () => { }, onClick: () => {
                    var _a;
                    (_a = radioButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    if (checked) {
                        return;
                    }
                    handleSelectedSuggestion(selectedTimeRange);
                    formatSuggestedDate(selectedTimeRange.unit);
                    handleDropdownState(false);
                } })] }));
});
