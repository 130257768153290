import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Alert } from "../alert";
import useWizard from "./useWizard";
export const WizardPanels = ({ children }) => {
    const [{ currentStep }] = useWizard();
    const panelsArray = React.Children.toArray(children);
    // This will handle missing panels. No error will technically be thrown by React.
    if (currentStep >= panelsArray.length) {
        // currentStep is 0 indexed
        const message = `This STEP (${currentStep + 1}) seems to be missing an associated panel. `;
        return (_jsx(Alert, { id: "alert-error", testId: "test-alert-error", variant: "error", heading: "Error!", message: message, dismissible: true, onDismiss: () => void 0 }));
    }
    // Only display panel based on current step
    return (_jsx("div", { id: "eco-wizard-panels", className: "panels", children: panelsArray.filter((panel, idx) => idx === currentStep) }));
};
export default WizardPanels;
