var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState, forwardRef, useRef } from "react";
import { Transition } from "@headlessui/react";
import { offset, flip, shift, autoUpdate, useFloating, useInteractions, useHover, useFocus, useRole, useDismiss, arrow, safePolygon, } from "@floating-ui/react-dom-interactions";
import { FloatingArrow } from "@floating-ui/react";
import mergeRefs from "react-merge-refs";
import { IconV2 } from "../icon_v2";
/**
 * Using ReferenceWrapper because Floating UI was not rendering tooltip like in the examples provided.
 * React would complete about not properly forwarding references.
 *
 * https://github.com/floating-ui/floating-ui/issues/1832
 * https://codesandbox.io/s/determined-wood-k5lcjc?file=/src/App.tsx
 */
// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const ReferenceWrapper = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx("span", Object.assign({}, props, { ref: ref, children: children })));
});
export const Tooltip = ({ id, label, placement = "top", tooltipOffset = 18, shiftPadding = 2, xAxisOffset, learnMoreHandler, children, }) => {
    var _a;
    const arrowRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { x, y, reference, floating, strategy, context } = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(tooltipOffset),
            flip(),
            shift({ padding: shiftPadding }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });
    /**
     * This method handles the marshalling of the learnMore clickHandler based on whether a value was declared for the `learnMoreHandler` prop or not.
     *
     * `safePolygon` is an API provided by floating-ui to prevent the closing of the tooltip if the user hovers over the tooltip allowing the user to interact with content in the tooltip.
     *
     * @reference https://floating-ui.com/docs/usehover#safepolygon
     * @returns Either a safePolygon instance or null
     */
    const handleCloseFn = () => {
        return learnMoreHandler ? safePolygon() : null;
    };
    const { getReferenceProps, getFloatingProps } = useInteractions([
        // useHover(context, { restMs: 40 }),
        useHover(context, { restMs: 40, handleClose: handleCloseFn() }),
        useFocus(context),
        useRole(context, { role: "tooltip" }),
        useDismiss(context, { escapeKey: true }),
    ]);
    // Preserve the consumer's ref
    const ref = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    () => mergeRefs([reference, children.ref]), [reference, children]);
    return (_jsxs(_Fragment, { children: [_jsx(ReferenceWrapper, Object.assign({}, getReferenceProps({ ref }), { children: children })), _jsx(Transition, { show: open, enter: "transition-opacity duration-150", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition-opacity duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0", className: "absolute z-50 opacity-0", children: _jsxs("div", Object.assign({ id: id }, getFloatingProps({
                    ref: floating,
                    className: `max-w-[250px] w-max rounded-md  bg-components-inverse dark:bg-dark-components-inverse px-3 py-1.5 text-sm text-inverse-primary dark:text-dark-inverse-primary drop-shadow-[4px_4px_14px_rgba(0,0,0,.20)] ${placement}`,
                    style: {
                        position: strategy,
                        top: y !== null && y !== void 0 ? y : 0,
                        left: (_a = xAxisOffset !== null && xAxisOffset !== void 0 ? xAxisOffset : x) !== null && _a !== void 0 ? _a : 0,
                    },
                }), { "data-eco-component": "tooltip", role: "tooltip", children: [_jsx("span", { children: label }), learnMoreHandler && (_jsx("div", { className: "text-link dark:text-dark-link pt-2.5", children: _jsx("a", { href: "#", onClick: (e) => learnMoreHandler(e), className: "cursor-pointer", children: _jsxs("div", { className: "flex flex-row space-x-2", children: [_jsx("span", { children: "Learn more" }), _jsx(IconV2, { type: "fa", icon: {
                                                icon: ["fal", "square-arrow-up-right"],
                                            } })] }) }) })), _jsx(FloatingArrow
                        // @ts-ignore
                        , { 
                            // @ts-ignore
                            context: context, ref: arrowRef, className: "fill-[components-inverse] dark:fill-white\n    [&>path:first-of-type]:stroke-[#141518] dark:[&>path:first-of-type]:stroke-white [&>path:last-of-type]:stroke-[#141518] dark:[&>path:last-of-type]:stroke-white", height: 8, width: 16 })] })) })] }));
};
export default Tooltip;
