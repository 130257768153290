import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Tabs } from "../tabs";
import { IconV2 } from "../icon_v2";
export const PopoverPanel = ({ id, testId, selectedTab, onDismiss, children, }) => {
    const childrenArray = React.Children.toArray(children);
    const [layerSelector, presets, mapSettings] = childrenArray;
    const defaultTitle = selectedTab === "map-settings"
        ? "Settings"
        : selectedTab === "presets"
            ? "Presets"
            : "Layers";
    const [title, setTitle] = useState(defaultTitle);
    const handleChange = (e) => {
        switch (e) {
            case 2:
                setTitle("Settings");
                break;
            case 1:
                setTitle("Presets");
                break;
            case 0:
            default:
                setTitle("Layers");
                break;
        }
    };
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "eco-timeline-popover-panel", children: [_jsxs("div", { className: "mx-2 my-2 flex justify-between", children: [_jsx("div", { children: title }), _jsx("button", { onClick: onDismiss, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                        icon: "xmark",
                                        fixedWidth: true,
                                        size: "lg",
                                    } }), _jsx("span", { className: "sr-only", children: "Close" })] }) })] }), _jsx(Tabs, { selectedId: selectedTab, onChange: (e) => handleChange(e), tabs: [
                    {
                        id: "layer-selector",
                        label: "Layers",
                        iconName: ["fas", "layer-group"],
                        panel: _jsx("div", { className: "h-[32rem]", children: layerSelector }),
                    },
                    {
                        id: "presets",
                        label: "Presets",
                        iconName: ["fas", "square-star"],
                        panel: _jsx("div", { className: "h-[32rem]", children: presets }),
                    },
                    {
                        id: "map-settings",
                        label: "Settings",
                        iconName: ["fas", "gear"],
                        panel: _jsx("div", { className: "h-[32rem]", children: mapSettings }),
                    },
                ] })] }));
};
