import { jsx as _jsx } from "react/jsx-runtime";
import { Tabs } from "../../tabs";
import Calendar from "./Calendar";
import { generateUniqueId } from "./utils/useUniqueId";
import SuggestedTimeRange from "./suggested-time-range";
import { useAdvancedDatePicker } from "./AdvancedDatePickerProvider";
const DateRangePickerOptions = ({ id, suggestedTimeRangeOptions = [], showTimePicker, i18nStrings = {}, startOfWeek, pickerValue, dateFormat = "yyyy-MM-dd", minDate, maxDate, dateRangeOptions, onChange, }) => {
    var _a, _b;
    const { selectedTabIndex, handleSelectedTabIndex } = useAdvancedDatePicker();
    return (_jsx("div", { className: "bg-container-light dark:bg-dark-container-light rounded-md px-3 pb-4", children: suggestedTimeRangeOptions.length > 1 ? (_jsx(Tabs, { onChange: (i) => handleSelectedTabIndex(i), selectedIndex: selectedTabIndex, selectedId: "suggestedTimeFramesType", tabs: [
                {
                    id: "suggestedTimeFramesType",
                    label: (_a = i18nStrings.suggestedTimeFramesTypeTitle) !== null && _a !== void 0 ? _a : "Suggested Time Frames",
                    panel: (_jsx(SuggestedTimeRange, { options: suggestedTimeRangeOptions, onChange: onChange })),
                },
                {
                    id: "calendarType",
                    label: (_b = i18nStrings.calendarTypeTitle) !== null && _b !== void 0 ? _b : "Calendar",
                    iconName: "fa-regular fa-calendar",
                    panel: (_jsx(Calendar, { i18nStrings,
                        showTimePicker,
                        startOfWeek,
                        dateFormat,
                        minDate,
                        maxDate,
                        pickerValue,
                        dateRangeOptions,
                        onChange })),
                },
            ], id: `${id !== null && id !== void 0 ? id : generateUniqueId()}-tabs` })) : (_jsx(Calendar, { i18nStrings,
            showTimePicker,
            startOfWeek,
            dateFormat,
            minDate,
            maxDate,
            pickerValue,
            dateRangeOptions,
            onChange })) }));
};
export default DateRangePickerOptions;
