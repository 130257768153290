import getConfig from "next/config";
export const getUserAccounts = (idToken, customerId) => {
    const { publicRuntimeConfig } = getConfig();
    const { DTN_PRODUCT_CODE } = publicRuntimeConfig;
    if (DTN_PRODUCT_CODE !== undefined) {
        const appInfo = idToken["https://auth.dtn.com/appInfo"][DTN_PRODUCT_CODE];
        if (appInfo && "customers" in appInfo) {
            return appInfo.customers;
        }
    }
    return [];
};
