import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
import { Tooltip } from "../tooltip";
import { StatusIndicator } from "../status-indicator";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const ButtonTab = ({ tab, index }) => {
    let buttonBorderStyle = "border-dark border-t dark:border-dark-dark border-x rounded-t-md";
    if (index === "first") {
        buttonBorderStyle = `${buttonBorderStyle} rounded-tl-md  `;
    }
    else if (index === "last") {
        buttonBorderStyle = `${buttonBorderStyle} rounded-tr-md border-dark dark:border-dark-dark border-r `;
    }
    return (_jsx(Tab, { as: Fragment, children: ({ selected }) => (_jsxs("button", { className: classNames(tab.disabled === true
                ? "text-primaryText/50 hover:text-primaryText/50 dark:text-dark-primaryText/50 bg-container-light/50 dark:bg-dark-container-light/50"
                : "focus:outline-none focus-visible:outline-none", selected
                ? `text-primaryText dark:text-dark-primaryText bg-container-light dark:bg-dark-container-baseContainer dark:border-b-dark-container-baseContainer h-full dark:border-b-[#191a1f]`
                : "text-link dark:text-dark-link bg-buttons-tertiary-bg-hover dark:bg-dark-components-lighter border-b-dark dark:border-b-dark-dark hover:text-link-hover hover:bg-components-lighter border-b", `group relative flex w-full items-center whitespace-nowrap px-4 py-2 text-base font-medium ${buttonBorderStyle}`), disabled: tab.disabled, "data-test-id": tab.testId, role: "tab", "aria-selected": selected ? true : false, "data-selected": selected ? true : false, children: [tab.hideLabel === true && tab.iconName !== undefined && (_jsx(Tooltip, { label: tab.label, id: `tab-${tab.label}`, children: _jsxs("div", { className: "flex items-center space-x-2", children: [_jsx("div", { className: "p-0.5", children: _jsx(IconV2, { type: "fa", icon: {
                                        icon: tab.iconName,
                                        fixedWidth: true,
                                        size: "xl",
                                    } }) }), tab.status && (_jsx(StatusIndicator, { hideStatus: tab.status.hideStatus, label: tab.status.label, variant: tab.status.variant }))] }) })), tab.hideLabel !== true && tab.iconName !== undefined && (_jsxs(_Fragment, { children: [_jsx("div", { className: "mr-2 p-0.5", children: _jsx(IconV2, { type: "fa", icon: {
                                    icon: tab.iconName,
                                    fixedWidth: true,
                                    size: "xl",
                                } }) }), tab.label] })), tab.iconName === undefined && (_jsx("div", { className: "p-0.5", children: tab.label })), tab.hideLabel !== true && tab.status && (_jsx("span", { className: "ml-2", children: _jsx(StatusIndicator, { hideStatus: tab.status.hideStatus, label: tab.status.label, variant: tab.status.variant }) }))] })) }));
};
export default ButtonTab;
