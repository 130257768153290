import { jsx as _jsx } from "react/jsx-runtime";
import { components } from "react-select";
/**
 * Override react-select's Group:
 *
 * The wrapper around each group if the Select has groups in its data.
 * The default component is responsible both for mapping its options, as well as rendering its data into the GroupHeading.
 */
const Group = (props) => {
    return (_jsx("div", { className: "my-2 [&_.eco-option]:pl-8", children: _jsx(components.Group, Object.assign({}, props)) }));
};
export default Group;
