import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { components } from "react-select";
import Badge from "./Badge";
import CollapseGroup from "./CollapseGroup";
const MultiValue = (props) => {
    const { index, getValue, selectProps } = props;
    const { collapsedSelectedValues, collapseGroups, collapsedGroupState, optionSelected, onChange, options, } = selectProps;
    const maxToShow = 1;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);
    const [first] = getValue();
    if (collapsedSelectedValues === true) {
        return index < maxToShow ? (_jsx(components.MultiValue, Object.assign({}, props))) : index === maxToShow ? (_jsx(components.MultiValue, Object.assign({}, props, { children: _jsx(Badge, { items: overflow, firstValue: first.value }) }))) : null;
    }
    // show collapse / expanding groups icon
    const multiVal = getValue()[index];
    if (collapseGroups && (multiVal === null || multiVal === void 0 ? void 0 : multiVal.groupLabel) !== undefined) {
        const label = multiVal.groupLabel;
        const group = collapsedGroupState.groups.find((grp) => grp.label === label);
        const collapsed = group ? group.collapsed : false;
        const firstGroupInstance = optionSelected.find((opt) => opt.groupLabel === label);
        const collapsedProp = collapsed ? true : false;
        if ((firstGroupInstance === null || firstGroupInstance === void 0 ? void 0 : firstGroupInstance.value) === multiVal.value) {
            return (_jsxs(_Fragment, { children: [_jsx(props.components.Container, Object.assign({}, props, { children: _jsx(CollapseGroup, { label: label, collapseGroup: selectProps.collapseGroup, expandGroup: selectProps.expandGroup, collapsed: collapsedProp, selectedOptions: group === null || group === void 0 ? void 0 : group.selectedOptions, totalOptions: group === null || group === void 0 ? void 0 : group.totalOptions, onChange: onChange, optionSelected: optionSelected }) })), !collapsed && _jsx(components.MultiValue, Object.assign({}, props))] }));
        }
        // if collapsed, return nothing
        if ((group === null || group === void 0 ? void 0 : group.collapsed) !== undefined && group.collapsed) {
            return _jsx(_Fragment, {});
        }
        // if expanded, return usual multivalue
        return _jsx(components.MultiValue, Object.assign({}, props));
    }
    return _jsx(components.MultiValue, Object.assign({}, props));
};
export default MultiValue;
