var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable no-shadow */
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { isGroup, } from "./interfaces";
import MultiValueContainer from "./MultiValueContainer";
import Placeholder from "./Placeholder";
import Group from "./Group";
import GroupHeading from "./GroupHeading";
import Label from "../label/Label";
import DropdownIndicator from "./DropdownIndicator";
import ClearIndicator from "./ClearIndicator";
import Menu from "./Menu";
import MenuList from "./MenuList";
import Option from "./Option";
import MultiValue from "./MultiValue";
import MultiValueRemove from "./MultiValueRemove";
import { useCollapsedGroups, } from "./useCollapsedGroups";
/**
 * This component wraps the react-select 3rd party tool.
 *
 * Documentation on react-select:
 *
 *  - https://react-select.com/home
 *
 *
 * HACK/OPTIMIZE:
 *
 * react-select has some suggestions on how best to wrap the select component.
 * https://react-select.com/typescript#select-generics
 *
 *
 * @param AutocompleteProps
 * @returns Autocomplete
 */
export const Autocomplete = (_a) => {
    var _b;
    var { id, testId, options, bgColor = "white", isMulti = false, blurInputOnSelect = false, closeMenuOnSelect = true, hideLabel = false, isClearable, isDisabled, isLoading, placeholderText = "Select...", isSearchable = true, labelFor, invalid, required, optional, value, onChange, collapsedSelectedValues = false, labelPosition = "top", helperText, onKeyDown, onInputChange, onBlur, onFocus, singleLineCollapse = false, selectedIndidacatorInMenu = "icon", collapseGroups = false } = _a, props = __rest(_a, ["id", "testId", "options", "bgColor", "isMulti", "blurInputOnSelect", "closeMenuOnSelect", "hideLabel", "isClearable", "isDisabled", "isLoading", "placeholderText", "isSearchable", "labelFor", "invalid", "required", "optional", "value", "onChange", "collapsedSelectedValues", "labelPosition", "helperText", "onKeyDown", "onInputChange", "onBlur", "onFocus", "singleLineCollapse", "selectedIndidacatorInMenu", "collapseGroups"]);
    // add group label to all options for collapsing groups
    const addGroupsToOptions = (options) => {
        let newOptions;
        newOptions = options.map((opt) => {
            if (isGroup(opt)) {
                return {
                    label: opt.label,
                    options: opt.options.map((optGroup) => (Object.assign(Object.assign({}, optGroup), { groupLabel: opt.label }))),
                };
            }
            return opt;
        });
        return newOptions;
    };
    const opts = addGroupsToOptions(options);
    let [optionSelected, setOptionSelected] = useState();
    const [inputValue, setInputValue] = useState("");
    const [menuPortalTarget, setMenuPortalTarget] = useState();
    useEffect(() => {
        var _a;
        setMenuPortalTarget((_a = props.menuPortalTarget) !== null && _a !== void 0 ? _a : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.menuPortalTarget]);
    const selectRef = useRef(null);
    const containerRef = useRef(null);
    const optionValue = value !== null && value !== void 0 ? value : optionSelected;
    const [hasFocused, setHasFocused] = useState(false);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (optionSelected &&
            optionSelected.length === 0 &&
            hasFocused) {
            const timeout = setTimeout(() => {
                document.getElementById(id).focus();
                document.getElementById(id).blur();
            }, 10);
            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionSelected]);
    let backgroundColor;
    if (invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (bgColor === "grey" || bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    const selectAllOption = {
        value: "all",
        label: "Select All",
    };
    const getOptions = () => [selectAllOption, ...opts];
    const getTotalOptions = (options, totalOptions) => {
        options.forEach((opt) => {
            if (Object.keys(opt).includes("options")) {
                totalOptions.concat(
                // @ts-expect-error: array drama
                getTotalOptions(opt.options, totalOptions));
            }
            else {
                // @ts-expect-error: array drama
                totalOptions.push(opt);
            }
        });
        return totalOptions;
    };
    // collapsed groups
    const { collapsedGroupState, initializeGroups, selectInGroup, selectAllGroup, removeFromGroup, removeAllGroup, collapseGroup, expandGroup, } = useCollapsedGroups();
    // Inititialize collapsed group state on load
    useEffect(() => {
        if (collapseGroups) {
            initializeGroups(getOptions());
        }
    }, [collapseGroups]);
    const handleMultiOnChange = (newValue, actionMeta) => {
        const { action, option, removedValue, removedValues } = actionMeta;
        const allOptions = getTotalOptions(opts, []);
        const selectAllOptions = [selectAllOption, ...allOptions];
        switch (action) {
            case "select-option":
                // if in group, update selected group count
                if ((option === null || option === void 0 ? void 0 : option.groupLabel) !== undefined) {
                    selectInGroup(option.groupLabel);
                }
                // check for select all
                if (option.value === selectAllOption.value) {
                    onChange(selectAllOptions);
                    setOptionSelected(selectAllOptions);
                    if (collapseGroups) {
                        opts.forEach((opt) => selectAllGroup(opt.label));
                    }
                    // check for select all in group
                }
                else if (option.value.includes("select-eco-group-")) {
                    const groupOptions = opts.find((opt) => opt.label === option.label);
                    if (groupOptions !== undefined) {
                        selectAllGroup(option.label);
                        let newOptions;
                        if (optionSelected) {
                            newOptions = [];
                            const optionsOtherGroup = optionSelected.filter((opt) => opt.groupLabel !== (option === null || option === void 0 ? void 0 : option.label));
                            newOptions = [...optionsOtherGroup, ...groupOptions.options];
                        }
                        else {
                            newOptions = groupOptions.options;
                        }
                        onChange(newOptions);
                        setOptionSelected(newOptions);
                    }
                }
                else {
                    const val = newValue;
                    if (val.length === allOptions.length) {
                        onChange(selectAllOptions);
                        setOptionSelected(selectAllOptions);
                    }
                    else {
                        onChange(val);
                        setOptionSelected(val);
                    }
                }
                setHasFocused(false);
                break;
            case "remove-value":
                if (removedValue.value === selectAllOption.value) {
                    onChange([]);
                    setOptionSelected([]);
                }
                else {
                    if (removedValue.groupLabel !== undefined) {
                        removeFromGroup(removedValue.groupLabel);
                    }
                    let updatedValues = optionValue.filter((i) => i.value !== removedValue.value &&
                        i.value !== selectAllOption.value);
                    onChange(updatedValues);
                    setOptionSelected(updatedValues);
                }
                break;
            case "deselect-option":
                if (option.value === selectAllOption.value) {
                    onChange([]);
                    setOptionSelected([]);
                }
                else if (option.value.includes("remove-eco-group-")) {
                    removeAllGroup(option.label);
                    let updatedValues;
                    if (removedValues) {
                        updatedValues = optionSelected.filter((opt) => !removedValues.some((x) => opt.value === x.value));
                    }
                    else {
                        updatedValues = optionSelected;
                    }
                    onChange(updatedValues.filter((opt) => opt.value !== selectAllOption.value));
                    setOptionSelected(updatedValues.filter((opt) => opt.value !== selectAllOption.value));
                }
                else {
                    if ((option === null || option === void 0 ? void 0 : option.groupLabel) !== undefined) {
                        removeFromGroup(option.groupLabel);
                    }
                    let updatedValues = optionValue.filter((i) => i.value !== option.value && i.value !== selectAllOption.value);
                    onChange(updatedValues);
                    setOptionSelected(updatedValues);
                }
                break;
            case "clear":
                onChange([]);
                setOptionSelected([]);
                if (collapseGroups) {
                    opts.forEach((opt) => removeAllGroup(opt.label));
                }
                break;
            default:
                // eslint-disable-next-line no-case-declarations
                const val = newValue;
                if (val.length === opts.length) {
                    onChange([...getOptions()]);
                    setOptionSelected([...getOptions()]);
                }
                else {
                    onChange(val);
                    setOptionSelected(val);
                }
                break;
        }
    };
    const handleSingleOnChange = (newValue) => {
        onChange(newValue);
        setOptionSelected(newValue);
        setHasFocused(false);
    };
    const handleOnInputChange = (newValue, actionMeta) => {
        setInputValue(newValue);
        if (!onInputChange) {
            return;
        }
        const { action, prevInputValue } = actionMeta;
        if ((action === "input-change" || action === "menu-close") &&
            newValue !== prevInputValue) {
            onInputChange(newValue, prevInputValue);
        }
    };
    const handleOnFocus = (e) => {
        setHasFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    };
    // This check resolves some prop issues on react-select when component is used as a select.
    if (!blurInputOnSelect) {
        // eslint-disable-next-line no-param-reassign
        blurInputOnSelect = isMulti ? false : true;
    }
    const hideSelectedOptions = collapsedSelectedValues === true ? false : true;
    const constructContainerClassNames = () => {
        let borderClasses = "";
        if (invalid === true) {
            borderClasses =
                "border-error dark:border-dark-error focus-within:border-error dark:focus-within:border-dark-error focus-within:ring-red-500";
        }
        else if (isDisabled === true) {
            borderClasses =
                "border-dark dark:border-dark-dark opacity-50 cursor-not-allowed";
        }
        else {
            borderClasses =
                "border-interactive dark:border-dark-interactive focus-within:border-blue-500 focus-within:ring-blue-500";
        }
        let backgroundColor;
        if (invalid === true) {
            backgroundColor =
                "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
        }
        else if (bgColor === "grey" || bgColor === "gray") {
            backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
        }
        else {
            backgroundColor = `bg-container-light dark:bg-dark-container-light`;
        }
        const labelClasses = hideLabel || labelFor === undefined ? "" : "mt-0.5";
        return (props) => `block w-full rounded-md border focus-within:ring-1 text-base ${borderClasses} ${backgroundColor} ${labelClasses}`;
    };
    const handleOutsideClick = (event) => {
        if (containerRef.current &&
            !containerRef.current.contains(event.target)) {
            setHasFocused(false);
            setInputValue("");
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [containerRef]);
    return (_jsxs("div", { id: `autocomplete-${id}`, "data-test-id": testId, ref: containerRef, children: [_jsx(Label, { name: (_b = props.name) !== null && _b !== void 0 ? _b : "autocomplete-label", label: labelFor, disabled: isDisabled, required: required, optional: optional, hideLabel: hideLabel || labelFor === undefined, labelPosition: labelPosition, children: _jsx(Select, { id: id !== undefined ? `${id}-select-container` : "", inputId: id, className: "font-sans", options: isMulti === true ? getOptions() : opts, ref: selectRef, unstyled: true, blurInputOnSelect: blurInputOnSelect, closeMenuOnSelect: closeMenuOnSelect, isClearable: isClearable, isDisabled: isDisabled, isLoading: isLoading, isSearchable: false, menuPortalTarget: menuPortalTarget, placeholder: placeholderText, hideSelectedOptions: false, 
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value: optionValue, components: {
                        Group,
                        Option,
                        GroupHeading,
                        MultiValueContainer,
                        MultiValue,
                        MultiValueRemove,
                        Placeholder,
                        MenuList,
                        DropdownIndicator,
                        ClearIndicator,
                        Menu,
                        IndicatorSeparator: () => null,
                    }, classNames: {
                        container: constructContainerClassNames(),
                        indicatorsContainer: () => "dark:rounded-r-md",
                        menu: () => "bg-container-light rounded-md mt-2 py-1 dark:bg-dark-container-light",
                        multiValue: () => "pl-1.5",
                        multiValueLabel: () => "text-primaryText dark:text-dark-primaryText",
                        option: () => "text-primaryText dark:text-dark-primaryText hover:bg-buttons-tertiary-bg-active hover:dark:bg-dark-buttons-tertiary-bg-active",
                        placeholder: () => "text-placeholder dark:text-dark-placeholder",
                        singleValue: () => "text-primaryText dark:text-dark-primaryText",
                        valueContainer: () => "dark:rounded-l-md",
                    }, styles: {
                        menuPortal: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { zIndex: 60, backgroundColor: "gray" })),
                        menu: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { boxShadow: "none" })),
                        multiValue: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { border: "none", background: "transparent" })),
                        multiValueLabel: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { padding: "0" })),
                        control: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { border: "none", boxShadow: "none", background: "transparent", borderRadius: "6px" })),
                        input: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { "input:focus": {
                                boxShadow: "none",
                            }, padding: 0, margin: 0, border: "solid 1px pink" })),
                        container: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { minHeight: "44px", background: backgroundColor })),
                        valueContainer: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { minHeight: "42px", padding: "4px 0", margin: "0 8px", rowGap: "0.25rem", columnGap: "0.25rem", flexWrap: singleLineCollapse ? "nowrap" : "wrap" })),
                        indicatorsContainer: (base) => 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        (Object.assign(Object.assign({}, base), { alignItems: "start" })),
                    }, theme: (theme) => (Object.assign(Object.assign({}, theme), { borderRadius: 8 })), isMulti: isMulti, onFocus: (e) => handleOnFocus(e), onChange: (newValue, e) => {
                        if (isMulti === true) {
                            return handleMultiOnChange(newValue, e);
                        }
                        return handleSingleOnChange(newValue);
                    }, onBlur: onBlur, onKeyDown: onKeyDown, onInputChange: handleOnInputChange, menuIsOpen: hasFocused || undefined,
                    isFocused: hasFocused || undefined, inputValue: inputValue, showSearch: isSearchable, singleLineCollapse: singleLineCollapse, collapsedSelectedValues: collapsedSelectedValues, selectedIndidacatorInMenu: selectedIndidacatorInMenu, optionSelected: optionSelected, collapseGroups: collapseGroups, collapsedGroupState: collapsedGroupState, collapseGroup: collapseGroup, expandGroup: expandGroup }) }), helperText !== undefined && invalid !== true && (_jsx("p", { className: "mt-1 text-xs text-gray-700", children: helperText }))] }));
};
export default Autocomplete;
