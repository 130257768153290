import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import { ButtonTabs } from "./ButtonTabs";
import { SelectTabs } from "./SelectTabs";
import TabPanels from "./TabsPanels";
export const Tabs = ({ tabs, id, testId, selectedId, onChange, }) => {
    /**
     *
     * Set Tabs Indexes based on `selectedId`.
     *
     */
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        if (selectedId !== undefined) {
            setSelectedIndex(tabs.findIndex((tab) => tab.id === selectedId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);
    /**
     *
     * Guesstimate Tab Length for Computing If Tabs Fit on the Screen.
     *
     */
    const tabCount = tabs
        .map((tab) => {
        let countVal = 2;
        if (tab.hideLabel === true) {
            countVal += 4;
        }
        else if (tab.iconName !== undefined) {
            countVal = 3 + tab.label.length;
        }
        else {
            countVal = tab.label.length;
        }
        if (tab.status !== undefined) {
            countVal += 2;
            if (tab.status.label !== undefined) {
                countVal += tab.status.label.length;
            }
        }
        return countVal;
    })
        .reduce((prev, curr) => prev + curr, 0);
    const tabsRef = useRef(null);
    const { width } = useResizeObserver({ ref: tabsRef, box: "border-box" });
    let tabBreakpoint = width !== undefined ? tabCount / width <= 0.1 : false;
    /**
     *
     * Handle Tabs Callback, Set Tab Index and Call `onChange` if provided..
     *
     * @param index
     */
    const onChangeHandler = (index) => {
        setSelectedIndex(index);
        onChange === null || onChange === void 0 ? void 0 : onChange(index);
    };
    return (_jsxs("div", { ref: tabsRef, children: [tabBreakpoint ? (_jsx("div", { className: "button-tabs", children: _jsx(ButtonTabs, { id: id, testId: testId, tabs: tabs, selectedId: selectedId, selectedIndex: selectedIndex, onChange: (i) => {
                        onChangeHandler(i);
                    }, "data-eco-component": "tabs", "data-test-id": testId }) })) : (_jsx("div", { className: "select-tabs", children: _jsx(SelectTabs, { tabs: tabs, selectedId: selectedId, onChange: (i) => onChangeHandler(i), selectedIndex: selectedIndex, id: id, "data-eco-component": "tabs", "data-test-id": testId }) })), _jsx(TabPanels, { tabs: tabs, selectedIndex: selectedIndex })] }));
};
export default Tabs;
