import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer, useCallback, useContext, } from "react";
const initialState = {
    value: null,
    isDropdownOpened: false,
    selectedSuggestionRange: { amount: null, unit: null },
    selectedTabIndex: 0,
};
export var AdvancedDatePickerActions;
(function (AdvancedDatePickerActions) {
    AdvancedDatePickerActions["SET_NEW_VALUE"] = "SET_NEW_VALUE";
    AdvancedDatePickerActions["SET_DROPDOWN_STATE"] = "SET_DROPDOWN_STATE";
    AdvancedDatePickerActions["SET_SELECTED_SUGGESTION"] = "SET_SELECTED_SUGGESTION";
    AdvancedDatePickerActions["SET_SELECTED_TAB_INDEX"] = "SET_SELECTED_TAB_INDEX";
})(AdvancedDatePickerActions || (AdvancedDatePickerActions = {}));
const reducer = (state, action) => {
    switch (action.type) {
        case AdvancedDatePickerActions.SET_NEW_VALUE:
            return Object.assign(Object.assign({}, state), { value: action.payload });
        case AdvancedDatePickerActions.SET_DROPDOWN_STATE:
            return Object.assign(Object.assign({}, state), { isDropdownOpened: action.payload });
        case AdvancedDatePickerActions.SET_SELECTED_SUGGESTION:
            return Object.assign(Object.assign({}, state), { selectedSuggestionRange: action.payload });
        case AdvancedDatePickerActions.SET_SELECTED_TAB_INDEX:
            return Object.assign(Object.assign({}, state), { selectedTabIndex: action.payload });
        default:
            throw new Error();
    }
};
//eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAdvancedDatePickerContext = (initState) => {
    const [state, dispatch] = useReducer(reducer, initState);
    const handleDateChangedValue = useCallback((val) => {
        dispatch({
            type: AdvancedDatePickerActions.SET_NEW_VALUE,
            payload: val,
        });
    }, []);
    const handleDropdownState = useCallback((e) => {
        dispatch({
            type: AdvancedDatePickerActions.SET_DROPDOWN_STATE,
            payload: e,
        });
    }, []);
    const handleSelectedSuggestion = useCallback((e) => {
        dispatch({
            type: AdvancedDatePickerActions.SET_SELECTED_SUGGESTION,
            payload: e,
        });
    }, []);
    const handleSelectedTabIndex = useCallback((e) => {
        dispatch({
            type: AdvancedDatePickerActions.SET_SELECTED_TAB_INDEX,
            payload: e,
        });
    }, []);
    return {
        state,
        handleDateChangedValue,
        handleDropdownState,
        handleSelectedSuggestion,
        handleSelectedTabIndex,
    };
};
const initContextState = {
    state: initialState,
    handleDateChangedValue: (e) => { },
    handleDropdownState: (e) => { },
    handleSelectedSuggestion: (e) => { },
    handleSelectedTabIndex: (e) => { },
};
export const AdvancedDatePickerContext = createContext(initContextState);
export const AdvancedDatePickerProvider = ({ children, }) => {
    return (_jsx(AdvancedDatePickerContext.Provider, { value: useAdvancedDatePickerContext(initialState), children: children }));
};
export const useAdvancedDatePicker = () => {
    const { state: { value, isDropdownOpened, selectedSuggestionRange, selectedTabIndex, }, handleDateChangedValue, handleDropdownState, handleSelectedSuggestion, handleSelectedTabIndex, } = useContext(AdvancedDatePickerContext);
    return {
        value,
        isDropdownOpened,
        selectedSuggestionRange,
        selectedTabIndex,
        handleDateChangedValue,
        handleDropdownState,
        handleSelectedSuggestion,
        handleSelectedTabIndex,
    };
};
