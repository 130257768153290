var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import Label from "../label/Label";
import Timepicker from "react-time-picker";
import "react-clock/dist/Clock.css";
import "react-time-picker/dist/TimePicker.css";
import "./time.css";
import { IconV2 } from "../../icon_v2";
export const Time = (_a) => {
    var _b;
    var { labelPosition = "top" } = _a, props = __rest(_a, ["labelPosition"]);
    const initialTime = (_b = props.time) !== null && _b !== void 0 ? _b : undefined;
    const [value, setValue] = useState(initialTime);
    const handleChange = (val) => {
        props.onChange(val);
        setValue(val);
    };
    const backgroundColor = props.bgColor === "grey" || props.bgColor === "gray" ? "gray" : "";
    const invalidClass = props.invalid === true ? "invalid" : "";
    return (_jsx(Label, { label: props.label, name: props.name, required: props.required, optional: props.optional, hideLabel: props.hideLabel, id: props.id !== undefined ? `${props.id}-label` : undefined, labelPosition: labelPosition, disabled: props.disabled, children: _jsx("div", { className: `stratos-timepicker ${invalidClass} ${backgroundColor}`, id: props.id, "data-test-id": props.testId, children: _jsx(Timepicker, { value: value, disabled: props.disabled, onChange: handleChange, onBlur: props.onBlur, onFocus: props.onFocus, format: props.format, locale: props.locale, maxDetail: props.maxDetail, hourPlaceholder: props.hourPlaceholder, minutePlaceholder: props.minutePlaceholder, secondPlaceholder: props.secondPlaceholder, disableClock: true, nativeInputAriaLabel: props.name, clearIcon: _jsx("span", { className: "text-primaryText dark:text-dark-primaryText", children: _jsx(IconV2, { type: "fa", icon: {
                            icon: ["far", "xmark"],
                            fixedWidth: true,
                            size: "lg",
                        } }) }), amPmAriaLabel: "Select AM/PM", hourAriaLabel: "Hour", minuteAriaLabel: "Minute", clearAriaLabel: "Clear Value", clockAriaLabel: "Toggle Clock" }) }) }));
};
export default Time;
