import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useMemo } from "react";
import { offset, flip, shift, autoUpdate, useFloating, useInteractions, useRole, useDismiss, useId, useClick, FloatingFocusManager, size, } from "@floating-ui/react-dom-interactions";
import mergeRefs from "react-merge-refs";
import { useAdvancedDatePicker } from "./AdvancedDatePickerProvider";
const Dropdown = ({ children, render, placement, ariaLabelledby, ariaDescribedby, }) => {
    const { isDropdownOpened, handleDropdownState } = useAdvancedDatePicker();
    const { x, y, reference, floating, strategy, context } = useFloating({
        open: isDropdownOpened,
        onOpenChange: handleDropdownState,
        middleware: [
            offset(5),
            flip(),
            shift({ padding: 5 }),
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width < 320 ? 320 : rects.reference.width}px`,
                    });
                },
            }),
        ],
        placement,
        whileElementsMounted: autoUpdate,
    });
    const id = useId();
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context),
    ]);
    // Preserve the consumer's ref
    const ref = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    () => mergeRefs([reference, children.ref]), [reference, children]);
    return (_jsxs(_Fragment, { children: [cloneElement(children, getReferenceProps(Object.assign({ ref }, children.props))), isDropdownOpened && (_jsx(FloatingFocusManager, { context: context, modal: false, order: ["reference", "content"], returnFocus: false, children: _jsx("div", Object.assign({ id: id, ref: floating, className: "border-interactive dark:border-dark-interactive text-primaryText dark:text-dark-primaryText z-30 whitespace-normal rounded-md border bg-white sm:text-sm dark:bg-transparent", style: {
                        position: strategy,
                        top: y !== null && y !== void 0 ? y : 0,
                        left: x !== null && x !== void 0 ? x : 0,
                    }, "aria-labelledby": ariaLabelledby, "aria-describedby": ariaDescribedby }, getFloatingProps(), { children: render({
                        close: () => {
                            handleDropdownState(false);
                        },
                    }) })) }))] }));
};
export default Dropdown;
