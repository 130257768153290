import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import "./radiogroup.css";
export const RadiogroupRadio = (props) => {
    let { value, label, group, selected, disabled, groupDisable } = props;
    let disableRadio = (disabled !== null && disabled !== void 0 ? disabled : false) || (groupDisable !== null && groupDisable !== void 0 ? groupDisable : false);
    let ref = useRef(null);
    useEffect(() => {
        let radio = ref.current;
        if (disableRadio) {
            radio.setAttribute("disabled", "");
        }
        else {
            radio.removeAttribute("disabled");
        }
        if (selected === value) {
            radio.setAttribute("checked", "");
        }
        else {
            radio.removeAttribute("checked");
        }
    }, [disableRadio, selected, value]);
    const labelClasses = `${disableRadio ? "opacity-50" : "cursor-pointer"}`;
    let backgroundColor;
    if (props.invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (props.bgColor === "grey" || props.bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    let borderColor = props.invalid === true
        ? "border-error dark:border-dark-error"
        : "border-gray-300 dark:border-slate-400";
    return (_jsx("div", { className: "mb-1 flex items-center", "data-eco-component": "radiogroup-radio", id: props.id, "data-test-id": props.testId, children: _jsxs("label", { className: `text-secondary dark:text-dark-secondary inline-block text-sm leading-6 ${labelClasses}`, children: [_jsx("input", { className: `${props.invalid === true ? "invalid" : ""} float-left mr-2 mt-0.5 h-6 w-6 cursor-pointer appearance-none rounded-full border ${borderColor} focus:text-blue-600 focus:ring-blue-600/80 ${backgroundColor} checked:bg-brand-accent dark:checked:bg-dark-brand-accent bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none focus:ring-offset-1 disabled:opacity-50 dark:text-gray-300 dark:focus:ring-blue-400 dark:focus:ring-offset-0`, ref: ref, type: "radio", value: value, id: `${value}`, name: group, tabIndex: 0, checked: selected === value ? true : false, readOnly: true }), label] }) }));
};
