var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, Children } from "react";
import { ButtonGroupButton } from "./ButtonGroupButton";
export const ButtonGroup = (_a) => {
    var _b, _c, _d;
    var { id, testId, children } = _a, props = __rest(_a, ["id", "testId", "children"]);
    let as = (_b = props.as) !== null && _b !== void 0 ? _b : "toolbar";
    let size = (_c = props.size) !== null && _c !== void 0 ? _c : "md";
    let ref = useRef(null);
    const [buttonList, setButtonList] = useState([]);
    const selectButton = (selected) => {
        if (as === "radio") {
            buttonList.forEach((button) => {
                if (button === selected) {
                    selected.setAttribute("aria-checked", "true");
                }
                else {
                    button.setAttribute("aria-checked", "false");
                }
            });
        }
    };
    useEffect(() => {
        const handleClick = (e) => {
            if (as === "radio") {
                selectButton(e.target);
            }
        };
        let buttongroup = ref.current;
        const buttons = buttongroup.querySelectorAll("button");
        setButtonList(Array.from(buttons));
        buttongroup.addEventListener("click", handleClick);
        return () => {
            buttongroup.removeEventListener("click", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [as, ref.current]);
    const roles = {
        toolbar: "group",
        radio: "radiogroup",
    };
    const layout = (_d = props.layout) !== null && _d !== void 0 ? _d : "horizontal";
    const layoutStyle = layout === "horizontal"
        ? "inline-flex rounded-md"
        : "flex flex-col justify-stretch w-fit";
    return (_jsx("div", { id: id, ref: ref, "data-test-id": testId, "data-eco-component": "buttongroup", className: layoutStyle, role: roles[as], children: React.Children.map(children, (child, index) => {
            const length = Children.count(children);
            if (React.isValidElement(child)) {
                return React.cloneElement(child, Object.assign({ index,
                    length,
                    size }, props));
            }
            return child;
        }) }));
};
ButtonGroup.Button = ButtonGroupButton;
ButtonGroupButton.displayName = "ButtonGroup.Button";
