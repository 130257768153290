import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { components } from "react-select";
import { SearchBar } from "../../search-bar";
// reference for getting search to work https://codesandbox.io/s/react-select-input-in-menulist-forked-tqhxgt?file=/example.js:3882-3988
const MenuList = (props) => {
    const { selectProps } = props;
    const { id, showSearch, onInputChange, inputValue } = selectProps;
    return (_jsxs("div", { id: id !== undefined ? `${id}-listbox` : undefined, children: [showSearch === true && (_jsx("div", { className: "border-dark dark:border-dark-dark my-2 block border-b px-2 pb-3", children: _jsx(SearchBar, { label: "Search", name: "autocompleteSearch", hideLabel: true, value: inputValue, onChange: (e) => onInputChange(e.currentTarget.value, {
                        action: "input-change",
                        prevInputValue: selectProps.inputValue,
                    }), onMouseDown: (e) => {
                        e.stopPropagation();
                    }, onTouchEnd: (e) => {
                        e.stopPropagation();
                    } }) })), _jsx(components.MenuList, Object.assign({ className: "text-md font-normal" }, props))] }));
};
export default MenuList;
