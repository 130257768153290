var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip } from "../tooltip";
import { IconV2, convertIcon, needsConversion } from "../icon_v2";
import { Spinner } from "../spinner";
const ButtonCore = (_a) => {
    var _b, _c, _d, _e;
    var { id, testId, form, onClick, onBlur, onFocus, iconName, loading, size = "md", children } = _a, props = __rest(_a, ["id", "testId", "form", "onClick", "onBlur", "onFocus", "iconName", "loading", "size", "children"]);
    // Since the new IconV2 uses prefix and icon names without the `fa-` prefix like old Icon does, we need to convert those strings to a format that is usable by the new IconV2.
    // This will allow us to start the deprecation process or warning developers they need to start converting their Button components that use icons over to defining icon names that use the IconV2 format
    // But we must test for `iconname` being an Array because to prepare for the move to IconV2, developers might want to use the icon name format of `[<prefix>,<iconName>]`
    const convertedIcon = iconName !== undefined && !Array.isArray(iconName)
        ? needsConversion(iconName)
            ? convertIcon(iconName)
            : iconName
        : iconName !== undefined && Array.isArray(iconName)
            ? iconName
            : undefined;
    let variant = (_b = props.variant) !== null && _b !== void 0 ? _b : "secondary";
    let type = (_c = props.type) !== null && _c !== void 0 ? _c : "button";
    let disabled = (_d = props.disabled) !== null && _d !== void 0 ? _d : false;
    let hideLabel = (_e = props.hideLabel) !== null && _e !== void 0 ? _e : false;
    // let stretch = props.stretch ?? false;
    // console.log("BUTTON CHILDREN:", children);
    let variantColorStyle;
    let borderStyle = "border border-transparent";
    switch (variant) {
        case "primary":
            variantColorStyle =
                "bg-buttons-primary-bg-normal hover:bg-buttons-primary-bg-hover focus:outline-none focus:ring-2 focus:ring-focus text-buttons-primary-text active:bg-buttons-primary-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-primary-bg-normal dark:hover:bg-dark-buttons-primary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-primary-text dark:active:bg-dark-buttons-primary-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            break;
        case "tertiary":
            variantColorStyle =
                "[&:not(:disabled)]:hover:bg-buttons-tertiary-bg-hover focus:outline-none focus:ring-2 focus:ring-focus text-buttons-tertiary-text active:bg-buttons-tertiary-bg-active disabled:text-buttons-disabled-text dark:[&:not(:disabled)]:hover:bg-dark-buttons-tertiary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-tertiary-text dark:active:bg-dark-buttons-tertiary-bg-active dark:disabled:bg-transparent dark:disabled:text-dark-buttons-disabled-text";
            break;
        case "destructive":
            variantColorStyle =
                "bg-buttons-destructive-bg-normal hover:bg-buttons-destructive-bg-hover focus:outline-none focus:ring-2 focus:ring-focus text-buttons-destructive-text active:bg-buttons-destructive-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-destructive-bg-normal dark:hover:bg-dark-buttons-destructive-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-destructive-text dark:active:bg-dark-buttons-destructive-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            break;
        case "secondary-destructive":
            variantColorStyle =
                "bg-buttons-desctructive-outline-bg-normal border border-1 hover:bg-buttons-desctructive-outline-bg-hover focus:outline-none focus:ring-2 focus:ring-focus focus:[&:not(:disabled)]:border-red-600/0 text-buttons-desctructive-outline-text active:bg-buttons-desctructive-outline-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-desctructive-outline-bg-normal dark:hover:bg-dark-buttons-desctructive-outline-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-desctructive-outline-text dark:active:bg-dark-buttons-desctructive-outline-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text [&:not(:disabled)]:border-red-600 dark:[&:not(:disabled)]:border-red-400 focus:dark:[&:not(:disabled)]:border-red-400/0";
            break;
        case "transparent":
            variantColorStyle =
                "bg-transparent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-focus focus:ring-offset-1 text-gray-700 dark:bg-transparent";
            break;
        case "secondary":
        default:
            variantColorStyle =
                "bg-buttons-secondary-bg-normal [&:not(:disabled)]:border-buttons-secondary-forecolor dark:[&:not(:disabled)]:border-dark-buttons-secondary-forecolor border hover:bg-buttons-secondary-bg-hover focus:outline-none focus:ring-2 focus:ring-focus focus:[&:not(:disabled)]:border-buttons-secondary-forecolor/0 focus:dark:[&:not(:disabled)]:border-dark-buttons-secondary-forecolor/0 text-buttons-secondary-forecolor dark:text-dark-buttons-secondary-forecolor active:bg-buttons-secondary-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-secondary-bg-normal dark:hover:bg-dark-buttons-secondary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-secondary-text dark:active:bg-dark-buttons-secondary-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            break;
    }
    let buttonSize;
    let textSize;
    switch (size) {
        case "lg":
            buttonSize = "h-14 px-6";
            textSize = "text-lg";
            break;
        case "sm":
            buttonSize = "h-8 px-3";
            textSize = "text-sm";
            break;
        default:
            buttonSize = "h-11 px-4";
            textSize = "text-base";
            break;
    }
    const buttonStyle = `${borderStyle} leading-4
  font-medium rounded-md ${variantColorStyle} pointer-events-auto ${buttonSize} w-full justify-center sm:w-auto`;
    return (_jsx("button", { id: id, "data-test-id": testId, "data-eco-component": "button", type: type, disabled: disabled, form: form, onClick: onClick, onBlur: onBlur, onFocus: onFocus, className: buttonStyle, children: _jsxs("span", { className: `pointer-events-auto flex items-center justify-center ${textSize}`, children: [loading !== undefined ? _jsx(Spinner, { size: "sm", mode: "dark" }) : null, convertedIcon !== undefined ? (_jsx(IconV2, { type: "fa", icon: { icon: convertedIcon } })) : null, hideLabel ? null : children] }) }));
};
export const Button = (_a) => {
    var { id, testId, form, onClick, onBlur, onFocus, iconName, loading, tooltip = false, tooltipLabel, tooltipPlacement = "top", children } = _a, props = __rest(_a, ["id", "testId", "form", "onClick", "onBlur", "onFocus", "iconName", "loading", "tooltip", "tooltipLabel", "tooltipPlacement", "children"]);
    return (_jsx(_Fragment, { children: tooltip ? (_jsx(Tooltip, { id: `buttonTooltip-${id}`, placement: tooltipPlacement, label: `${tooltipLabel !== null && tooltipLabel !== void 0 ? tooltipLabel : children}`, testId: `buttonTooltip-${testId}`, children: _jsx(ButtonCore, Object.assign({ id: id, testId: testId, form: form, onClick: onClick, onBlur: onBlur, onFocus: onFocus, iconName: iconName, loading: loading }, props, { children: children && _jsx("span", { className: "px-2 leading-none", children: children }) })) })) : (_jsx(ButtonCore, Object.assign({ id: id, testId: testId, form: form, onClick: onClick, onBlur: onBlur, onFocus: onFocus, iconName: iconName, loading: loading }, props, { children: children && _jsx("span", { className: "px-2 leading-none", children: children }) }))) }));
};
