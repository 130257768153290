import { jsx as _jsx } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
const CollapseGroupButton = ({ collapsed, collapseGroup, expandGroup, label, }) => {
    const iconProp = collapsed ? "plus" : "minus";
    const handleOnClick = () => {
        if (collapsed) {
            expandGroup(label);
        }
        else {
            collapseGroup(label);
        }
    };
    const ariaLabel = `${collapsed ? "expand" : "collapse"}-${label.toLowerCase()}`;
    return (_jsx("div", { role: "button", className: "bg-brand-accent dark:bg-dark-brand-accent text-dark-pill-text-default dark:text-pill-text-default mx-1 block flex size-4 min-w-[16px] items-center justify-center rounded-full", onClick: () => handleOnClick(), tabIndex: 0, "aria-label": ariaLabel, children: _jsx(IconV2, { type: "fa", icon: {
                icon: ["far", iconProp],
                fixedWidth: true,
                size: "xs",
            } }) }));
};
export default CollapseGroupButton;
