import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Button } from "../button";
import { useWizard } from "./useWizard";
export const WizardNextSteps = ({ nextSteps, }) => {
    const [{ currentStep }, { incrementCurrentStep, markCompleted }] = useWizard();
    const nextStep = nextSteps[currentStep];
    if (nextStep === undefined) {
        // eslint-disable-next-line no-console
        console.error("Wizard: nextSteps does not align with Wizard steps");
        return _jsx(_Fragment, {});
    }
    const disableNext = nextStep.enableNext;
    const setSkipNextActive = () => {
        // Unclear about UI, should it be marked as completed or leaved greyed out since it was skipped.
        // markCompleted(currentStep);
        incrementCurrentStep();
        if (nextStep.onClick !== undefined) {
            nextStep.onClick();
        }
    };
    if (nextStep.skip !== undefined && nextStep.skip) {
        return (_jsx(Button, { variant: "primary", disabled: !disableNext, onClick: setSkipNextActive, children: currentStep === nextSteps.length - 1 ? "Finish" : "Skip Step" }));
    }
    const setNextActive = () => {
        markCompleted(currentStep);
        incrementCurrentStep();
        if (nextStep.onClick !== undefined) {
            nextStep.onClick();
        }
    };
    return (_jsx(Button, { variant: "primary", disabled: !disableNext, onClick: setNextActive, children: currentStep === nextSteps.length - 1 ? "Finish" : "Next" }));
};
export default WizardNextSteps;
