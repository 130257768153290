import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth } from "./auth";
import { useRouter } from "next/router";
export const SidebarProfile = () => {
    const { user } = useAuth();
    const router = useRouter();
    const loggedOutNav = {
        label: "Log In",
        href: `${router.basePath}/api/auth/login`,
    };
    const loggedInNav = {
        label: "Log Out",
        href: `${router.basePath}/api/auth/logout`,
    };
    const email = user === null || user === void 0 ? void 0 : user.email;
    const navigation = email !== undefined ? loggedInNav : loggedOutNav;
    return (_jsx("div", { className: "border-base-200 flex flex-shrink-0 border-t px-4 pt-4", children: _jsx("div", { className: "group block w-full flex-shrink-0", children: _jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "block h-6 w-6", children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512", children: _jsx("path", { d: "M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z" }) }) }), _jsxs("div", { className: "ml-3", children: [email !== undefined && (_jsx("p", { className: "text-base-700 group-hover:text-base-900 truncate text-sm font-semibold", children: email })), _jsx("a", { href: navigation.href, className: "stratos-focus text-base-500 group-hover:text-base-700 text-xs font-medium", children: navigation.label })] })] }) }) }));
};
