var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
export const MenuAction = (_a) => {
    var { href, icon, label } = _a, props = __rest(_a, ["href", "icon", "label"]);
    return (_jsx(Menu.Item, Object.assign({}, props, { "data-eco-component": "menu-action", children: ({ active }) => (_jsxs(_Fragment, { children: [_jsx("hr", { className: "text-border-light dark:text-dark-border-light -mx-[0.25rem] my-[4px]" }), _jsxs("a", { href: href, className: `${active
                        ? "bg-buttons-tertiary-bg-hover dark:bg-dark-buttons-tertiary-bg-active"
                        : ""} text-link dark:text-dark-link flex justify-between whitespace-nowrap rounded-md p-2`, children: [_jsx("span", { children: label }), _jsx("div", { className: "flex items-center", children: _jsx(IconV2, { type: "fa", icon: { icon: icon, size: "xs" } }) })] })] })) })));
};
