import { jsx as _jsx } from "react/jsx-runtime";
export const TabPanels = ({ tabs, selectedIndex = 0 }) => {
    return (_jsx("div", { className: "tab-panels border-dark bg-container-light dark:bg-dark-container-baseContainer dark:border-dark-dark border border-t-0 p-4", children: tabs.map((tab, i) => {
            if (i === selectedIndex) {
                return (_jsx("div", { id: `panel-${tab.id}-${i}`, children: tab.panel }, `panel-${tab.id}-${i}`));
            }
            return null;
        }) }));
};
export default TabPanels;
