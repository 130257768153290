import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { ButtonTab } from "./ButtonTab";
export const ButtonTabs = ({ id, testId, tabs, selectedIndex, onChange, }) => {
    return (_jsx(Tab.Group, { defaultIndex: selectedIndex, selectedIndex: selectedIndex, manual: true, onChange: onChange, children: _jsx("div", { id: id, "data-test-id": testId, className: "border-dark dark:border-dark-dark border-b", children: _jsx(Tab.List, { as: Fragment, "aria-labelledby": `${id}-tab`, children: _jsx("nav", { className: "-mb-px flex flex-wrap", children: tabs.map((tab, i) => {
                        const selectedCSS = selectedIndex === i ? "" : "hover:border-r-blue-600";
                        return (_jsx("div", { id: `tab-${tab.id}-${i}`, className: `${selectedCSS} mx-0.5 -ml-px mt-px border-transparent first:ml-0 last:mr-0 last:border-r-0 hover:relative hover:z-10`, children: _jsx(ButtonTab, { tab: tab, index: i === 0
                                    ? "first"
                                    : i === tabs.length - 1
                                        ? "last"
                                        : i.toString() }) }, `tab-${tab.id}-${i}`));
                    }) }) }) }) }));
};
export default ButtonTabs;
