export const getAppNameInfo = (user) => {
    let apps = [];
    const { availableApps, appInfo } = user;
    if (appInfo === undefined) {
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        console.warn("Calling getAppNameInfo, appInfo from user object is undefined.");
        return apps;
    }
    if (appInfo !== undefined && availableApps.length > 0) {
        availableApps.forEach((app) => {
            if (Object.prototype.hasOwnProperty.call(appInfo, app)) {
                let currentApp = appInfo[app];
                // What happens if we have multiple customers in the future?
                let customers = currentApp.customers;
                if (customers.length) {
                    let customer = customers[0];
                    apps.push({
                        appName: app,
                        customerId: customer.customerId,
                        customerName: customer.customerName,
                        roles: customer.roles,
                        data: customer.data,
                    });
                }
            }
        });
    }
    return apps;
};
