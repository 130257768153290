var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from "react";
import io from "socket.io-client";
import { useRouter } from "next/router";
let socket;
const AuthSegmentContext = createContext(null);
export default function SocketProvider({ children, }) {
    const [socketio, setSocketio] = useState(null);
    const [connected, setConnected] = useState(false);
    const router = useRouter();
    useEffect(() => {
        let ignore = false;
        const initSocket = () => __awaiter(this, void 0, void 0, function* () {
            if (!connected) {
                yield fetch(`${router.basePath}/api/auth/segment`);
                socket = io({ transports: ["websocket"], upgrade: false });
                socket.on("connect", () => {
                    setConnected(true);
                    if (!ignore) {
                        setSocketio(socket);
                    }
                });
            }
        });
        void initSocket();
        return () => {
            ignore = true;
        };
    }, []);
    return (_jsx(AuthSegmentContext.Provider, { value: socketio, children: children }));
}
export function useSocket() {
    const socketio = useContext(AuthSegmentContext);
    return socketio;
}
