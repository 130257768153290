import { jsx as _jsx } from "react/jsx-runtime";
import { DisclosureItem } from "./DisclosureItem";
export const Disclosure = ({ id, testId, list, mode = "full", }) => {
    if (!Array.isArray(list)) {
        return (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "disclosure", children: _jsx(DisclosureItem, { disclosure: list, mode: mode }) }));
    }
    return (_jsx("div", { className: "divide-y divide-gray-200 dark:divide-slate-800", id: id, "data-test-id": testId, "data-eco-component": "disclosure", children: list.map((disc, i) => {
            return (_jsx(DisclosureItem, { disclosure: disc, mode: mode }, `disc-${i}`));
        }) }));
};
export default Disclosure;
