import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import "./flyoutMenuCheckbox.css";
export const FlyoutMenuCheckbox = ({ value, label, checked, onChange, }) => {
    let ref = useRef(null);
    useEffect(() => {
        let checkbox = ref.current;
        if (checked !== undefined) {
            checkbox.checked = checked;
        }
    }, [checked]);
    return (_jsxs("label", { className: "flyoutmenu-checkbox-container my-2 block cursor-pointer", children: [_jsx("span", { className: "text-tertiary mr-3 text-base font-normal", children: label }), _jsx("input", { ref: ref, type: "checkbox", id: value, name: value, checked: checked, onChange: onChange }), _jsx("span", { className: "eco-checkmark-flyout-menu mt-1" })] }));
};
