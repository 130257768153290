import { jsx as _jsx } from "react/jsx-runtime";
export const Avatar = ({ id, testId, name, size = "md", color = "lightGray", }) => {
    const getInitials = (fullName) => {
        let initials;
        initials = fullName
            ? `${fullName
                .split(" ")
                .map((word) => word[0])
                .join("")}`
            : "";
        return initials.slice(-2);
    };
    let initials = name.length <= 2 ? name : getInitials(name);
    const dimensions = {
        xs: "h-6 w-6",
        sm: "h-8 w-8",
        md: "h-10 w-10",
        lg: "h-12 w-12",
        xl: "h-14 w-14",
    };
    const textSize = {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-medium",
        lg: "text-lg",
        xl: "text-xl",
    };
    const avatarColor = {
        gray: "bg-gray-500 text-white",
        red: "bg-red-500 text-white",
        orange: "bg-orange-500 text-white",
        amber: "bg-amber-500 text-white",
        yellow: "bg-amber-500 text-white",
        lime: "bg-lime-500 text-white",
        green: "bg-green-500 text-white",
        emerald: "bg-emerald-500 text-white",
        teal: "bg-teal-500 text-white",
        cyan: "bg-sky-500 text-white",
        sky: "bg-sky-500 text-white",
        blue: "bg-blue-500 text-white",
        indigo: "bg-indigo-500 text-white",
        violet: "bg-indigo-500 text-white",
        purple: "bg-purple-500 text-white",
        fuchsia: "bg-purple-500 text-white",
        pink: "bg-pink-500 text-white",
        rose: "bg-rose-500 text-white",
        slate: "bg-slate-500 text-white",
        primary: "bg-blue-500 text-white",
        positive: "bg-green-500 text-white",
        negative: "bg-red-500 text-white",
        caution: "bg-orange-500 text-white",
        warning: "bg-warning-500 text-white",
        lightGray: "bg-components-light dark:bg-dark-components-light text-primaryText dark:text-dark-primaryText",
        base: "bg-gray-500 text-white",
    };
    return (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "avatar", className: `flex shrink-0 grow-0 content-center items-center justify-center ${dimensions[size]} rounded-full ${avatarColor[color]}`, children: _jsx("span", { className: `${textSize[size]} font-medium leading-none`, children: initials.toUpperCase() }) }));
};
export default Avatar;
