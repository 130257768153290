import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect } from "react";
import { format, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";
import { IconV2 } from "../../icon_v2";
import { useAdvancedDatePicker } from "./AdvancedDatePickerProvider";
const TriggerButton = ({ placeholder, disabled = true, invalid = false, customPickerValueFormat = undefined, selectedValue, customDateFormat, }, ref) => {
    const { value, handleDropdownState, handleDateChangedValue } = useAdvancedDatePicker();
    useEffect(() => {
        if (selectedValue !== undefined) {
            const selectedValueISOString = {
                startDate: new Date(selectedValue.startDate).toISOString(),
                endDate: new Date(selectedValue.endDate).toISOString(),
            };
            handleDateChangedValue(selectedValueISOString);
        }
    }, [selectedValue, handleDateChangedValue]);
    const invalidClasses = invalid
        ? ` form-field-invalid pr-16`
        : ` form-field-focus `;
    const attributes = {
        type: "button",
        disabled,
    };
    if (invalid) {
        attributes["aria-invalid"] = invalid;
    }
    const formatDate = (isoString, formatString, locale = enUS) => {
        const date = parseISO(isoString);
        return format(date, formatString, { locale });
    };
    let formatted = value
        ? customDateFormat !== undefined
            ? formatDate(value.startDate, customDateFormat.format) +
                customDateFormat.toOperator +
                formatDate(value.endDate, customDateFormat.format)
            : `${value.startDate} - ${value.endDate}`
        : "";
    return (_jsxs("div", { className: "relative flex h-11 items-center", children: [_jsx("button", Object.assign({ ref: ref }, attributes, { className: `text-secondary dark:text-dark-secondary bg-container-light dark:bg-dark-container-light stratos-focus-border relative mt-0.5 flex h-full w-full min-w-[320px] rounded-md border pr-8 font-normal focus-within:ring-1 ${invalidClasses} ${disabled
                    ? "border-dark dark:border-dark-dark opacity-50"
                    : invalid
                        ? `${invalidClasses}`
                        : "stratos-default-border"}`, disabled: disabled, onClick: () => {
                    if (!disabled) {
                        handleDropdownState(false);
                    }
                }, children: value !== null ? (_jsx("span", { "aria-disabled": true, className: "text-primarayText dark:text-dark-primaryText my-auto ml-3 truncate", children: formatted })) : (_jsx("span", { "aria-disabled": true, className: `${disabled
                        ? "text-secondary dark:text-dark-text-secondary"
                        : "text-placeholder dark:text-dark-text-placeholder"} my-auto ml-3 truncate`, children: placeholder })) })), invalid && (_jsx("span", { className: "text-alerts-text-error dark:text-dark-alerts-text-error absolute right-8 mt-0.5", children: _jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "circle-exclamation"],
                        fixedWidth: true,
                        size: "lg",
                    } }) }))] }));
};
export default forwardRef(TriggerButton);
