import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const FlyoutDisclosure = ({ icon, label, panel, defaultOpen, onToggle, }) => {
    return (_jsx(Disclosure, { defaultOpen: defaultOpen, "data-eco-component": "flyout-disclosure", as: Fragment, children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs(Disclosure.Button, { className: classNames(open ? "text-link" : "text-textPrimary", "grid h-[50px] grid-cols-[auto_0px] text-nowrap p-3 duration-150 hover:text-blue-700 group-hover:grid-cols-[auto_330px] [&>*:nth-child(2)]:overflow-hidden"), onClick: onToggle, "data-eco-component": "flyout-menu-disclosure-button", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: ["far", icon],
                                fixedWidth: true,
                                size: "lg",
                            }, color: open ? "primary" : "base" }), _jsxs("div", { className: "ml-3 flex flex-row justify-between text-left", children: [label, _jsx("div", { className: classNames(open ? "rotate-180" : "rotate-0", " flex h-6 w-6 shrink-0 grow-0 items-center justify-center rounded-full transition-transform"), children: _jsx(IconV2, { id: "chevronDown", type: "fa", icon: {
                                            icon: ["far", "chevron-down"],
                                            fixedWidth: true,
                                            size: "sm",
                                        }, color: "base" }) })] })] }), _jsx(Disclosure.Panel, { "data-eco-component": "flyout-menu-disclosure-panel", className: "hidden w-full group-hover:block", unmount: false, children: panel })] })) }));
};
