import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This component copies the passed in string to the system clipboard.

 */
import { useEffect, useState } from "react";
import { IconV2 } from "../../icon_v2";
export const CodeCopyButton = ({ copiedContent }) => {
    const [copyOk, setCopyOk] = useState(false);
    const [icon, setIcon] = useState("clipboard");
    useEffect(() => {
        if (copyOk) {
            void navigator.clipboard.writeText(copiedContent);
            setIcon("clipboard-check");
            const copyDelay = setTimeout(() => {
                setCopyOk(false);
                setIcon("clipboard");
            }, 1000);
            return () => window.clearTimeout(copyDelay);
        }
    }, [copyOk]);
    return (_jsx("div", { className: "not-prose bg-components-light hover:bg-components-medium dark:bg-dark-components-light dark:hover:bg-dark-components-medium absolute right-1 top-1 rounded", children: _jsx("button", { onClick: () => setCopyOk(true), className: "flex h-8 w-8 cursor-pointer items-center justify-center rounded-md", title: "copy", children: _jsx(IconV2, { type: "fa", icon: {
                    icon: ["far", icon],
                    fixedWidth: true,
                }, color: "slate" }) }) }));
};
