var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Label from "../label/Label";
import { IconV2 } from "../../icon_v2";
const TextareaComponent = (_a) => {
    var _b, _c;
    var { labelPosition = "top" } = _a, props = __rest(_a, ["labelPosition"]);
    let backgroundColor;
    if (props.invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (props.bgColor === "grey" || props.bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    const invalidClasses = props.invalid === true
        ? ` border-error dark:border-dark-error`
        : ` focus:border-focus dark:focus:border-dark-focus`;
    const disabledClasses = props.disabled === true ? ` form-field-disabled cursor-not-allowed` : ``;
    const readonlyClasses = props.readOnly === true ? ` form-field-readOnly` : ``;
    let resizeClass;
    switch (props.resize) {
        case "horizontal":
            resizeClass = "resize-x";
            break;
        case "vertical":
            resizeClass = "resize-y";
            break;
        case "both":
            resizeClass = "resize";
            break;
        default:
            resizeClass = "resize-none";
            break;
    }
    // conditionally includ the aria labeled by attr if input is not nested in the label
    const labelledby = labelPosition === "side" ? { "aria-labelledby": props.name } : {};
    return (_jsxs("div", { className: "relative", children: [_jsx("textarea", Object.assign({ id: `${props.id}-textarea`, "data-test-id": `${props.testId}-textarea`, rows: props.rows, cols: props.cols, placeholder: props.placeholder, name: props.name, value: props.value, readOnly: props.readOnly, required: props.required, disabled: props.disabled, onBlur: props.onBlur, onFocus: props.onFocus, onChange: (e) => props.onChange(e), onKeyDown: props.onKeyDown, "aria-invalid": props.invalid }, labelledby, { className: `${backgroundColor}
        text-primaryText dark:text-dark-primaryText form-field-default focus:border-focus dark:focus:border-dark-focus placeholder:text-placeholder dark:placeholder:text-dark-placeholder block w-full border font-normal focus-within:ring-1 ${invalidClasses} ${resizeClass} ${disabledClasses} ${readonlyClasses}
        ${props.enableChangeIndicatorUI
                    ? "pr-9"
                    : ""}
        ${props.initialValueChanged === true
                    ? "form-field-change-indicator"
                    : ""}
      ` })), props.enableChangeIndicatorUI &&
                !((_b = props.readOnly) !== null && _b !== void 0 ? _b : false) &&
                !((_c = props.disabled) !== null && _c !== void 0 ? _c : false) && (_jsx("span", { className: "pointer-events-none absolute right-2 top-2 flex items-center", children: props.initialValueChanged ===
                    true ? (_jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "link-simple-slash"],
                        fixedWidth: true,
                        size: "lg",
                    }, color: "base" })) : (_jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "link-simple"],
                        fixedWidth: true,
                        size: "lg",
                    }, color: "base" })) }))] }));
};
export const Textarea = (props) => {
    return (_jsxs("div", { "data-eco-component": "textarea", id: props.id, "data-test-id": props.testId, children: [_jsx(Label, Object.assign({ hideLabel: props.hideLabel, required: props.required, optional: props.optional, disabled: props.disabled, labelPosition: props.labelPosition }, props, { children: _jsx(TextareaComponent, Object.assign({}, props)) })), props.helperText !== undefined && props.invalid !== true && (_jsx("p", { className: "text-tertiary dark:text-dark-tertiary mt-1 text-xs", children: props.helperText }))] }));
};
export default Textarea;
