import { jsx as _jsx } from "react/jsx-runtime";
// import { useRuntimeEnvironmentContext } from "./runtime-enviroment";
const withDeprecation = (Component, msg = "") => (props) => {
    // const { environment } = useRuntimeEnvironmentContext();
    // if (environment === "development") {
    // console.warn(`ECO DEPRECATION WARNING: ${msg}`);
    // }
    console.warn(`ECO DEPRECATION WARNING: ${msg}`);
    return _jsx(Component, Object.assign({}, props));
};
export default withDeprecation;
