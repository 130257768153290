import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useWizard from "./useWizard";
import WizardStepCount from "./WizardStepCount";
export const WizardStep = ({ idx, isSelected, isLast, completed = false, skip = false, children, }) => {
    const [{ currentStep: currentStepIdx, canContinue }, { setCurrentStep }] = useWizard();
    let baseIdx = (idx !== null && idx !== void 0 ? idx : 0) || 0;
    let adjustedIdx = baseIdx + 1;
    // Proceed with next step only if canContinue
    const handleOnClick = (newStepIdx) => {
        if (baseIdx === currentStepIdx) {
            return;
        }
        if (canContinue || completed) {
            setCurrentStep(newStepIdx);
        }
    };
    const handleOnKeyDown = (newStepIdx) => {
        if (baseIdx === currentStepIdx) {
            return;
        }
        if (canContinue || completed) {
            setCurrentStep(newStepIdx);
        }
    };
    return (_jsxs("div", { className: `${
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        isSelected && !skip
            ? "border-dark-interactive dark:border-dark-interactive"
            : "border-dark dark:border-dark-dark"} ${completed
            ? "border-focus dark:border-dark-focus text-link dark:text-dark-link hover:text-link-hover dark:hover:text-dark-link-hover"
            : "text-primaryText dark:text-dark-primaryText pointer-events-none"}
      eco-wizard-step border-l border-l-2 py-4 pl-2 md:border-b md:border-b-2 md:border-l-0 md:py-0 md:pb-2 md:pl-0`, role: "button", onClick: () => handleOnClick(baseIdx), onKeyDown: () => handleOnKeyDown(baseIdx), tabIndex: baseIdx + 1, id: `eco-wizard-step-${baseIdx}`, "data-eco-component": "wizard-step", children: [_jsx(WizardStepCount, { isSelected: isSelected, adjustedIdx: adjustedIdx, completed: completed, skip: skip }), _jsx("h4", { className: "text-md font-bold", children: children })] }, baseIdx));
};
export default WizardStep;
