import React, { createContext, useEffect, useState } from "react";
import type { ReactElement, ReactNode } from "react";

import Head from "next/head";
import { NextConfig } from "next";
import getConfig from "next/config";
import type { NextPage } from "next";
import type { AppProps } from "next/app";

import { withLDProvider } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "react-query";

import { ThemeProvider } from "next-themes";

import { ProtectedApp, UserProvider } from "@eco/stratos-auth";
import { useAnalytics } from "@eco/stratos-utilities";
import NavBar from "../components/navbar/NavBar";
import Datadog from "../components/Datadog";
import { IdleComponent } from "@eco/stratos-appShell";

import { useRouter } from "next/router";
import { NotifierContextProvider } from "react-headless-notifier";
// import {
//   RuntimeEnvironmentCxtProvider,
//   RuntimeEnvironments,
// } from "@eco/stratos-components";
import "../styles/style.css";

import proIcons from "@fortawesome/fontawesome-pro/js/all";
import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
// import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const { publicRuntimeConfig } = getConfig() as NextConfig;

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  library.add(proIcons);
  useEffect(() => {
    dom.watch();
    void dom
      .i2svg()
      // eslint-disable-next-line no-console
      .catch(() => console.warn("Icons failed to load."));
  }, []);

  const router = useRouter();
  if (publicRuntimeConfig?.SEGMENT_KEY !== undefined) {
    // Initialize Segment Analytics
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAnalytics(publicRuntimeConfig.SEGMENT_KEY, router);
  }

  // const [showMenu, setShowMenu] = useState<boolean>(false);
  // const [navBarVisible, setNavBarVisible] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const getLayout = Component.getLayout ?? ((page) => page);

  const queryClient = new QueryClient();

  // Janky but works to scroll window to the anchor hash. `setTimeout` is always code smell
  // but in the case of loading Eco Docs from a bookmark or pasted URL that contains an
  // anchor hash (ie. `elements/button#no-lable`), the page would load but not scroll to
  // the anchor
  // Original Issue: https://github.com/vercel/next.js/issues/11109
  //    Though it's, closed due to inactivity, it still exists
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        document.querySelector(hash)?.scrollIntoView({ behavior: "auto" });
      }, 500);
    }
  });

  return (
    <UserProvider errorPath={""}>
      <ProtectedApp ignoreAccess>
        <NotifierContextProvider
          // All props are optional, those are the values by default
          config={{
            max: null,
            duration: 5000,
            position: "bottomRight", // You can specify a position where the notification should appears, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
          }}
        >
          <Head>
            <title>Eco Documentation</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
          </Head>

          {/* <EmailCheck></EmailCheck> */}

          <QueryClientProvider client={queryClient}>
            {/* <NavBar showMenu={setShowMenu} showNavBar={navBarVisible} /> */}
            {/* <ShowMobileNavContext.Provider value={{ showMenu, setShowMenu }}> */}
            {/* <RuntimeEnvironmentCxtProvider
              environment={
                publicRuntimeConfig?.ENVIRONMENT as RuntimeEnvironments
              }
            > */}
            <ThemeProvider attribute="class">
              {getLayout(<Component {...pageProps} />)}
            </ThemeProvider>
            {/* </RuntimeEnvironmentCxtProvider> */}
            {/* </ShowMobileNavContext.Provider> */}
            <Datadog />
          </QueryClientProvider>
        </NotifierContextProvider>
        {/* <IdleComponent idleTimer={5000} /> */}
      </ProtectedApp>
    </UserProvider>
  );
}

// Opt out of static optimization for runtime env vars
// https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
// eslint-disable-next-line require-await
MyApp.getInitialProps = async () => {
  return {};
};

export default withLDProvider({
  // clientSideID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? "",
  clientSideID: publicRuntimeConfig?.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? "",
})(MyApp);
