"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEvent = void 0;
/**
 * Create a custom Eco event
 *
 * @param {string} event The name of the event
 * @param {object} detail An optional details object
 * @param {CustomEventInit} eventOptions An optional event options object
 * @returns The created Eco event
 */
function createEvent(event, detail, eventOptions) {
    var _a, _b, _c;
    let bubbles = (_a = eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.bubbles) !== null && _a !== void 0 ? _a : true;
    let composed = (_b = eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.composed) !== null && _b !== void 0 ? _b : true;
    let cancelable = (_c = eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.cancelable) !== null && _c !== void 0 ? _c : true;
    // let meta = {
    //   timestamp: Date.now(),
    // };
    let ecoEvent = new CustomEvent(event, {
        detail,
        bubbles,
        composed,
        cancelable,
    });
    return ecoEvent;
}
exports.createEvent = createEvent;
