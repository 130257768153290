export var ModalIconVariants;
(function (ModalIconVariants) {
    ModalIconVariants["INFO"] = "info";
    ModalIconVariants["SUCCESS"] = "success";
    ModalIconVariants["ERROR"] = "error";
    ModalIconVariants["WARNING"] = "warning";
    ModalIconVariants["DESCTRUCTIVE"] = "destructive";
})(ModalIconVariants || (ModalIconVariants = {}));
export var ColorVariants;
(function (ColorVariants) {
    ColorVariants["PRIMARY"] = "primary";
    ColorVariants["SECONDARY"] = "secondary";
    ColorVariants["TERTIARY"] = "tertiary";
    ColorVariants["DESCTRUCTIVE"] = "destructive";
    ColorVariants["SECONDARYDESTRUCTIVE"] = "secondary-destructive";
    ColorVariants["TRANSPARENT"] = "transparent";
})(ColorVariants || (ColorVariants = {}));
