import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "../button";
import { Heading } from "../typography";
import { WizardNextSteps } from "./WizardNextSteps";
import { WizardProvider } from "./WizardProvider";
export const Wizard = ({ id = "eco-wizard", startingIndex = 0, title, onCancel, nextSteps, children, }) => {
    const childrenArray = React.Children.toArray(children);
    const showControls = title !== undefined || onCancel !== undefined || nextSteps !== undefined;
    const showButtons = onCancel !== undefined || nextSteps !== undefined;
    // with this API we expect the first child to be a list of tabs
    // followed by a list of tab panels that correspond to those tabs
    // the ordering is determined by the position of the elements
    // that are passed in as children
    const [steps, ...panels] = childrenArray;
    return (_jsx(WizardProvider, { startingIndex: startingIndex, children: _jsxs("div", { id: `${id}`, "data-eco-component": "wizard", children: [showControls && (_jsxs("div", { className: "mb-4 flex flex-row items-center gap-4", "data-eco-component": "wizard-controls", children: [title !== undefined && _jsx(Heading, { as: "h2", children: title }), showButtons && (_jsxs("div", { className: "flex grow flex-row justify-end gap-4", children: [onCancel !== undefined && (_jsx(Button, { variant: "secondary", onClick: onCancel, children: "Cancel" })), nextSteps !== undefined && (_jsx(WizardNextSteps, { nextSteps: nextSteps }))] }))] })), steps, panels] }) }));
};
export default Wizard;
