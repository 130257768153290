import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const DescriptionListItem = ({ id, testId, title, children, action, striped = false, }) => {
    return (_jsxs("div", { className: classNames("sm:grid sm:grid-cols-3 sm:gap-4", striped
            ? "px-4 py-5 odd:bg-white even:bg-gray-50 sm:px-6"
            : "py-4 sm:py-5"), id: id, "data-test-id": testId, "data-eco-component": "description-list-item", children: [_jsx("dt", { className: "text-sm font-medium text-gray-500 dark:text-gray-300", children: title }), _jsxs("dd", { className: "mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300", children: [_jsx("span", { className: "flex-grow", children: children }), action !== undefined && (_jsx("span", { className: "ml-4 flex-shrink-0", children: action }))] })] }));
};
export default DescriptionListItem;
