import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter } from "next/router";
import { IconV2 } from "../icon_v2";
export const Breadcrumbs = ({ id, testId, rootLabel = "Home", pageLabel, showRootLabel = true, customCrumbs, crumbClickHandler = null, }) => {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (router === undefined) {
        return (_jsx(_Fragment, { children: "Please use this component within a NextJS application, it requires useRouter." }));
    }
    function generateBreadcrumbs() {
        if (customCrumbs) {
            return customCrumbs;
        }
        // remove any query params
        const splitters = /\?|#/;
        const [pathWithoutQuery] = router.asPath.split(splitters);
        // if the path is nested, break it apart
        const pathNestedRoutes = pathWithoutQuery === null || pathWithoutQuery === void 0 ? void 0 : pathWithoutQuery.split("/").filter((v) => v.length > 0);
        // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
        const crumblist = pathNestedRoutes === null || pathNestedRoutes === void 0 ? void 0 : pathNestedRoutes.map((path, id, arr) => {
            const href = router.basePath + "/" + arr.slice(0, id + 1).join("/");
            // eslint-disable-next-line no-use-before-define
            const text = pathNameToTitle(path);
            return { href, text };
        });
        if (pageLabel !== undefined && pageLabel !== "") {
            const lastCrumb = crumblist[crumblist.length - 1];
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            crumblist[crumblist.length - 1] = {
                href: lastCrumb === null || lastCrumb === void 0 ? void 0 : lastCrumb.href,
                text: pageLabel,
            };
        }
        const rootCrumb = showRootLabel
            ? [{ href: "/", text: rootLabel }]
            : [];
        return [...rootCrumb, ...crumblist];
    }
    const pathNameToTitle = (str) => {
        // if the Next Page is named with an underscore, hyphen, or camelCase
        // make readable then titlecase the string
        const cleanedStr = str
            .replace(/[_-]/, " ")
            .replace(/([0-9A-Z])/g, " $&")
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(" ");
        return cleanedStr;
    };
    const breadcrumbs = generateBreadcrumbs();
    /**
     * This click handler is fired if a `crumbClickHandler` prop is defined.
     * The event is bubbled back up to the parent component of the Breadcrumb
     * component and the event is handled there.
     *
     * @param e The SyntheticEvent dispatched from the click event
     * @param crumb The Crumb object passed from the click event
     */
    const clickHandler = (e, crumb) => {
        e.preventDefault();
        if (crumbClickHandler) {
            crumbClickHandler(crumb);
        }
    };
    const chevronRightIcon = (_jsx("span", { className: "text-tertiary dark:text-dark-tertiary", children: _jsx(IconV2, { type: "fa", icon: {
                icon: ["far", "chevron-right"],
                size: "sm",
                color: "gray",
            } }) }));
    const chevronLeftIcon = (_jsx("span", { className: "text-tertiary dark:text-dark-tertiary", children: _jsx(IconV2, { type: "fa", icon: {
                icon: ["far", "chevron-left"],
                size: "sm",
                color: "gray",
            } }) }));
    return (_jsx("div", { className: "flex flex-grow flex-col", "data-eco-component": "breadcrumbs", children: _jsxs("div", { className: 
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            router !== undefined && router.pathname === "/"
                ? `invisible`
                : `visible`, children: [_jsx("div", { className: "inline-flex hidden content-center items-center justify-start text-sm sm:block", id: id, "data-test-id": testId, children: breadcrumbs.map((crumb, index, arr) => {
                        const chevronRight = index + 1 !== arr.length && chevronRightIcon;
                        let crumbStyle = "text-link dark:text-dark-link pr-2 px-1 hover:text-link-hover dark:hover:text-dark-link-hover hover:underline hover:underline-offset-2";
                        if (index === arr.length - 1) {
                            crumbStyle = `text-tertiary dark:text-dark-tertiary cursor-default`;
                        }
                        else if (index !== 0) {
                            crumbStyle = `${crumbStyle} content-center items-center`;
                        }
                        if (index === arr.length - 1) {
                            return (_jsx("span", { id: crumb.text, className: "inline-flex", children: _jsx("span", { className: crumbStyle, children: crumb.text }) }, index));
                        }
                        return (_jsxs("span", { id: crumb.text, className: "inline-flex", children: [_jsx("span", { className: crumbStyle, children: _jsx("a", { href: crumb.href, onClick: crumbClickHandler !== null
                                            ? (e) => clickHandler(e, crumb)
                                            : undefined, children: crumb.text }) }), _jsx("span", { className: "pr-2", children: chevronRight })] }, index));
                    }) }), _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary flex cursor-pointer flex-row pr-2 text-sm sm:hidden", onClick: () => router.back(), children: [chevronLeftIcon, _jsx("span", { className: "pl-2", children: "Back" })] })] }) }));
};
export default Breadcrumbs;
