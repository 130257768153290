import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
const ClearIndicator = (props) => {
    return (_jsx("div", Object.assign({ id: "indicator-container", className: "mr-2 block py-1" }, props.innerProps, { children: _jsxs("button", { className: "bg-text-primaryText dark:bg-dark-text-primaryText text-inverse-primary dark:text-dark-inverse-primary hover:text-primaryText dark:hover:text-dark-primaryText m-1 flex size-6 flex-shrink-0 items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-800", children: [_jsx("span", { className: "sr-only", children: "Clear All" }), _jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "xmark"],
                        fixedWidth: true,
                        size: "xs",
                    } })] }) })));
};
export default ClearIndicator;
