var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useState } from "react";
import { Menu as Dropdown, Transition } from "@headlessui/react";
import { IconV2 } from "../../icon_v2";
import { SplitbuttonOption } from "./SplitbuttonOption";
const useFindChildIndex = (children, selected) => {
    const childrenArray = React.Children.toArray(children);
    const selectedIndex = childrenArray.findIndex((child) => child.props.label === selected);
    return selectedIndex;
};
export const Splitbutton = (_a) => {
    var _b, _c, _d, _e, _f;
    var { align, variant, size, mainOption, id, testId, children } = _a, props = __rest(_a, ["align", "variant", "size", "mainOption", "id", "testId", "children"]);
    const [selectedIndex, setSelectedIndex] = useState(useFindChildIndex(children, mainOption));
    if (selectedIndex === -1) {
        throw new Error(`Splitbutton component must have a child with label ${mainOption}`);
    }
    const childrenArray = React.Children.toArray(children);
    const selectedChild = childrenArray[selectedIndex].props;
    const position = align !== null && align !== void 0 ? align : "right";
    let variantColorStyle;
    let dividerColor;
    let borderStyle = "border border-transparent";
    switch (variant) {
        case "primary":
            variantColorStyle =
                "bg-buttons-primary-bg-normal hover:bg-buttons-primary-bg-hover  text-buttons-primary-text active:bg-buttons-primary-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-primary-bg-normal dark:hover:bg-dark-buttons-primary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-primary-text dark:active:bg-dark-buttons-primary-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            dividerColor = "bg-white";
            break;
        case "tertiary":
            variantColorStyle =
                "[&:not(:disabled)]:hover:bg-buttons-tertiary-bg-hover focus:outline-none focus:ring-2 focus:ring-focus text-buttons-tertiary-text active:bg-buttons-tertiary-bg-active disabled:text-buttons-disabled-text dark:[&:not(:disabled)]:hover:bg-dark-buttons-tertiary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-tertiary-text dark:active:bg-dark-buttons-tertiary-bg-active dark:disabled:bg-transparent dark:disabled:text-dark-buttons-disabled-text";
            dividerColor = "bg-transparent";
            break;
        case "destructive":
            variantColorStyle =
                "bg-buttons-destructive-bg-normal hover:bg-buttons-destructive-bg-hover focus:outline-none focus:ring-2 focus:ring-focus text-buttons-destructive-text active:bg-buttons-destructive-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-destructive-bg-normal dark:hover:bg-dark-buttons-destructive-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-destructive-text dark:active:bg-dark-buttons-destructive-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            break;
        case "secondary-destructive":
            variantColorStyle =
                "bg-buttons-desctructive-outline-bg-normal border border-1 hover:bg-buttons-desctructive-outline-bg-hover focus:outline-none focus:ring-2 focus:ring-focus focus:[&:not(:disabled)]:border-red-600/0 text-buttons-desctructive-outline-text active:bg-buttons-desctructive-outline-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-desctructive-outline-bg-normal dark:hover:bg-dark-buttons-desctructive-outline-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-desctructive-outline-text dark:active:bg-dark-buttons-desctructive-outline-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text [&:not(:disabled)]:border-red-600 dark:[&:not(:disabled)]:border-red-400 focus:dark:[&:not(:disabled)]:border-red-400/0";
            break;
        case "transparent":
            variantColorStyle =
                "bg-transparent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-focus focus:ring-offset-1 text-gray-700 dark:bg-transparent";
            break;
        case "secondary":
        default:
            variantColorStyle =
                "bg-buttons-secondary-bg-normal [&:not(:disabled)]:border-buttons-secondary-forecolor dark:[&:not(:disabled)]:border-dark-buttons-secondary-forecolor border hover:bg-buttons-secondary-bg-hover text-buttons-secondary-forecolor dark:text-dark-buttons-secondary-forecolor active:bg-buttons-secondary-bg-active disabled:bg-buttons-disabled-background disabled:text-buttons-disabled-text dark:bg-dark-buttons-secondary-bg-normal dark:hover:bg-dark-buttons-secondary-bg-hover dark:focus:ring-dark-focus dark:text-dark-buttons-secondary-text dark:active:bg-dark-buttons-secondary-bg-active dark:disabled:bg-dark-buttons-disabled-background dark:disabled:text-dark-buttons-disabled-text";
            dividerColor = "bg-blue-600";
            break;
    }
    let buttonSize;
    let textSize;
    let dividerSize;
    switch (size) {
        case "lg":
            buttonSize = "h-14 px-6";
            textSize = "text-lg";
            dividerSize = "h-14";
            break;
        case "sm":
            buttonSize = "h-8 px-3";
            textSize = "text-sm";
            dividerSize = "h-8";
            break;
        default:
            buttonSize = "h-11 px-4";
            textSize = "text-base";
            dividerSize = "h-11";
            break;
    }
    const buttonStyle = `${borderStyle} leading-4
  font-medium ${variantColorStyle} pointer-events-auto ${buttonSize} w-full justify-center sm:w-auto`;
    return (_jsxs("div", { id: id, "data-testid": testId, "data-eco-component": "splitbutton", className: "relative inline-flex", children: [_jsx("button", { type: (_b = selectedChild.type) !== null && _b !== void 0 ? _b : "button", form: (_c = selectedChild.form) !== null && _c !== void 0 ? _c : "", onClick: (_d = selectedChild.onClick) !== null && _d !== void 0 ? _d : (() => { }), onBlur: (_e = selectedChild.onBlur) !== null && _e !== void 0 ? _e : (() => { }), onFocus: (_f = selectedChild.onFocus) !== null && _f !== void 0 ? _f : (() => { }), className: `${buttonStyle} flex items-center justify-center rounded-l-md border-r-0`, children: _jsxs("span", { className: `pointer-events-auto flex items-center justify-center ${textSize}`, children: [selectedChild.loading !== undefined ? (_jsx("div", { className: `mr-2 h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-t-transparent fill-current `, children: _jsx("span", { className: "sr-only", children: "Loading" }) })) : null, selectedChild.iconName !== undefined && (_jsx("span", { className: "flex max-h-5 items-center pl-2 pr-4", children: _jsx(IconV2, { type: "fa", icon: {
                                    icon: ["far", selectedChild.iconName],
                                } }) })), _jsx("span", { className: `text-left ${textSize}`, children: selectedChild.label })] }) }), _jsx("div", { className: `${dividerColor} ${dividerSize} w-px` }), _jsxs(Dropdown, { as: "div", className: "inline-block", children: [_jsx("div", { children: _jsx(Dropdown.Button, { className: `${buttonStyle} rounded-r-md border-l-0`, children: ({ open }) => (_jsx(IconV2, { type: "fa", icon: {
                                    icon: ["far", open ? "chevron-up" : "chevron-down"],
                                } })) }) }), _jsx(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsx(Dropdown.Items, { as: "ul", className: `z-dropdown absolute ${position}-0 bg-components-baseComponent dark:bg-dark-components-baseComponent border-light dark:border-dark-light mt-2 min-w-[10rem] origin-top-left rounded-md p-2 shadow-lg ring-1 ring-opacity-5 focus:outline-none`, children: mainOption &&
                                React.Children.toArray(children)
                                    .filter((_, index) => index !== selectedIndex)
                                    .map((child, index) => {
                                    if (React.isValidElement(child)) {
                                        return React.cloneElement(child, Object.assign(Object.assign({}, props), { as: "li" }));
                                    }
                                    return child;
                                }) }) })] })] }));
};
Splitbutton.Option = SplitbuttonOption;
SplitbuttonOption.displayName = "Splitbutton.Option";
export default Splitbutton;
