import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useAuth, login } from "./auth";
import getConfig from "next/config";
import { useRouter } from "next/router";
export const ProtectedApp = ({ accessRedirect, ignoreAccess = false, children, }) => {
    const { user, isLoading } = useAuth();
    const { publicRuntimeConfig } = getConfig();
    const router = useRouter();
    if (!isLoading) {
        if (!user) {
            login(router.basePath);
            return null;
        }
        if (!user.availableApps.includes(publicRuntimeConfig.DTN_PRODUCT_CODE) &&
            !ignoreAccess &&
            window.location.href !== `${window.location.origin}/unauthorized` &&
            window.location.href !== `${window.location.origin}${accessRedirect}`) {
            if (accessRedirect !== undefined) {
                if (window.location.href !== `${window.location.origin}${accessRedirect}`) {
                    window.location.replace(`${window.location.origin}${accessRedirect}`);
                    return null;
                }
            }
            else {
                window.location.replace(`${window.location.origin}/unauthorized`);
            }
        }
    }
    return _jsx(React.Fragment, { children: user && !isLoading && children });
};
