import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../../icon_v2";
import CollapseGroupButton from "./CollapseGroupButton";
const CollapseGroup = ({ collapsed, collapseGroup, expandGroup, label, selectedOptions = 0, totalOptions = 0, onChange, optionSelected, }) => {
    const handleOnRemove = () => {
        const newVals = optionSelected.filter((opt) => opt.groupLabel !== label);
        const removedValues = optionSelected.filter((opt) => opt.groupLabel === label);
        onChange(newVals, {
            action: "deselect-option",
            removedValues,
            option: {
                value: `remove-eco-group-${label}`,
                label,
            },
        });
    };
    return (_jsxs("div", { className: "flex flex-row gap-1", children: [_jsx(CollapseGroupButton, { label: label, collapseGroup: collapseGroup, expandGroup: expandGroup, collapsed: collapsed }), _jsxs("div", { className: "flex-none", children: [label, " (", selectedOptions, "/", totalOptions, ")"] }), _jsx("div", { className: "bg-pill-text-default dark:bg-dark-pill-text-default text-dark-pill-text-default dark:text-pill-text-default mx-1 block flex size-4 min-w-[16px] items-center justify-center rounded-full", onClick: () => handleOnRemove(), role: "button", tabIndex: 0, "aria-label": `remove-all-from-${label.toLowerCase()}`, children: _jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "xmark"],
                        fixedWidth: true,
                        size: "xs",
                    } }) })] }));
};
export default CollapseGroup;
