var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Children, useState, } from "react";
import { SegmentControlButton, } from "./SegmentControlButton";
export const SegmentControl = ({ id, testId, selected, onSelect, children }) => {
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(selected !== null && selected !== void 0 ? selected : 0);
    const handleButtonClick = (index) => {
        setSelectedButtonIndex(index);
        if (onSelect)
            onSelect(index);
    };
    return (_jsx("div", { "data-eco-component": "segment-control", id: id, "data-test-id": testId, children: _jsx("div", { className: "bg-components-light dark:bg-dark-components-light flex gap-x-2  overflow-x-auto rounded-lg p-1 [&::-webkit-scrollbar]:[display:none]", children: Children.map(children, (child, index) => {
                if (React.isValidElement(child) &&
                    child.type === SegmentControlButton) {
                    const _a = child.props, { onClick: childOnClick } = _a, otherChildProps = __rest(_a, ["onClick"]);
                    return (_jsx(SegmentControlButton, Object.assign({}, otherChildProps, { selected: selectedButtonIndex === index, onClick: (e) => {
                            handleButtonClick(index);
                            if (childOnClick) {
                                childOnClick(e);
                            }
                        } })));
                }
                return child;
            }) }) }));
};
SegmentControl.Button = SegmentControlButton;
SegmentControlButton.displayName = "SegmentControl.Button";
