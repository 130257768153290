import { jsx as _jsx } from "react/jsx-runtime";
import { components } from "react-select";
/**
 * Override react-select's Placeholder:
 *
 * Component to be displayed in the input when nothing is selected. By default it is the text 'Select...'
 */
const Placeholder = (props) => {
    return (_jsx(components.Placeholder, Object.assign({ className: "text-placeholder text-md font-normal" }, props)));
};
export default Placeholder;
