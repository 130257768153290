import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, memo } from "react";
import RadioGroup from "./radio-group";
const formatSuggestedRange = (e) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const n = 1 === e.amount ? e.unit : `${e.unit}s`;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `Past ${e.amount} ${n}`;
};
export const SuggestedTimeRange = ({ options: clientOptions = [], onChange, }) => {
    const radioOptions = clientOptions.map((option) => ({
        selectedTimeRange: option,
        label: formatSuggestedRange(option),
    }));
    const showRadioControl = clientOptions.length > 0;
    const handleDateChange = useCallback((timeFrame) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onChange(timeFrame);
    }, [onChange]);
    return (_jsx("div", { className: "suggested-timerange", role: "radiogroup", "aria-labelledby": "suggested-timerange-label", children: showRadioControl && (_jsx("div", { className: "mt-3", children: _jsx(RadioGroup, { selectedTimeRange: null, items: radioOptions, onChange: handleDateChange }) })) }));
};
export default memo(SuggestedTimeRange);
