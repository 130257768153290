import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { IconV2 } from "../../icon_v2";
import { useAdvancedDatePicker } from "./AdvancedDatePickerProvider";
const ClearButton = (props, ref) => {
    const { value, handleDateChangedValue } = useAdvancedDatePicker();
    const handleOnClick = () => {
        props.onChange(value);
        handleDateChangedValue(null);
    };
    return (value && (_jsx("button", { onClick: handleOnClick, ref: ref, children: _jsx("span", { className: "text-secondary dark:text-dark-secondary inline-block", children: _jsx(IconV2, { type: "fa", icon: {
                    icon: ["far", "xmark"],
                    fixedWidth: true,
                    size: "lg",
                } }) }) })));
};
export default forwardRef(ClearButton);
