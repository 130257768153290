import { jsx as _jsx } from "react/jsx-runtime";
import { useNotifier } from "react-headless-notifier";
import { Alert } from "@eco/stratos-components";
import "./notify.css";
export const useNotify = () => {
    const { notify: sendNotify, dismissAll } = useNotifier();
    const notify = (props) => {
        var _a, _b;
        const alertProps = props;
        const variant = (_a = alertProps.variant) !== null && _a !== void 0 ? _a : "info";
        sendNotify(_jsx(Alert, { variant: variant, heading: alertProps.heading, message: alertProps.message, onDismiss: alertProps.onDismiss, dismissible: alertProps.dismissible, actions: alertProps.actions }), {
            position: "top",
            duration: (_b = props.timeout) !== null && _b !== void 0 ? _b : 100000,
        });
    };
    return { notify, dismissAll };
};
export default useNotify;
