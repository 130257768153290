import { jsx as _jsx } from "react/jsx-runtime";
export const Spinner = ({ id, testId, size, mode = "dark", }) => {
    let dimensions, border;
    switch (size) {
        case "xs":
            dimensions = "h-4 w-4 border-2";
            break;
        case "sm":
            dimensions = "h-6 w-6 border-2";
            break;
        case "lg":
            dimensions = "h-12 w-12 border-4";
            break;
        case "xl":
            dimensions = "h-16 w-16 border-8";
            break;
        case "xxl":
            dimensions = "h-20 w-20 border-8";
            break;
        default:
            dimensions = "h-8 w-8 border-4";
    }
    switch (mode) {
        case "light":
            border =
                "border-brand-white/20 border-t-brand-white dark:border-gray-950/20 dark:border-t-gray-950";
            break;
        case "DTN":
            border =
                "border-t-brand-dtn-blue border-r-brand-dtn-blue border-b-brand-dtn-green border-l-brand-dtn-green";
            break;
        default:
            border =
                "border-light border-t-brand-accent dark:border-dark-light dark:border-t-brand-accent";
    }
    return (_jsx("div", { id: id, "data-eco-component": "spinner", "data-test-id": testId, className: `${dimensions} my-2 animate-spin rounded-full border-solid ${border}`, role: "status", children: _jsx("span", { className: "sr-only", children: "Loading" }) }));
};
export default Spinner;
