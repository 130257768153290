var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { StatusIndicator } from "../status-indicator";
import { IconV2 } from "../icon_v2";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const SelectTabs = (_a) => {
    var _b, _c, _d, _e;
    var { tabs, id, testId, onChange, selectedId } = _a, props = __rest(_a, ["tabs", "id", "testId", "onChange", "selectedId"]);
    const defaultSelectedValue = selectedId !== null && selectedId !== void 0 ? selectedId : (_b = tabs[0]) === null || _b === void 0 ? void 0 : _b.id;
    const selectedIndex = (_c = props.selectedIndex) !== null && _c !== void 0 ? _c : tabs.findIndex((tab) => tab.id === defaultSelectedValue);
    const defaultSelectedLabel = (_d = tabs.find((tab) => tab.id === defaultSelectedValue)) === null || _d === void 0 ? void 0 : _d.label;
    const [selectedValue, setSelectedValue] = useState(defaultSelectedLabel);
    const [selectedValueId, setSelectedValueId] = useState(defaultSelectedValue);
    useEffect(() => {
        var _a;
        setSelectedValue((_a = tabs[selectedIndex]) === null || _a === void 0 ? void 0 : _a.label);
    }, [selectedIndex, tabs]);
    const setUpdatedTab = (val) => {
        const index = tabs.findIndex((tab) => tab.label === val);
        const valId = tabs.find((tab) => tab.label === val).id;
        if (onChange) {
            onChange(index);
        }
        setSelectedValue(val);
        setSelectedValueId(valId);
    };
    const showIcon = ((_e = tabs[selectedIndex]) === null || _e === void 0 ? void 0 : _e.iconName) !== undefined;
    let selectedIcon;
    if (showIcon) {
        selectedIcon = tabs[selectedIndex].iconName;
    }
    return (_jsx("div", { id: id, "data-test-id": testId, children: _jsx(Listbox, { value: selectedValue, onChange: (val) => setUpdatedTab(val), children: ({ open }) => (_jsxs("div", { className: "relative w-full", children: [_jsxs(Listbox.Button, { className: "border-dark dark:border-dark-dark focus:border-focus dark:focus:border-dark-focus bg-container-baseContainer dark:bg-dark-container-baseContainer text-primaryText dark:text-dark-primaryText relative w-full cursor-default rounded-md border py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm", children: [_jsxs("span", { className: "flex content-center gap-3 truncate", id: id !== undefined ? `tabs-button-${id}` : undefined, children: [showIcon && (_jsx(IconV2, { type: "fa", icon: {
                                            icon: selectedIcon,
                                            fixedWidth: true,
                                            size: "lg",
                                        }, color: "base" })), selectedValue] }), _jsx("span", { className: "pointer-events-none absolute inset-y-0 right-0 ml-2 mr-4 mt-1 flex items-center", children: open ? (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "angle-up"], fixedWidth: true }, color: "base" })) : (_jsx(IconV2, { type: "fa", icon: { icon: ["far", "angle-down"], fixedWidth: true }, color: "base" })) })] }), _jsx(Listbox.Options, { className: "z-dropdown dark:bg-dark-container-light bg-container-light absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", children: _jsx(Transition, { show: open, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: tabs.map((tab, i) => {
                                return (_jsx(Listbox.Option, { disabled: tab.disabled, value: tab.label, className: ({ active, selected }) => classNames(active ? "text-primaryText bg-blue-50" : "", selected && !active
                                        ? "bg-brand-accent dark:bg-dark-brand-accent text-white"
                                        : "", tab.disabled === true
                                        ? "text-tertiary dark:text-dark-tertiary"
                                        : "cursor-default ", tab.disabled !== true && !active
                                        ? "text-primaryText dark:text-dark-primaryText"
                                        : "", "relative select-none px-4 py-2"), id: `tab-${tab.label}-${i}`, "data-test-id": tab.testId, children: ({ active, selected }) => (_jsx(_Fragment, { children: _jsxs("div", { className: classNames(selected ? "font-semibold" : "font-normal", "content-center-center flex items-center gap-3 truncate py-0.5"), children: [tab.iconName !== undefined &&
                                                    (tab.disabled === true ? (_jsx(IconV2, { type: "fa", icon: {
                                                            icon: tab.iconName,
                                                            fixedWidth: true,
                                                            size: "lg",
                                                        }, color: "base-light" })) : selected && !active ? (_jsx(IconV2, { type: "fa", icon: {
                                                            icon: tab.iconName,
                                                            fixedWidth: true,
                                                            size: "lg",
                                                        }, color: "white" })) : (_jsx(IconV2, { type: "fa", icon: {
                                                            icon: tab.iconName,
                                                            fixedWidth: true,
                                                            size: "lg",
                                                        }, color: "base" }))), _jsx("span", { className: "px-2", children: tab.label }), tab.status !== undefined && (_jsx(StatusIndicator, { label: tab.status.label, variant: tab.status.variant }))] }) })) }, `tab-${tab.label}-${i}`));
                            }) }) })] })) }) }));
};
export default SelectTabs;
