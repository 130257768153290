import getConfig from "next/config";
export const getUserRoles = (idToken, customerId) => {
    const { publicRuntimeConfig } = getConfig();
    const { DTN_PRODUCT_CODE } = publicRuntimeConfig;
    if (DTN_PRODUCT_CODE !== undefined) {
        const appInfo = idToken["https://auth.dtn.com/appInfo"][DTN_PRODUCT_CODE];
        if (appInfo) {
            if ("customers" in appInfo) {
                let [customer] = appInfo.customers;
                if (customerId !== undefined && customerId !== "unknown") {
                    customer = appInfo.customers.find((c) => c.customerId === customerId);
                }
                return (customer === null || customer === void 0 ? void 0 : customer.roles) ? customer.roles : [];
            }
            return appInfo.roles;
        }
    }
    return [];
};
