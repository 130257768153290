var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
/**
 * useAnalytics, hook to instantiate segment analytics
 *
 * @param key Segment Key
 * @returns
 */
export const useAnalytics = (key, router) => {
    const [analytics, setAnalytics] = useState(undefined);
    const [writeKey, setWriteKey] = useState(key);
    useEffect(() => {
        const loadAnalytics = () => __awaiter(void 0, void 0, void 0, function* () {
            let [response] = yield AnalyticsBrowser.load({ writeKey });
            setAnalytics(response);
        });
        void loadAnalytics();
    }, [writeKey]);
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const handleRouteChangeComplete = (url) => {
            void (analytics === null || analytics === void 0 ? void 0 : analytics.page(url));
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        router.events.on("routeChangeComplete", handleRouteChangeComplete);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChangeComplete);
        };
    }, [router, analytics]);
    return analytics;
};
