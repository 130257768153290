import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2, convertIcon, needsConversion } from "../icon_v2";
export const Pill = ({ id, testId, message, variant, icon, iconName, dismissible, onDismiss, }) => {
    const variantType = variant !== null && variant !== void 0 ? variant : "default";
    const showDismiss = dismissible !== null && dismissible !== void 0 ? dismissible : false;
    let variantBgColor;
    let variantFontColor;
    let dismissColor;
    let variantButtonFontColor;
    let dismissHoverBg;
    let dismissHoverText;
    let dismissFocusBg;
    switch (variantType) {
        case "primary":
            variantBgColor = "bg-pill-bg-info dark:bg-dark-pill-bg-info";
            variantFontColor = "text-pill-text-info dark:text-dark-pill-text-info";
            dismissColor = "bg-pill-text-info dark:bg-dark-pill-text-info";
            variantButtonFontColor = "text-pill-bg-info dark:text-dark-pill-bg-info";
            dismissHoverBg = "hover:bg-pill-bg-info dark:hover:bg-dark-pill-bg-info";
            dismissHoverText =
                "hover:text-pill-text-info dark:hover:text-dark-pill-text-info";
            dismissFocusBg =
                "focus:bg-pill-text-info/60 focus:dark:bg-dark-pill-text-info/60";
            break;
        case "positive":
            variantBgColor = "bg-pill-bg-positiveBg dark:bg-dark-pill-bg-positiveBg";
            variantFontColor =
                "text-pill-text-positiveText dark:text-dark-pill-text-positiveText";
            dismissColor =
                "bg-pill-text-positiveText dark:bg-dark-pill-text-positiveText";
            variantButtonFontColor =
                "text-pill-bg-positiveBg dark:text-dark-pill-bg-positiveBg";
            dismissHoverBg =
                "hover:bg-pill-bg-positiveBg dark:hover:bg-dark-pill-bg-positiveBg";
            dismissHoverText =
                "hover:text-pill-text-positiveText dark:hover:text-dark-pill-text-positiveText";
            dismissFocusBg =
                "focus:bg-pill-text-positiveText/60 focus:dark:bg-dark-pill-text-positiveText/60";
            break;
        case "negative":
            variantBgColor = "bg-pill-bg-danger dark:bg-dark-pill-bg-danger";
            variantFontColor =
                "text-pill-text-danger dark:text-dark-pill-text-danger";
            dismissColor = "bg-pill-text-danger dark:bg-dark-pill-text-danger";
            variantButtonFontColor =
                "text-pill-bg-danger dark:text-dark-pill-bg-danger";
            dismissHoverBg =
                "hover:bg-pill-bg-danger dark:hover:bg-dark-pill-bg-danger";
            dismissHoverText =
                "hover:text-pill-text-danger dark:hover:text-dark-pill-text-danger";
            dismissFocusBg =
                "focus:bg-pill-text-danger/60 focus:dark:bg-dark-pill-text-danger/60";
            break;
        case "warning":
            variantBgColor = "bg-pill-bg-warningBg dark:bg-dark-pill-bg-warningBg";
            variantFontColor =
                "text-pill-text-warningText dark:text-dark-pill-text-warningText";
            dismissColor =
                "bg-pill-text-warningText dark:bg-dark-pill-text-warningText";
            variantButtonFontColor =
                "text-pill-bg-warningBg dark:text-dark-pill-bg-warningBg";
            dismissHoverBg =
                "hover:bg-pill-bg-warningBg dark:hover:bg-dark-pill-bg-warningBg";
            dismissHoverText =
                "hover:text-pill-text-warningText dark:hover:text-dark-pill-text-warningText";
            dismissFocusBg =
                "focus:bg-pill-text-warningText/60 focus:dark:bg-dark-pill-text-warningText/60";
            break;
        case "caution":
            variantBgColor = "bg-pill-bg-warningBg dark:bg-dark-pill-bg-warningBg";
            variantFontColor =
                "text-pill-text-warningText dark:text-dark-pill-text-warningText";
            dismissColor =
                "bg-pill-text-warningText dark:bg-dark-pill-text-warningText";
            variantButtonFontColor =
                "text-pill-bg-warningBg dark:text-dark-pill-bg-warningBg";
            dismissHoverBg =
                "hover:bg-pill-bg-warningBg dark:hover:bg-dark-pill-bg-warningBg";
            dismissHoverText =
                "hover:text-pill-text-warningText dark:hover:text-dark-pill-text-warningText";
            dismissFocusBg =
                "focus:bg-pill-text-warningText/60 focus:dark:bg-dark-pill-text-warningText/60";
            break;
        case "default":
        default:
            variantBgColor = "bg-pill-bg-default dark:bg-dark-pill-bg-default";
            variantFontColor =
                "text-pill-text-default dark:text-dark-pill-text-default";
            dismissColor = "bg-pill-text-default dark:bg-dark-pill-text-default";
            variantButtonFontColor =
                "text-pill-bg-default dark:text-dark-pill-bg-default";
            dismissHoverBg =
                "hover:bg-pill-bg-default dark:hover:bg-dark-pill-bg-default";
            dismissHoverText =
                "hover:text-pill-text-default dark:hover:text-dark-pill-text-default";
            dismissFocusBg =
                "focus:bg-pill-text-default/60 focus:dark:bg-dark-pill-text-default/60";
            break;
    }
    const pillStyle = `flex rounded-full items-center self-center px-3 py-1 font-medium text-sm ${variantBgColor} ${variantFontColor} ${showDismiss && onDismiss ? "pr-1.5" : ""}`;
    const dismissButtonStyle = `flex-shrink-0 ml-2 h-4 w-4 rounded-full inline-flex items-center justify-center ${dismissColor} ${dismissHoverBg} focus:outline-none ${dismissFocusBg} focus:text-white`;
    if (icon !== undefined) {
        // eslint-disable-next-line no-console
        console.warn("icon is deprecated. Use iconName instead.");
    }
    const convertedIcon = iconName !== undefined && !Array.isArray(iconName)
        ? needsConversion(iconName)
            ? convertIcon(iconName)
            : iconName
        : iconName !== undefined && Array.isArray(iconName)
            ? iconName
            : undefined;
    return (_jsx("div", { id: id, "data-test-id": testId, className: "flex", "data-eco-component": "pill", children: _jsxs("div", { className: pillStyle, children: [iconName !== undefined && (_jsx("span", { className: "mr-2", children: _jsx(IconV2, { type: "fa", icon: {
                            icon: convertedIcon,
                            fixedWidth: true,
                            size: "lg",
                        } }) })), _jsx("span", { children: message }), showDismiss && onDismiss ? (_jsxs("button", { type: "button", className: `${dismissButtonStyle} ${variantButtonFontColor} ${dismissHoverText}`, onClick: (e) => onDismiss(e), children: [_jsx("span", { className: "sr-only", children: "Dismiss" }), _jsx("svg", { className: `h-2 w-2`, stroke: "currentColor", fill: "none", viewBox: "0 0 8 8", children: _jsx("path", { strokeLinecap: "round", strokeWidth: "1.5", d: "M1 1l6 6m0-6L1 7" }) })] })) : ("")] }) }));
};
export default Pill;
