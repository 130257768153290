import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useState, useEffect, useRef } from "react";
import { useDropzone, ErrorCode, } from "react-dropzone";
import { IconV2 } from "../../icon_v2";
import SelectedFile from "./SelectedFile";
import DZErrors from "./DZErrors";
export const FileUpload = ({ id, testId, name, fileTypesText, fileTypes, maxSize, maxSizeText, maxFiles, maxFilesText, fileSizeError, fileTypeError, fileAmountError, template, onChange, onRemove, }) => {
    let acceptedFileTypes = fileTypes ? fileTypes : undefined;
    // Improve: Required Interface, if fileTypes provided then fileTypesText becomes required.
    // https://bobbyhadz.com/blog/typescript-make-property-required
    if (fileTypes !== undefined && fileTypesText === undefined) {
        console.warn("[ECO Components - FileUpload]: fileTypes was provided but was not provided fileTypesText to inform user of specific types.");
    }
    const [dzDisabled, setDZDisabled] = useState(false);
    const [dzErrors, setDZErrors] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [dragOver, setDragOver] = useState(false);
    const [changedFiles, setChangedFiles] = useState([]);
    const [fileAction, setFileAction] = useState(undefined);
    const prevSelectedFiles = useRef([]);
    const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone(Object.assign(Object.assign({ accept: acceptedFileTypes, disabled: dzDisabled, onDragOver: () => {
            setDragOver(true);
            // Keep previous state on re-renders.
            setDZErrors((prev) => [...prev]);
            setErrorList((prev) => [...prev]);
        }, onDrop: () => {
            setDragOver(false);
            // Keep previous state on re-renders.
            setDZErrors((prev) => [...prev]);
            setErrorList((prev) => [...prev]);
        }, onDragLeave: () => {
            setDragOver(false);
            // Keep previous state on re-renders.
            setDZErrors((prev) => [...prev]);
            setErrorList((prev) => [...prev]);
        }, noClick: true, maxSize }, (maxFiles !== undefined && { maxFiles })), { onDropRejected: (rejects) => {
            let errors = [];
            rejects.forEach((file) => {
                file.errors.forEach((fileErr) => {
                    if (!errors.includes(fileErr.code)) {
                        errors.push(fileErr.code);
                    }
                });
            });
            setDZErrors((prev) => [...prev, ...errors]);
            const errorMsgs = errors.map((error) => {
                if (error.includes("too-many-files")) {
                    return fileAmountError !== null && fileAmountError !== void 0 ? fileAmountError : "Too many files";
                }
                else if (error.includes("file-invalid-type")) {
                    return fileTypeError !== null && fileTypeError !== void 0 ? fileTypeError : "Invalid file type";
                }
                else if (error.includes("file-too-large")) {
                    return fileSizeError !== null && fileSizeError !== void 0 ? fileSizeError : "File size is too large";
                }
                return undefined;
            });
            setErrorList((prev) => [...prev, ...errorMsgs]);
        } }));
    useEffect(() => {
        if (selectedFiles.length !== prevSelectedFiles.current.length) {
            let formData = new FormData();
            selectedFiles.map((file, i) => {
                return formData.append(`file${i}`, file);
            });
            onChange(formData, { selectedFiles, type: fileAction, changedFiles });
            setChangedFiles([]);
            setFileAction(undefined);
        }
    }, [changedFiles]);
    useEffect(() => {
        if (fileRejections.length === 0) {
            setErrorList([]);
        }
    }, [fileRejections]);
    useEffect(() => {
        // Start CLEAN! This effect gets called when user is selected files.
        setDZErrors([]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const possibleTotalFiles = acceptedFiles.length + (selectedFiles.length || 0);
        // Check if current files + recently uploaded files doesn't exceed max file limit
        if (maxFiles !== undefined && possibleTotalFiles > maxFiles) {
            setDZErrors((prev) => [...prev, ErrorCode.TooManyFiles]);
            return;
        }
        // Update selected files -- acceptFiles is part of Dropzone API
        if (acceptedFiles.length > 0 && selectedFiles.length !== maxFiles) {
            setFileAction("add");
            setChangedFiles(acceptedFiles);
            setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
            if (possibleTotalFiles === maxFiles) {
                setDZDisabled(true);
            }
        }
    }, [acceptedFiles]);
    useEffect(() => {
        prevSelectedFiles.current = selectedFiles;
        if (maxFiles !== undefined && selectedFiles.length < maxFiles) {
            setDZDisabled(false);
        }
    }, [selectedFiles]);
    const handleRemove = (e) => {
        const { file } = e.currentTarget.parentElement.dataset;
        const removedFile = selectedFiles.find((selected) => {
            return selected.name === file;
        });
        if (removedFile) {
            if (onRemove) {
                onRemove(e, removedFile);
            }
            setChangedFiles([removedFile]);
            setFileAction("remove");
        }
        const filteredFiles = selectedFiles.filter((selected) => selected.name !== file);
        setSelectedFiles(filteredFiles);
    };
    const getFilesImageIcon = (fileType) => {
        if (fileType.includes("image")) {
            return "file-image";
        }
        return "file-spreadsheet";
    };
    const files = selectedFiles.map((file) => {
        return (_jsx("li", { "data-file": file.path, "data-testid": file.path, children: _jsx(SelectedFile, { path: file.path, size: file.size, icon: getFilesImageIcon(file.type), handleRemove: handleRemove }) }, file.path));
    });
    const rejectedFiles = fileRejections.map((rejection) => {
        const { file } = rejection;
        return (_jsx("li", { "data-file": file.name, children: _jsx(SelectedFile, { path: file.name, size: file.size, icon: getFilesImageIcon(file.type), handleRemove: handleRemove, errors: errorList }) }, file.name));
    });
    // Additional Stylings for border surrounding Dropzone
    let dragOverStyles = dragOver
        ? "border-gray-500"
        : "border-brand-accent dark:border-dark-brand-accent";
    return (_jsxs("section", { className: "", "data-test-id": testId, id: id, "data-eco-component": "fileupload", children: [_jsxs("div", Object.assign({}, getRootProps({
                className: `dropzone border-dashed bg-components-lighter dark:bg-dark-components-lighter text-tertiary dark:text-dark-tertiary w-full rounded-md border p-12 text-center text-sm shadow-sm transition ${dzDisabled ? "disabled" : ""} ${dragOverStyles}`,
            }), { children: [_jsxs("div", { className: `dd-ui mb-1 space-y-4 ${dzDisabled ? "opacity-25" : ""} flex flex-col items-center`, children: [_jsx("div", { className: " bg-components-light dark:bg-dark-components-light dark:text-dark-link text-brand-accent flex h-16 w-16 items-center justify-center rounded-full", children: _jsx(IconV2, { type: "fa", icon: {
                                        icon: ["far", "file-plus"],
                                        fixedWidth: true,
                                        size: "xl",
                                    } }) }), _jsx("input", Object.assign({}, getInputProps({ name, id }))), _jsxs("p", { className: "text-primaryText dark:text-dark-primaryText", children: ["Drag & drop or\u00A0", _jsx("button", { onClick: open, disabled: dzDisabled, "data-eco-component": "fileupload-button", className: "text-link dark:text-dark-link hover:text-link-hover dark:hover:text-dark-link-hover", children: "click to upload." })] })] }), template !== undefined && (_jsx("div", { children: _jsx("a", { href: template, children: "Download the template" }) })), _jsxs("ul", { className: "text-tertiary dark:text-dark-tertiary p-0", children: [fileTypesText !== undefined && (_jsx("li", { className: "mb-1", children: fileTypesText })), maxSize !== undefined && (_jsx("li", { className: "mb-1", children: maxSizeText !== null && maxSizeText !== void 0 ? maxSizeText : `Maximum file size: ${(maxSize / Math.pow(1024, 2)).toFixed(2)} MB` })), maxFiles !== undefined && (_jsx("li", { className: "mb-1", children: maxFilesText !== null && maxFilesText !== void 0 ? maxFilesText : `Maximum number of files:
              ${maxFiles}` }))] })] })), dzErrors.length > 0 && (_jsx(DZErrors, { errorCodes: dzErrors, maxFiles: maxFiles })), _jsxs("div", { className: "my-2 space-y-2", children: [files.length > 0 && (_jsx("ul", { "data-testid": "file-list", className: "space-y-2", children: files })), fileRejections !== undefined && fileRejections.length > 0 && (_jsx("ul", { "data-testid": "rejected-file-list", className: "space-y-2", children: rejectedFiles }))] })] }));
};
export default FileUpload;
FileUpload.displayName = "FileUpload";
