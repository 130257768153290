var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useRef, useState } from "react";
import { UserProvider as Auth0UserProvider } from "@auth0/nextjs-auth0/client";
import { useAuth } from "./auth";
import getConfig from "next/config";
import { isAuthConfigured } from "./utils/is-auth-configured";
import { UserWithAppsProvider } from "./AppsProvider";
import { useRouter } from "next/router";
import { useIdleTimer } from "react-idle-timer";
import AuthSegmentProvider, { useSocket, } from "./components/AuthSegmentProvider";
const UserWithoutApps = ({ errorPath, children }) => {
    const { user } = useAuth();
    const router = useRouter();
    useEffect(() => {
        if (user) {
            if (user.id !== "" &&
                Object.keys(user.appInfo).length === 0 &&
                router.query.redirected !== "true") {
                router.query.redirected = "true";
                void router.push(router);
            }
        }
    }, [errorPath, user, router]);
    return _jsx(React.Fragment, { children: children });
};
export const AuthContext = createContext({
    authEnabled: false,
    user: undefined,
    roles: [],
});
const AuthContextProvider = ({ children, }) => {
    const { user, roles } = useAuth();
    const socketio = useSocket();
    const onIdle = () => {
        socketio === null || socketio === void 0 ? void 0 : socketio.emit("session idle");
    };
    const onActive = () => {
        socketio === null || socketio === void 0 ? void 0 : socketio.emit("session active");
    };
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { start } = useIdleTimer({
        onIdle,
        onActive,
        timeout: 300000,
        startOnMount: false,
        startManually: true,
    });
    useEffect(() => {
        start();
    }, []);
    return (_jsx(AuthContext.Provider, { value: { authEnabled: true, user, roles }, children: children }));
};
export const RecipientsContext = createContext({
    users: [],
    groups: [],
    isLoaded: false,
});
export const UserProvider = ({ errorPath, showApps = true, initialSelectedAppName = "", appSwitcherLabel = "View as company", providedApps, handleParentAppChanges, callUsersEndpoint = false, children, }) => {
    const { publicRuntimeConfig } = getConfig();
    const { DTN_PRODUCT_CODE } = publicRuntimeConfig;
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tokenExp, setTokenExp] = useState(0);
    const router = useRouter();
    const useInterval = (callback, delay) => {
        const savedCallback = useRef();
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        // eslint-disable-next-line consistent-return
        useEffect(() => {
            const tick = () => {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            };
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };
    const updateSession = () => __awaiter(void 0, void 0, void 0, function* () {
        yield fetch(`${window.location.origin}${router.basePath}/api/auth/session`)
            // eslint-disable-next-line require-await
            .then((res) => __awaiter(void 0, void 0, void 0, function* () { return res.json(); }))
            .then((data) => {
            setTokenExp((prev) => data.timestamp || prev);
        })
            .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
    });
    useInterval(() => {
        if (tokenExp < Math.floor(Date.now() / 1000)) {
            void updateSession();
        }
    }, 10000);
    const getUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isAuthConfigured(getConfig()) && callUsersEndpoint) {
            yield fetch(`${window.location.origin}${router.basePath}/api/auth/users`)
                // eslint-disable-next-line require-await
                .then((res) => __awaiter(void 0, void 0, void 0, function* () { return res.json(); }))
                .then((data) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setUsers(data.data.recipients);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setGroups(data.data.groups);
                setIsLoaded(true);
            });
        }
    });
    useEffect(() => {
        if (callUsersEndpoint)
            void getUsers();
    }, []);
    if (DTN_PRODUCT_CODE === "") {
        return (_jsx(AuthContext.Provider, { value: { authEnabled: false, user: undefined, roles: [] }, children: children }));
    }
    return (_jsx(Auth0UserProvider, { profileUrl: `${router.basePath}/api/auth/me`, children: _jsx(AuthSegmentProvider, { children: _jsx(UserWithoutApps, { errorPath: errorPath, children: _jsx(UserWithAppsProvider, { appSwitcherLabel: appSwitcherLabel, showApps: showApps, initialSelectedAppName: initialSelectedAppName, providedApps: providedApps, handleParentAppChanges: handleParentAppChanges, children: _jsx(AuthContextProvider, { children: _jsx(RecipientsContext.Provider, { value: { users, groups, isLoaded }, children: children }) }) }) }) }) }));
};
