import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useState, useCallback, useEffect, useLayoutEffect, memo, } from "react";
import { parseISO, isValid } from "date-fns";
import ReactDatepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";
import { useAdvancedDatePicker } from "./AdvancedDatePickerProvider";
import { Button } from "../../button";
import Timepicker from "react-time-picker";
import { IconV2 } from "../../icon_v2";
import { Label } from "../../form/label";
import "./time.css";
const Calendar = ({ i18nStrings, showTimePicker = true, startOfWeek, dateFormat = "yyyy-MM-dd", pickerValue, maxDate, minDate, dateRangeOptions, onChange: onDateChanged, }) => {
    var _a, _b;
    const { value, handleDateChangedValue, handleDropdownState, handleSelectedSuggestion, } = useAdvancedDatePicker();
    const [formatRangeValue, setFormatRangeValue] = useState({
        startDate: "",
        endDate: "",
    });
    if ((value === null || value === void 0 ? void 0 : value.startDate) instanceof Date)
        value.startDate = value.startDate.toISOString();
    if ((value === null || value === void 0 ? void 0 : value.endDate) instanceof Date)
        value.endDate = value.endDate.toISOString();
    const [startDate, setStartDate] = useState(value ? parseISO(value.startDate) : new Date());
    const [endDate, setEndDate] = useState(value ? parseISO(value.endDate) : null);
    const [startTime, setStartTime] = useState(value ? new Date(value.startDate).toTimeString() : null);
    const [endTime, setEndTime] = useState(value ? new Date(value.endDate).toTimeString() : null);
    const onChange = useCallback((dates) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        handleDateChangedValue(null);
    }, [handleDateChangedValue]);
    useEffect(() => {
        if (endDate !== null) {
            const startDateString = startDate.toDateString();
            const endDateString = endDate.toDateString();
            const startTimeString = startTime === null || startTime === void 0 ? void 0 : startTime.toLocaleString();
            const endTimeString = endTime === null || endTime === void 0 ? void 0 : endTime.toLocaleString();
            const pendingStart = new Date(`${startDateString} ${startTimeString !== null && startTimeString !== void 0 ? startTimeString : "00:00:00"}`);
            const pendingEnd = new Date(`${endDateString} ${endTimeString !== null && endTimeString !== void 0 ? endTimeString : "23:59:59"}`);
            const now_utc_start = Date.UTC(pendingStart.getUTCFullYear(), pendingStart.getUTCMonth(), pendingStart.getUTCDate(), pendingStart.getUTCHours(), pendingStart.getUTCMinutes(), pendingStart.getUTCSeconds());
            const now_utc_end = Date.UTC(pendingEnd.getUTCFullYear(), pendingEnd.getUTCMonth(), pendingEnd.getUTCDate(), pendingEnd.getUTCHours(), pendingEnd.getUTCMinutes(), pendingEnd.getUTCSeconds());
            const localStartDate = new Date(now_utc_start).toISOString();
            const localEndDate = new Date(now_utc_end).toISOString();
            setFormatRangeValue({
                startDate: localStartDate,
                endDate: localEndDate,
            });
        }
    }, [startDate, endDate, startTime, endTime]);
    // Setting default date range value
    useLayoutEffect(() => {
        if (dateRangeOptions === null || dateRangeOptions === void 0 ? void 0 : dateRangeOptions.setDefault) {
            setStartDate(dateRangeOptions.startDate);
            setEndDate(dateRangeOptions.endDate);
            setStartTime(dateRangeOptions.startTime);
            setEndTime(dateRangeOptions.endTime);
        }
    }, [dateRangeOptions]);
    const onApply = () => {
        // check if dates are valid
        if (isValid(parseISO(formatRangeValue.startDate)) &&
            isValid(parseISO(formatRangeValue.endDate))) {
            handleDateChangedValue(formatRangeValue);
            handleDropdownState(false);
            handleSelectedSuggestion({ amount: null, unit: null });
            onDateChanged(formatRangeValue);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ReactDatepicker, { selected: startDate,
                value: pickerValue,
                onChange,
                startDate,
                endDate,
                selectsRange: true,
                inline: true,
                dateFormat,
                calendarStartDay: startOfWeek,
                minDate,
                maxDate }), _jsx("div", { className: "flex px-3 py-3", children: showTimePicker && (_jsxs("div", { className: "date-range-picker-time flex", children: [_jsx("div", { className: "mr-3 w-full", children: _jsx(Label, { label: (_a = i18nStrings === null || i18nStrings === void 0 ? void 0 : i18nStrings.startTimeLabel) !== null && _a !== void 0 ? _a : "Start time", name: "daterange-picker-start-time", children: _jsx(Timepicker, { value: startTime, onChange: (val) => setStartTime(val), hourPlaceholder: "hh", minutePlaceholder: "mm", secondPlaceholder: "ss", disableClock: true, nativeInputAriaLabel: "daterangepicker-start-time", clearIcon: _jsx(IconV2, { type: "fa", icon: {
                                            icon: ["far", "xmark"],
                                            fixedWidth: true,
                                            size: "lg",
                                        } }), amPmAriaLabel: "Select AM/PM", hourAriaLabel: "Hour", minuteAriaLabel: "Minute", clearAriaLabel: "Clear Value", clockAriaLabel: "Toggle Clock" }) }) }), _jsx("div", { className: "w-full", children: _jsx(Label, { label: (_b = i18nStrings === null || i18nStrings === void 0 ? void 0 : i18nStrings.endTimeLabel) !== null && _b !== void 0 ? _b : "End time", name: "daterange-picker-end-time", children: _jsx(Timepicker, { value: endTime, onChange: (val) => setEndTime(val), hourPlaceholder: "hh", minutePlaceholder: "mm", secondPlaceholder: "ss", disableClock: true, nativeInputAriaLabel: "daterangepicker-end-time", clearIcon: _jsx(IconV2, { type: "fa", icon: {
                                            icon: ["far", "xmark"],
                                            fixedWidth: true,
                                            size: "lg",
                                        } }), amPmAriaLabel: "Select AM/PM", hourAriaLabel: "Hour", minuteAriaLabel: "Minute", clearAriaLabel: "Clear Value", clockAriaLabel: "Toggle Clock" }) }) })] })) }), _jsxs("div", { className: "mt-3 flex flex-row-reverse border-t pt-3", children: [_jsx(Button, { variant: "primary", onClick: onApply, children: "Apply" }), _jsx("div", { className: "mr-3" }), _jsx(Button, { variant: "secondary", onClick: () => handleDropdownState(false), children: "Cancel" })] })] }));
};
export default memo(Calendar);
