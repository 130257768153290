/**
 * Reducer Enum & Actions
 */
export var WizardActions;
(function (WizardActions) {
    WizardActions["SET_STEPS"] = "SET_STEPS";
    WizardActions["INCREMENT_CURRENT_STEP"] = "INCREMENT_CURRENT_STEP";
    WizardActions["DECREMENT_CURRENT_STEP"] = "DECREMENT_CURRENT_STEP";
    WizardActions["ADD_COMPLETED_STEP"] = "ADD_COMPLETED_STEP";
    WizardActions["REMOVE_COMPLETED_STEP"] = "REMOVE_COMPLETED_STEP";
    WizardActions["SET_CURRENT_STEP"] = "SET_CURRENT_STEP";
    WizardActions["SET_CAN_CONTINUE"] = "SET_CAN_CONTINUE";
    WizardActions["SET_RESTRICTIVE"] = "SET_RESTRICTIVE";
})(WizardActions || (WizardActions = {}));
