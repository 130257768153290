import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../icon_v2";
import { FlyoutDisclosure } from "./FlyoutMenuDisclosure";
import { FlyoutMenuCheckbox } from "./FlyoutMenuCheckbox";
export const FlyoutMenu = ({ id, testId, menuItems }) => {
    const disclosureList = menuItems.map((i) => {
        if (i.options) {
            return {
                id: i.id,
                label: i.label,
                icon: i.icon,
                panel: (_jsx(_Fragment, { children: i.options.map((op, idx) => {
                        let borderTop = idx === 0 ? "border-t-2" : "";
                        let borderBottom = idx < i.options.length - 1 ? "border-b-2" : "";
                        return (_jsx("div", { className: `max-w-96 border-gray-200 ${borderTop} ${borderBottom} pl-12 pr-2`, children: _jsx(FlyoutMenuCheckbox, { value: op.value, label: op.label, checked: op.selected, onChange: i.onOptionChange }) }, `${op.value}-${idx}`));
                    }) })),
            };
        }
        return {
            id: i.id,
            label: i.label,
            icon: i.icon,
            onToggle: i.onToggle,
        };
    });
    return (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "flyout-menu-component", children: _jsx("div", { className: "group w-fit rounded border border-gray-200 bg-white", children: disclosureList.map((disc) => {
                if (disc.panel) {
                    return (_jsx("div", { className: "border-b border-gray-200 last:border-b-0", children: _jsx(FlyoutDisclosure, { id: disc.id, label: disc.label, icon: disc.icon, panel: disc.panel, onToggle: disc.onToggle }) }, disc.id));
                }
                return (_jsx("div", { className: "border-b border-gray-200 last:border-b-0", children: _jsxs("button", { className: "hover:text-link text-textPrimary grid h-[50px] grid-cols-[auto_0px] text-nowrap p-3 duration-150 group-hover:grid-cols-[auto_320px] [&>*:nth-child(2)]:overflow-hidden", onClick: disc.onToggle, children: [_jsx(IconV2, { type: "fa", icon: {
                                    icon: ["far", disc.icon],
                                    fixedWidth: true,
                                    size: "lg",
                                }, color: "base" }), _jsx("div", { className: "ml-3 flex flex-row justify-between text-left", children: disc.label })] }) }, disc.id));
            }) }) }));
};
