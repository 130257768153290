import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { IconV2 } from "../icon_v2";
import { Checkbox } from "../form";
import { SearchBar } from "../search-bar";
import { Button } from "../button";
import { Slider } from "../slider";
export const LayerSelector = ({ id, testId, layers, activeLayers, defaultSelectedLayerId, onSaveAsPreset, onSearch, onClear, onSelected, onOpacityChange, }) => {
    const uniqueGroups = [...new Set(layers.map((i) => i.group))];
    const defaultSelected = defaultSelectedLayerId !== null && defaultSelectedLayerId !== void 0 ? defaultSelectedLayerId : "radar-base-ref-precip-type-na-contours";
    const selectedLayerIds = activeLayers.map((l) => l.layerId);
    let layerData = [];
    uniqueGroups.forEach((g) => {
        const groupLayers = layers
            .filter((l) => l.group === g)
            .map((i) => {
            return {
                id: i.id,
                label: i.label,
            };
        });
        const formattedData = {
            group: g,
            layers: groupLayers,
        };
        layerData.push(formattedData);
    });
    const [openPanelIndex, setOpenPanelIndex] = useState(null);
    const showLayerOptions = (index) => {
        setOpenPanelIndex(openPanelIndex === index ? null : index);
    };
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-components": "eco-layer-selector", className: "bg-container-light dark:bg-dark-container-light\n      text-primaryText dark:text-dark-primaryText", children: [_jsx("div", { className: "px-2", children: _jsx(SearchBar, { id: "searchLayers", label: "Search Layers", name: "searchLayers", onChange: (e) => onSearch(e), onClear: (e) => onClear(e), placeholder: "Search Layers", hideLabel: true }) }), _jsx("div", { className: "my-5 h-96 overflow-y-auto overflow-x-hidden", children: layerData.map((lg) => {
                    return (_jsxs("div", { className: "flex flex-col space-y-2.5 pb-2.5 pl-2 pr-1", children: [_jsx("div", { className: "text-tertiary dark:text-dark-tertiary pb-1 pt-4", children: lg.group }), lg.layers.map((l, index) => {
                                var _a, _b;
                                return (_jsxs("div", { children: [_jsxs("div", { className: "text-primaryText dark:text-dark-primaryText flex justify-between", children: [_jsx("div", { className: "mr-2 w-[275px] text-wrap", children: l.label }), _jsxs("div", { className: "flex justify-end space-x-2 pr-0.5", children: [_jsx("button", { onClick: () => showLayerOptions(index), disabled: !selectedLayerIds.includes(l.id), children: selectedLayerIds.includes(l.id) ? (_jsx(IconV2, { type: "fa", icon: {
                                                                    icon: "gear",
                                                                    fixedWidth: true,
                                                                } })) : (_jsx(IconV2, { type: "fa", icon: {
                                                                    icon: "gear",
                                                                    fixedWidth: true,
                                                                }, color: "base-light" })) }), _jsx(Checkbox, { label: l.label, value: l.id, checked: selectedLayerIds.includes(l.id), hideLabel: true, onChange: (e) => onSelected(e), disabled: l.id === defaultSelected })] })] }), openPanelIndex === index && (_jsx("div", { className: "border-light dark:border-dark-light mt-2 border-b-2 border-t-2 pb-4 pr-2 pt-2", children: _jsx(Slider, { id: `opacity-${l.id}`, label: "Opacity", value: (_b = (_a = activeLayers.find((i) => i.layerId === l.id)) === null || _a === void 0 ? void 0 : _a.opacity) !== null && _b !== void 0 ? _b : 1, onChange: (value) => onOpacityChange(l.id, value), min: 0, max: 1, step: 0.1 }) }))] }, l.id));
                            })] }, lg.group));
                }) }), _jsx("div", { className: "pt-25 w-full px-2 pb-2", children: _jsx(Button, { variant: "secondary", onClick: (e) => onSaveAsPreset(e), children: "Save As Preset" }) })] }));
};
