var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * The Card Components is a container to hold content, usually within a list (grid, stacked list, etc).
 * It contains an optional header and footer along with the ability to add content to each.
 */
import { cloneElement } from "react";
export const Card = (_a) => {
    var { id, testId, item, background } = _a, props = __rest(_a, ["id", "testId", "item", "background"]);
    const backgroundStyle = background === "dark"
        ? "bg-components-lighter dark:bg-dark-components-lighter"
        : "bg-components-baseComponent dark:bg-dark-components-baseComponent";
    return (_jsx(_Fragment, { children: item ? (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "card", className: `border-light dark:border-dark-light text-primaryText dark:text-dark-primaryText divide-y divide-gray-200 rounded-sm border ${backgroundStyle}`, children: cloneElement(props.children, { item: item }) })) : (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "card", className: `border-light dark:border-dark-light text-primaryText dark:text-dark-primaryText rounded-sm border p-6 ${backgroundStyle}`, children: props.children })) }));
};
export default Card;
