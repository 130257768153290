import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { components } from "react-select";
import { IconV2 } from "../../icon_v2";
import { Checkbox } from "../checkbox";
// reference for getting search to work https://codesandbox.io/s/react-select-input-in-menulist-forked-tqhxgt?file=/example.js:3882-3988
const Option = (props) => {
    const { selectProps, data } = props;
    const { selectedIndidacatorInMenu } = selectProps;
    const { sublabel } = data;
    return (_jsx(components.Option, Object.assign({}, props, { children: _jsxs("div", { "data-test-id": `option-${props.label}`, className: `eco-option ${props.label === "Select All" &&
                "border-light dark:border-dark-light border-b"} flex w-full flex-row 
        ${selectedIndidacatorInMenu === "icon" && "justify-between"} 
        px-3 py-2`, children: [selectedIndidacatorInMenu === "checkbox" ? (_jsx(Checkbox, { label: "", hideLabel: true, checked: props.isSelected, value: props.label })) : (""), sublabel !== undefined && (_jsxs("label", { className: "leading-snug", children: [_jsx("span", { className: "font-bold", children: props.label }), _jsx("br", {}), _jsx("span", { className: "text-tertiary dark:text-dark-tertiary font-extralight", children: sublabel })] })), sublabel === undefined && _jsx("label", { children: props.label }), props.isSelected && selectedIndidacatorInMenu === "icon" ? (_jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "check"],
                        fixedWidth: true,
                        size: "lg",
                    } })) : ("")] }) })));
};
export default Option;
