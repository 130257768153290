import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "../icon";
import withDeprecation from "../helpers/deprecated";
import { IconV2 } from "../icon_v2";
export const Alert = withDeprecation(({ id, testId, message, heading, variant, dismissible, actions, onDismiss, }) => {
    const variantType = variant !== null && variant !== void 0 ? variant : "info";
    const showDismiss = dismissible !== null && dismissible !== void 0 ? dismissible : false;
    if (heading === undefined) {
        console.warn("Alert Component: the heading property will be required in the next release, please add one to the Alert component.");
    }
    let variantBgColor;
    let variantFontColor;
    let newIcon;
    let optionalHeading;
    switch (variantType) {
        case "success":
            variantBgColor =
                "bg-alerts-background-success dark:bg-dark-alerts-background-success";
            variantFontColor =
                "text-alerts-text-success dark:text-dark-alerts-text-success";
            newIcon = "circle-check";
            optionalHeading = "success";
            break;
        case "error":
            variantBgColor =
                "bg-alerts-background-error dark:bg-dark-alerts-background-error";
            variantFontColor =
                "text-alerts-text-error dark:text-dark-alerts-text-error";
            newIcon = "circle-exclamation";
            optionalHeading = "error";
            break;
        case "info":
        default:
            variantBgColor =
                "bg-alerts-background-information dark:bg-dark-alerts-background-information";
            variantFontColor =
                "text-alerts-text-information dark:text-dark-alerts-text-information";
            newIcon = "circle-info";
            optionalHeading = "Info";
            break;
    }
    const fontStyle = `text-sm font-bold mb-1 ${variantFontColor}`;
    const actionLinkStyle = `text-link hover:text-link-hover dark:text-dark-link dark:hover:text-dark-link-hover text-sm`;
    return (_jsx("div", { id: id, "data-test-id": testId, className: `border-dark dark:border-dark-dark bg-components-baseComponent dark:bg-dark-container-medium overflow-hidden rounded-lg border shadow`, "data-eco-component": "alert", children: _jsxs("div", { className: "flex w-full", children: [_jsxs("div", { className: `${variantBgColor} text-light dark:text-dark-light p-4`, "data-eco-component": "alert-variant", children: [_jsx(IconV2, { type: "fa", icon: { icon: ["far", newIcon], size: "xl" } }), _jsx(Icon, { icon: `fa-regular ${newIcon}`, size: "md" })] }), _jsxs("div", { className: "text-primaryText dark:text-dark-primaryText p-4", children: [_jsx("div", { className: fontStyle, children: heading !== null && heading !== void 0 ? heading : optionalHeading }), _jsx("div", { className: "mb-2 text-sm", children: message }), _jsxs("div", { className: "flex items-end gap-x-4", children: [actions === null || actions === void 0 ? void 0 : actions.map((action, index) => {
                                    return (_jsx("span", { className: actionLinkStyle, children: action }, index));
                                }), showDismiss ? (_jsx("button", { type: "button", "data-eco-component": "alert-close", onClick: (e) => onDismiss(e), className: actionLinkStyle, children: "Dismiss" })) : null] })] })] }) }));
});
export default Alert;
