import { jsx as _jsx } from "react/jsx-runtime";
import { countries } from "country-flag-icons";
import { dialCodes } from "./country-dial-codes";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Autocomplete } from "../form/autocomplete";
import { useWindowSize } from "usehooks-ts";
export const CountryCode = ({ id, testId, label, bgColor = "white", name, selected, disabled, hideLabel, optional, required, menuPortalTarget, invalid, placeholder, compact = false, labelPosition = "top", onChange, }) => {
    const { width } = useWindowSize();
    let supportedCountries = countries.map((code) => dialCodes.filter((d) => d.code === code)[0]);
    supportedCountries = supportedCountries.filter((e) => e !== undefined);
    supportedCountries = supportedCountries.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
    const options = supportedCountries.map((country) => {
        const flag = getUnicodeFlagIcon(country.code);
        return {
            label: `${flag} ${compact && width < 768 ? "" : country.name} ${country.dial_code}`,
            value: country.code,
            dialCode: country.dial_code,
        };
    });
    const [selectedValue] = options.filter((c) => c.value === selected);
    return (_jsx("div", { "data-eco-component": "country-code-component", children: _jsx(Autocomplete, { id: id, testId: testId, name: name, bgColor: bgColor, labelFor: label, hideLabel: hideLabel, menuPortalTarget: menuPortalTarget, isDisabled: disabled, invalid: invalid, value: selectedValue, placeholderText: placeholder, labelPosition: labelPosition, required: required, optional: optional, options: options, onChange: onChange }) }));
};
