var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-console */
import React, { useState, useEffect, createElement, } from "react";
import ReactDOMServer from "react-dom/server";
import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
export const Paragraph = ({ as = "p", align = "left", testId, id, children, }) => {
    const [Content, setContent] = useState(_jsx(_Fragment, {}));
    const Element = as;
    let textAlign;
    switch (align) {
        case "left":
            textAlign = "text-left";
            break;
        case "right":
            textAlign = "text-right";
            break;
        default:
            textAlign = "text-center";
    }
    useEffect(() => {
        let markup = ReactDOMServer.renderToStaticMarkup(children);
        unified()
            .use(rehypeParse, { fragment: true })
            .use(rehypeReact, {
            createElement,
            Fragment: React.Fragment,
            components: {
                a: (_a) => {
                    var props = __rest(_a, []);
                    return (_jsx("a", Object.assign({}, props, { className: "text-link dark:text-dark-link dark:hover:text-dark-link/hover hover:text-link/hover font-medium", children: props.children })));
                },
            },
        })
            .process(markup)
            .then((file) => {
            setContent(file.result);
        })
            .catch((error) => {
            console.warn(error);
        });
    }, [children]);
    return (_jsx(Element, { id: id, "data-test-id": testId, "data-eco-component": "paragraph", className: `text-secondary dark:text-dark-secondary ${textAlign} text-sm leading-[1.7142857] md:text-base md:leading-7`, children: Content }));
};
