var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * The purpose of a Grid Component is to layout content in a grid format that is responsive.
 * The content can be any ReactElement.
 *
 * The Grid Component will require an array of structures and if no array is provided then an EmptyState
 * component should be displayed.
 *
 * The foundation for this will be a <ul> where the provided array is mapped over and <li>'s are generated
 * with some component inside.
 *
 * This is taken from TailwindUI (https://tailwindui.com/components/application-ui/lists/grid-lists) and is using
 * TailwindCSS Grid (https://tailwindcss.com/docs/grid-template-columns)
 */
import { cloneElement } from "react";
export const Grid = (_a) => {
    var { id, testId, list, gap, largeColCount = 3, mediumColCount = 2, smallColCount = 1, fillHeight = false } = _a, props = __rest(_a, ["id", "testId", "list", "gap", "largeColCount", "mediumColCount", "smallColCount", "fillHeight"]);
    const lgColCount = `lg:grid-cols-${largeColCount}`;
    const mdColCount = `md:grid-cols-${mediumColCount}`;
    const smColCount = `grid-cols-${smallColCount}`;
    const gridStyles = `grid gap-${gap !== undefined ? String(Math.abs(gap)) : "6"} ${smColCount} ${mdColCount} ${lgColCount}`;
    const fillHeightStyle = fillHeight ? "[&>*]:h-full" : "";
    return (_jsx(_Fragment, { children: list ? (_jsx("ul", { id: id, "data-test-id": testId, className: gridStyles, role: "list", "data-eco-component": "grid", children: list.map((content, index) => (_jsx("li", { className: `col-span-1 ${fillHeightStyle}`, children: cloneElement(props.children, { item: content }) }, index))) })) : (_jsx("div", { className: "flex w-full place-content-center p-8 text-sm text-gray-400", "data-eco-component": "grid", children: "no data available" })) }));
};
