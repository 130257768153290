"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
Font Awesome requires this config setting to prevent adding their embedded CSS to the <head> tag. We want to
do this because its impacting the size settings of our <Icon> component. Unfortunately, adding this
directly in a next app seems to do nothing but importing it from a package fixes it so we will need to
handle all Font Awesome config settings here.
*/
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
fontawesome_svg_core_1.config.autoAddCss = false;
