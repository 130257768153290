import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactSlider from "react-slider"; //https://github.com/zillow/react-slider
import "./slider.css";
export const Slider = ({ id, testId, value = 0, onChange, step = 1, min = 0, max = 100, label, disabled, hideLabel = false, trackClassName, thumbClassName, invert = false, renderThumb = (props) => {
    return _jsx("div", Object.assign({}, props));
}, }) => {
    // check for errors
    if (max < min) {
        console.warn("warning: max value for eco slider is less than the min value");
    }
    if (value > max || value < min) {
        console.warn("warning: value on eco slider is outside the slider range");
    }
    return (_jsxs("div", { "data-test-id": testId, "data-eco-component": "slider", className: "w-full pb-2", children: [_jsx("label", { id: id, className: "text-label dark:text-dark-label block text-sm font-medium", children: _jsx("span", { className: hideLabel ? "sr-only" : "", children: label }) }), _jsx(ReactSlider, { thumbClassName: thumbClassName !== null && thumbClassName !== void 0 ? thumbClassName : "eco-slider-thumb", thumbActiveClassName: "eco-slider-thumb-active", renderThumb: renderThumb, trackClassName: trackClassName !== null && trackClassName !== void 0 ? trackClassName : (typeof value === "number"
                    ? "eco-slider-track"
                    : "eco-slider-2thumb-track"), value: value, onChange: onChange, min: min, max: max, step: step, ariaLabelledby: id, disabled: disabled, invert: invert })] }));
};
export default Slider;
