export const getUserObject = (idToken) => {
    const userProfile = {
        customerId: idToken["https://auth.dtn.com/customerId"],
        requesterIp: idToken["https://auth.dtn.com/requesterIp"],
        availableApps: idToken["https://auth.dtn.com/availApps"],
        appInfo: idToken["https://auth.dtn.com/appInfo"],
        email: idToken.email,
        emailVerified: idToken.email_verified,
        updatedAt: idToken.updated_at,
        id: idToken.sub.replace("auth0|", ""),
        name: idToken.name,
        first_name: idToken.given_name,
        last_name: idToken.family_name,
    };
    return userProfile;
};
