import { useEffect, useState } from "react";
import { getUserRoles } from "./utils/get-user-roles";
import { getUserObject } from "./utils/get-user-object";
import { useUser } from "@auth0/nextjs-auth0/client";
import Cookies from "universal-cookie";
import { getUserAccounts } from "./utils/get-user-accounts";
export const useAuth = (customer) => {
    let initialState = {
        isLoading: true,
        user: undefined,
        roles: [],
    };
    const [auth, setAuth] = useState(initialState);
    const { user, isLoading } = useUser();
    const cookies = new Cookies();
    useEffect(() => {
        var _a;
        if (user) {
            const userProfile = getUserObject(user);
            let userRoles = getUserRoles(user);
            const accounts = getUserAccounts(user);
            const customerId = cookies.get("customer");
            if (customerId &&
                accounts.find((account) => account.customerId === customerId) &&
                accounts.length > 1 // Account for CustomerID with Multiple Accounts
            ) {
                userRoles = getUserRoles(user, customerId);
            }
            else if (accounts.length > 1) {
                cookies.set("customer", (_a = accounts[0]) === null || _a === void 0 ? void 0 : _a.customerId, {
                    path: "/",
                    expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
                });
            }
            else {
                cookies.remove("customer");
            }
            setAuth((prev) => (Object.assign(Object.assign({}, prev), { user: userProfile, roles: userRoles, isLoading })));
        }
        else {
            setAuth((prev) => (Object.assign(Object.assign({}, prev), { isLoading })));
        }
    }, [user, isLoading, customer]);
    return auth;
};
// pass in basepath from FE
export const login = (basePath) => {
    window.location.replace(`${window.location.origin}${basePath}/api/auth/login`);
};
export const logout = (basePath) => {
    window.location.replace(`${window.location.origin}${basePath}/api/auth/logout`);
};
