import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IconV2 } from "../../icon_v2";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const SelectAlt = ({ id, testId, invalid, selectedValue, onChange, options, required, disabled, readonly = false, }) => {
    let defaultValue = selectedValue !== null && selectedValue !== void 0 ? selectedValue : options[0].label;
    let [selectedVal, setSelectedVal] = useState(defaultValue);
    const handleOnChange = (val) => {
        const newSelectedVal = val;
        setSelectedVal(newSelectedVal);
        onChange(val);
    };
    if (readonly) {
        // eslint-disable-next-line no-param-reassign
        options = options === null || options === void 0 ? void 0 : options.map((option) => {
            return Object.assign(Object.assign({}, option), { disabled: true });
        });
    }
    useEffect(() => {
        setSelectedVal(defaultValue);
    }, [defaultValue]);
    return (_jsx("div", { id: id, "data-test-id": testId, className: "inline-block", children: _jsx(Listbox, { value: selectedVal, onChange: (value) => handleOnChange(value), children: ({ open }) => (_jsxs("div", { className: "relative w-full", children: [_jsxs(Listbox.Button, { className: "dark:bg-dark-container-baseContainer dark:text-dark-primaryText flex-inline relative inline-flex w-full cursor-default items-center text-left focus:outline-none", children: [_jsx("span", { className: "text-blue-500", children: selectedVal }), _jsx("span", { className: "pointer-events-none pl-1", children: _jsx(IconV2, { type: "fa", icon: { icon: ["far", "angle-down"] } }) })] }), _jsx(Listbox.Options, { className: "z-dropdown dark:bg-dark-container-light bg-container-light absolute mt-1 max-h-60 w-auto max-w-xs overflow-auto rounded-md p-1 text-base font-normal shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", children: _jsx(Transition, { show: open, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: options.map((option, i) => {
                                return (_jsx(Listbox.Option, { disabled: option.disabled, value: option.label, className: ({ active, selected }) => classNames(active && !selected ? "bg-blue-50" : "", selected ? "bg-blue-100" : "", option.disabled === true
                                        ? "text-tertiary dark:text-dark-tertiary"
                                        : "cursor-default ", option.disabled !== true && !active
                                        ? "text-primaryText dark:text-dark-primaryText"
                                        : "", "relative mb-1 select-none rounded-md p-2"), id: `option-${option.label}-${i}`, children: ({ active, selected }) => (_jsx(_Fragment, { children: _jsxs("div", { className: "content-center-center flex items-center justify-between gap-3 truncate py-0.5", children: [_jsx("span", { children: option.label }), selected && (_jsx(IconV2, { type: "fa", icon: {
                                                        icon: ["far", "check"],
                                                    } }))] }) })) }, `option-${option.label}-${i}`));
                            }) }) })] })) }) }));
};
export default SelectAlt;
