import { NextConfig } from "next";
import getConfig from "next/config";
import { useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
// import { useAuth } from "@eco/stratos-auth";

const { publicRuntimeConfig } = getConfig() as NextConfig;

const Datadog = () => {
  //   const { user, roles } = useAuth();

  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    datadogRum.init({
      applicationId:
        publicRuntimeConfig?.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
      clientToken:
        publicRuntimeConfig?.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      site: publicRuntimeConfig?.NEXT_PUBLIC_DATADOG_URL as string,
      service: "dtn-eco-documentation",
      env: publicRuntimeConfig?.ENV as string,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      premiumSampleRate: 10,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    setHasInitialized(true);
    datadogRum.startSessionReplayRecording();
  }, []);

  //   useEffect(() => {
  //     if (!hasInitialized) {
  //       return;
  //     }

  //     if (user === undefined) {
  //       datadogRum.removeUser();
  //       datadogRum.stopSessionReplayRecording();
  //       return;
  //     }

  //     if (roles.length === 0) {
  //       return;
  //     }

  //     datadogRum.setUser({
  //       id: user.id,
  //       name: currentUser.name,
  //       email: user.email,
  //       customerId: user.customerId?.join(", "),
  //       roles: roles.join(", "),
  //     });
  //     datadogRum.startSessionReplayRecording();
  //   }, [user, currentUser, hasInitialized]);

  return <></>;
};

export default Datadog;
