import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @next/next/no-img-element */
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { weatherIcons } from "./weather-icons";
export const IconV2 = (props) => {
    var _a, _b;
    let iconSize;
    switch (props.size) {
        case "xxs":
            iconSize = "h-10 w-10";
            break;
        case "xs":
            iconSize = "h-12 w-12";
            break;
        case "sm":
            iconSize = "h-16 w-16";
            break;
        case "lg":
            iconSize = "h-24 w-24";
            break;
        case "xl":
            iconSize = "h-32 w-32";
            break;
        case "md":
        default:
            iconSize = "h-20 w-20";
            break;
    }
    let iconColor;
    switch (props.color) {
        case "white":
            iconColor = "text-white";
            break;
        case "red":
            iconColor = "text-red-600";
            break;
        case "orange":
            iconColor = "text-orange-500";
            break;
        case "amber":
            iconColor = "text-amber-500";
            break;
        case "yellow":
            iconColor = "text-amber-500";
            break;
        case "lime":
            iconColor = "text-lime-500";
            break;
        case "green":
            iconColor = "text-green-500";
            break;
        case "emerald":
            iconColor = "text-emerald-500";
            break;
        case "teal":
            iconColor = "text-teal-500";
            break;
        case "cyan":
            iconColor = "text-sky-500";
            break;
        case "sky":
            iconColor = "text-sky-500";
            break;
        case "blue":
            iconColor = "text-blue-500";
            break;
        case "indigo":
            iconColor = "text-indigo-500";
            break;
        case "violet":
            iconColor = "text-indigo-500";
            break;
        case "purple":
            iconColor = "text-purple-500";
            break;
        case "fuchsia":
            iconColor = "text-purple-500";
            break;
        case "pink":
            iconColor = "text-pink-500";
            break;
        case "rose":
            iconColor = "text-rose-500";
            break;
        case "slate":
            iconColor = "text-slate-500";
            break;
        case "gray":
            iconColor = "text-gray-500";
            break;
        case "primary":
            iconColor = "text-blue-500";
            break;
        case "positive":
            iconColor = "text-green-500";
            break;
        case "negative":
            iconColor = "text-red-500";
            break;
        case "caution":
            iconColor = "text-orange-500";
            break;
        case "warning":
            iconColor = "text-warning-500";
            break;
        case "base":
            iconColor = "text-gray-500";
            break;
        case "base-light":
            iconColor = "text-gray-300";
            break;
        default:
            iconColor = "text-current";
            break;
    }
    const customStyle = `${iconSize} ${iconColor}`;
    // workaround for hydration errors with fontawesome and nextjs dev mode
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);
    // render the FontAwesomeIcon component
    if (props.type === "fa") {
        return (_jsx("div", { id: (_a = props.id) !== null && _a !== void 0 ? _a : props.icon.icon, suppressHydrationWarning: true, "data-eco-component": "icon-component", children: !loading ? (_jsx(FontAwesomeIcon, Object.assign({}, props.icon, { className: `${iconColor} block` }))) : ("") }, (_b = props.id) !== null && _b !== void 0 ? _b : props.icon.icon));
    }
    if (props.type === "weather") {
        const iconInfo = weatherIcons.find((icon) => icon.code === props.icon);
        if (iconInfo) {
            let variant = "";
            if (iconInfo.variant === true && props.variant === "night")
                variant = "_night";
            // the regular clear skies icon has a space instead of _ but the night variant does not...
            if (iconInfo.variant === true &&
                props.variant === "night" &&
                iconInfo.code === 1)
                iconInfo.icon = "clear_skies";
            return (_jsx("div", { id: props.id, "data-eco-component": "icon-component", children: _jsx("img", { src: `https://static-assets.dtn.com/icons/weather-conditions/v1/${iconInfo.icon}${variant}.svg`, alt: iconInfo.description, className: iconSize }) }, props.id));
        }
        console.log("Icon with the provided weather code could not be found.");
        return null;
    }
    // render a basic image with the static assets svg.
    return (_jsx("div", { id: props.id, "data-eco-component": "icon-component", children: _jsx("img", { src: props.icon, className: `inline-flex items-center align-middle ${customStyle}`, alt: props.altText }) }, props.id));
};
