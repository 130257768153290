import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useRef } from "react";
import { IconV2 } from "../icon_v2";
import "./flyoutRadiogroupRadio.css";
export const FlyoutRadiogroupRadio = ({ id, testId, value, label, selected, thumbnail, icon, group, index, }) => {
    let ref = useRef(null);
    useEffect(() => {
        let radio = ref.current;
        if (selected === value) {
            radio.setAttribute("checked", "");
        }
        else {
            radio.removeAttribute("checked");
        }
    }, [selected, value]);
    let containerStyle = "h-[78px] w-16 cursor-pointer rounded-md border-2 border-gray-100 shadow-md bg-white hover:border-blue-600 " +
        (index !== 0 ? "ml-0.5" : "");
    return (_jsx("div", { id: id, "data-test-id": testId, className: containerStyle, children: _jsxs("label", { className: "flyout-radio-container flex h-full w-full flex-col", children: [_jsx("input", { ref: ref, type: "radio", name: group, value: value, checked: selected === value ? true : false, readOnly: true }), _jsx("span", { className: "eco-checkmark-flyout-radiogroup" }), _jsx("div", { className: "h-11 self-center", children: thumbnail !== undefined ? (_jsx("img", { src: thumbnail, alt: "thumbnail", className: "h-11 w-16 rounded-t" })) : icon !== undefined ? (_jsx("div", { className: "py-1", children: _jsx(IconV2, { type: "fa", icon: {
                                icon: ["far", icon],
                                size: "2x",
                                fixedWidth: true,
                            }, color: "base" }) })) : (_jsx("div", { className: "py-1", children: _jsx(IconV2, { type: "fa", icon: { icon: ["far", "image"], size: "2x", fixedWidth: true }, color: "base" }) })) }), _jsx("div", { className: "text-textPrimary w-15 h-full border-t-2 border-gray-100 text-center text-xs font-light", children: _jsx("span", { className: "flex h-full w-full items-center justify-center", children: label }) })] }) }));
};
