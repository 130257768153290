var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../icon_v2";
export const SegmentControlButton = (_a) => {
    var { label, iconName, selected, onClick, onBlur, onFocus } = _a, props = __rest(_a, ["label", "iconName", "selected", "onClick", "onBlur", "onFocus"]);
    return (_jsxs("button", Object.assign({}, props, { className: `${selected !== undefined && selected
            ? "bg-container-light dark:bg-dark-container-light border-dark border shadow-sm"
            : "hover:bg-components-medium hover:dark:bg-dark-components-medium"} text-primaryText dark:border-dark-dark dark:text-dark-primaryText flex h-9 flex-auto items-center justify-center gap-x-2 rounded-md border border-transparent px-4 text-sm `, onClick: onClick, onBlur: onBlur, onFocus: onFocus, children: [iconName && (_jsx(IconV2, { type: "fa", icon: { icon: ["far", iconName], fixedWidth: true } })), label] })));
};
