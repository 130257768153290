import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const StatusIndicator = ({ id, testId, label = "", variant = "info", hideStatus = false, children, }) => {
    const translateInfo = children === undefined
        ? ""
        : `absolute translate-x-1/2 -translate-y-1/4 top-0 right-0`;
    const translateDot = children === undefined ? "" : `absolute translate-x-1/2 top-0 right-0`;
    const bgColor = "bg-brand-accent dark:bg-dark-brand-accent";
    const backgroundRing = children !== undefined
        ? "ring-2 ring-white dark:ring-dark-bg-container-baseContainer"
        : "";
    return (_jsxs("div", { className: `${!hideStatus ? "relative w-min" : ""}`, children: [children, label && !hideStatus && (_jsx("span", { id: id, "data-test-id": testId, "data-eco-component": "status-indicator", className: `block ${translateInfo} ${backgroundRing} dark:text-dark-light min-w-[18px] rounded-full px-1.5 py-0.5 text-center text-xs leading-none text-white ${bgColor}`, children: label })), !label && !hideStatus && (_jsx("span", { id: id, "data-test-id": testId, "data-eco-component": "status-indicator", className: `block ${backgroundRing} ${translateDot} ${bgColor} h-2 w-2 rounded-full ` }))] }));
};
