import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconV2 } from "../icon_v2";
export const ApiError = ({ id, testId, error_code, error_title, error_message, button_label, button_handler, }) => {
    const buttonText = button_label !== null && button_label !== void 0 ? button_label : "Go back home";
    let borderStyle = "border border-transparent";
    let variantColorStyle = "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500 text-white";
    const buttonStyle = `inline-flex items-center self-center px-3 py-2 ${borderStyle} text-sm leading-4 font-medium rounded-md ${variantColorStyle} disabled:opacity-40 pointer-events-auto`;
    return (_jsx("div", { id: id, "data-test-id": testId, "data-eco-component": "api-error", className: "flex flex-col bg-transparent py-10", children: _jsxs("main", { className: "mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8", children: [_jsx("div", { className: "flex justify-center", children: _jsx("a", { href: "/", className: "text-brand-accent dark:text-dark-brand-accent flex h-20 w-20 content-center justify-center", children: _jsx(IconV2, { type: "fa", icon: { icon: ["far", "circle-exclamation"], size: "4x" } }) }) }), _jsx("div", { className: "py-8", children: _jsxs("div", { className: "text-center", children: [_jsxs("p", { className: "text-brand-accent dark:text-dark-brand-accent text-xl font-semibold uppercase tracking-wide", children: [error_code, " Error Type"] }), _jsx("h1", { className: "text-primaryText dark:text-dark-primaryText mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl", children: error_title }), _jsx("p", { className: "text-tertiary dark:text-dark-tertiary mt-2 text-base text-lg", children: error_message }), _jsx("div", { className: "mt-8", children: button_handler ? (_jsx("button", { id: "apiErrorActionButton", type: "button", onClick: () => button_handler(), className: buttonStyle, children: _jsx("span", { className: "pointer-events-auto flex", children: buttonText }) })) : (_jsx("a", { href: "/", "data-eco-component": "api-error-button", className: "bg-buttons-primary-bg-normal hover:bg-primary-bg-hover dark:bg-dark-buttons-primary-bg-normal dark:hover:bg-dark-primary-bg-hover text-buttons-primary-text rounded-md px-4 py-3", children: buttonText })) })] }) })] }) }));
};
export default ApiError;
