var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import { MenuOption } from "./MenuOption";
import { MenuAction } from "./MenuAction";
import { MenuLink } from "./MenuLink";
import { MenuTitle } from "./MenuTitle";
import { IconV2 } from "../icon_v2";
export const Menu = (_a) => {
    var { id, testId, align, icon, label, children } = _a, props = __rest(_a, ["id", "testId", "align", "icon", "label", "children"]);
    const position = align !== null && align !== void 0 ? align : "left";
    return (_jsx("div", { className: `text-${position}`, children: _jsxs(HeadlessMenu, { as: "div", className: "relative inline-block text-left text-gray-800", id: id, "data-test-id": testId, "data-eco-component": "menu", children: [_jsx(HeadlessMenu.Button, { className: "stratos-focus bg-transparents dark:text-dark-buttons-tertiary-text flex max-w-xs items-center rounded-md px-2 py-1 focus:ring-offset-2", children: icon !== undefined ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "sr-only", children: label }), _jsx(IconV2, { type: "fa", icon: { icon: icon, size: "sm" } })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: "font-bold", children: label }), _jsx("span", { className: "ml-2", children: _jsx(IconV2, { type: "fa", icon: { icon: "chevron-down", size: "sm" } }) })] })) }), _jsx(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsx(HeadlessMenu.Items, { as: "ul", className: `z-dropdown absolute ${position}-0 dark:bg-dark-components-baseComponent bg-components-baseComponent dark:ring-dark-light mt-2 min-w-[10rem] origin-top-right rounded-md px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`, children: React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, Object.assign(Object.assign({}, props), { as: "li" }));
                            }
                            return child;
                        }) }) })] }) }));
};
Menu.Option = MenuOption;
MenuOption.displayName = "Menu.Option";
Menu.Action = MenuAction;
MenuAction.displayName = "Menu.Action";
Menu.Link = MenuLink;
MenuLink.displayName = "Menu.Link";
Menu.Title = MenuTitle;
MenuTitle.displayName = "Menu.Title";
export default Menu;
