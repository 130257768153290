import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from "react";
import { useAuth } from "./auth";
import { getAppNameInfo } from "./utils/get-app-name-info";
export const AppsContext = createContext({
    showApps: false,
    appSwitcherLabel: "",
    initialSelectedAppName: "",
    apps: [],
    selectedApp: undefined,
    handleOnAppChange: () => { },
});
export const UserWithAppsProvider = ({ showApps, appSwitcherLabel, initialSelectedAppName, providedApps, handleParentAppChanges, children, }) => {
    const { user } = useAuth();
    const [selectedApp, setSelectedApp] = useState();
    let apps = [];
    if (providedApps === undefined) {
        if (user) {
            apps = getAppNameInfo(user);
        }
    }
    else {
        apps = providedApps;
    }
    const handleOnAppChange = (app) => {
        // Handle change within Context
        setSelectedApp(app);
        // Notify application about app changes
        if (handleParentAppChanges) {
            handleParentAppChanges(app);
        }
    };
    return (_jsx(AppsContext.Provider, { value: {
            showApps,
            apps,
            appSwitcherLabel,
            initialSelectedAppName,
            handleOnAppChange,
        }, children: children }));
};
export default UserWithAppsProvider;
