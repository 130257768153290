import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Label from "../label/Label";
import { IconV2 } from "../../icon_v2";
import "./select.css";
const SelectComponent = (props) => {
    var _a, _b;
    let backgroundColor;
    if (props.invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else if (props.bgColor === "grey" || props.bgColor === "gray") {
        backgroundColor = `bg-container-baseContainer dark:bg-dark-container-baseContainer`;
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    const invalidClasses = props.invalid === true ? ` form-field-invalid` : ``;
    const height = props.height === "sm" ? `py-[7px]` : `py-[13px]`;
    const disabledClasses = props.disabled === true ? `opacity-50 cursor-not-allowed` : ``;
    // const borderColor =
    //   props.readonly === true || props.disabled === true
    //     ? "border-dark dark:border-dark-dark"
    //     : "border-interactive dark:border-dark-interactive";
    let borderColor;
    if (props.invalid === true) {
        borderColor = "border-error";
    }
    else if (props.readonly === true || props.disabled === true) {
        borderColor = `border-dark dark:border-dark-dark`;
    }
    else {
        borderColor = `border-interactive dark:border-dark-interactive`;
    }
    // conditionally includ the aria labeled by attr if input is not nested in the label
    const labelPosition = (_a = props.labelPosition) !== null && _a !== void 0 ? _a : "top";
    const labelledby = labelPosition === "side" ? { "aria-labelledby": props.name } : {};
    return (_jsxs("div", { className: "relative flex items-center", "data-eco-component": "select", id: "eco-select", children: [_jsx("select", Object.assign({ className: `block w-full leading-none ${height} ${invalidClasses} ${disabledClasses} ${backgroundColor} ${borderColor} focus:border-focus text-primaryText dark:text-dark-primaryText rounded-md pl-3 pr-10 focus:ring-blue-600`, id: props.id, "data-test-id": props.testId, name: props.name, required: props.required, disabled: props.disabled, value: (_b = props.selected) !== null && _b !== void 0 ? _b : undefined, onChange: props.onChange, onFocus: props.onFocus, onBlur: props.onBlur }, labelledby, { children: props.children })), _jsx("span", { className: `text-secondary text-primaryText dark:text-dark-primaryText pointer-events-none absolute right-2 ${props.disabled === true ? "opacity-50" : ""}`, children: _jsx(IconV2, { type: "fa", icon: {
                        icon: ["far", "angle-down"],
                        fixedWidth: true,
                        size: "lg",
                    } }) })] }));
};
const SelectOption = ({ label, value, disabled }) => {
    return (_jsx("option", { disabled: disabled, value: value, children: label }));
};
const SelectOptGroup = ({ label, disabled, options, children, }) => {
    return (_jsx("optgroup", { label: label, disabled: disabled, children: Array.isArray(options)
            ? options.map((option, index) => {
                return (_jsx(SelectOption, { label: option.label, value: option.value, disabled: option.disabled }, index));
            })
            : children }));
};
export const Select = ({ id, testId, label, name, bgColor = "white", invalid, height = "md", selected, onChange, options, required, optional, hideLabel, disabled, readonly = false, onBlur, onFocus, labelPosition = "top", helperText, }) => {
    let [selectedVal, setSelectedVal] = useState(selected);
    const handleOnChange = (e) => {
        const newSelectedVal = e.target.value;
        setSelectedVal(newSelectedVal);
        onChange(e);
    };
    if (readonly) {
        options = options === null || options === void 0 ? void 0 : options.map((option) => {
            return Object.assign(Object.assign({}, option), { disabled: true });
        });
    }
    // Why is the initial `selected` value not being set on `useState`.
    // The correct value is being passed in.
    useEffect(() => {
        setSelectedVal(selected);
    }, [selected]);
    return (_jsxs("div", { id: id, "data-test-id": testId, children: [_jsx(Label, { name: name, label: label, hideLabel: hideLabel, required: required, optional: optional, labelPosition: labelPosition, disabled: disabled, htmlFor: `${id}-select`, children: _jsx(SelectComponent, { label: label, name: name, id: `${id}-select`, testId: `${testId}-select`, bgColor: bgColor, onChange: handleOnChange, selected: selectedVal, height: height, invalid: invalid, required: required, hideLabel: hideLabel, disabled: disabled, onBlur: onBlur, onFocus: onFocus, labelPosition: labelPosition, readonly: readonly, children: options === null || options === void 0 ? void 0 : options.map((option, index) => {
                        return !("value" in option) ? (_jsx(SelectOptGroup, { options: option.options, label: option.label, disabled: option.disabled }, index)) : (_jsx(SelectOption, { label: option.label, value: option.value, disabled: option.disabled }, index));
                    }) }) }), helperText !== undefined && invalid !== true && (_jsx("p", { className: "text-tertiary dark:text-dark-tertiary mt-1 text-xs", children: helperText }))] }));
};
