var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { FlyoutRadiogroupRadio } from "./FlyoutRadiogroupRadio";
import { IconV2 } from "../icon_v2";
import { Transition } from "@headlessui/react";
export const FlyoutRadiogroup = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const { id, testId, group, label, selected, thumbnail, icon, onChange } = props;
    const [showOptions, setShowOptions] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState(selected);
    const handleChange = (e) => {
        setSelectedRadio(e.target.value);
        onChange(e);
    };
    useEffect(() => {
        setSelectedRadio(selected !== null && selected !== void 0 ? selected : "");
    }, [selected]);
    const handleParentButtonClick = (e) => {
        setShowOptions(!showOptions);
    };
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "flyout-radiogroup-component", className: "inline-flex rounded-md shadow-sm", children: [_jsx(Transition, { show: showOptions, enter: "transform transition ease-in-out duration-500 sm:duration-700", enterFrom: "opacity-0 translate-x-12", enterTo: "opacity-100 translate-x-0", leave: "transition ease duration-300 transform", leaveFrom: "opacity-100 translate-x-0", leaveTo: "opacity-0 translate-x-12", children: _jsx("div", { id: "flyout-radiogroup-radios", role: "radiogroup", onChange: handleChange, "aria-labelledby": `rg${group}`, className: "flex flex-row rounded-md border-2 border-gray-100 bg-white", children: React.Children.map(children, (child, index) => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, {
                                group,
                                selected: selectedRadio,
                                index,
                            });
                        }
                        return child;
                    }) }) }), _jsx("button", { id: "flyout-radiogroup-button", onClick: handleParentButtonClick, className: "ml-2 h-20 w-20 cursor-pointer rounded-md border-2 border-gray-100 bg-white shadow-md hover:border-blue-600", children: _jsxs("div", { className: "flex h-full flex-col", children: [_jsx("div", { className: "h-11 self-center", children: thumbnail !== undefined ? (_jsx("img", { src: thumbnail, alt: "thumbnail", className: "h-11 w-20" })) : icon !== undefined ? (_jsx("div", { className: "pt-1.5", children: _jsx(IconV2, { type: "fa", icon: {
                                        icon: ["far", icon],
                                        size: "2x",
                                        fixedWidth: true,
                                    }, color: "base" }) })) : (_jsx("div", { className: "pt-1.5", children: _jsx(IconV2, { type: "fa", icon: {
                                        icon: ["far", "image"],
                                        size: "2x",
                                        fixedWidth: true,
                                    }, color: "base" }) })) }), _jsx("div", { className: "text-textPrimary w-19 h-full border-t-2 border-gray-100 text-center text-xs font-light", children: _jsx("span", { className: "flex h-full w-20 items-center justify-center", children: label }) })] }) })] }));
};
FlyoutRadiogroup.Radio = FlyoutRadiogroupRadio;
FlyoutRadiogroupRadio.displayName = "FlyoutRadiogroup.Radio";
