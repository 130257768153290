var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUserObject } from "./utils/get-user-object";
import { auth0 } from "./utils/auth0";
import Cookies from "universal-cookie";
export const protectedRoute = (options) => {
    return (ctx) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const auth = yield auth0();
        // redirect withPageAuthRequired from nextjs auth0 package if user is not logged in
        let userProps;
        if ((options === null || options === void 0 ? void 0 : options.returnTo) !== undefined) {
            const { returnTo } = options;
            userProps = yield auth.withPageAuthRequired({ returnTo })(ctx);
        }
        else {
            userProps = yield auth.withPageAuthRequired()(ctx);
        }
        const session = yield auth.getSession(ctx.req, ctx.res);
        let user = (session === null || session === void 0 ? void 0 : session.user)
            ? getUserObject(session.user)
            : undefined;
        if (user) {
            let app = undefined;
            let roles = undefined;
            let hasApp = false;
            let hasRole = false;
            if ((options === null || options === void 0 ? void 0 : options.restrictions) !== undefined) {
                let { restrictions } = options;
                if (typeof restrictions === "string") {
                    app = restrictions;
                }
                else if (typeof restrictions === "object") {
                    ({ app, roles } = restrictions);
                }
                if (app !== undefined) {
                    hasApp = user.availableApps.includes(app);
                }
                if (roles !== undefined && app !== undefined) {
                    const cookies = new Cookies(ctx.req.headers.cookie);
                    let appInfo = user.appInfo[app];
                    if (appInfo !== undefined &&
                        "customers" in appInfo &&
                        appInfo.customers.length > 0) {
                        if (appInfo.customers[0].roles.length > 0) {
                            let userRoles = appInfo.customers[0].roles;
                            const customer = cookies.get("customer");
                            if (customer) {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                userRoles = appInfo.customers.find((c) => c.customerId === customer).roles;
                            }
                            hasRole = roles.some((role) => userRoles.includes(role));
                        }
                    }
                    else {
                        if (appInfo !== undefined &&
                            "roles" in appInfo &&
                            appInfo.roles.length > 0) {
                            const userRoles = appInfo.roles;
                            hasRole = roles.some((role) => userRoles.includes(role));
                        }
                    }
                }
                // redirect to host or previous page on host if user doesn't meet restrictions
                const { headers } = ctx.req;
                if ((app !== undefined && !hasApp) ||
                    (roles !== undefined && !hasRole)) {
                    let redirectUrl = "/";
                    if (Object.prototype.hasOwnProperty.call(headers, "referer") &&
                        ((_b = (_a = headers.referer) === null || _a === void 0 ? void 0 : _a.includes(headers.host)) !== null && _b !== void 0 ? _b : false)) {
                        redirectUrl = headers.referer;
                    }
                    if (options.redirectTo !== undefined) {
                        redirectUrl = options.redirectTo;
                    }
                    return {
                        redirect: {
                            destination: redirectUrl,
                            permanent: false,
                        },
                    };
                }
            }
        }
        return Object.assign({}, userProps);
    });
};
