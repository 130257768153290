import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useAdvancedDatePicker } from "../AdvancedDatePickerProvider";
import { useUniqueId } from "../utils/useUniqueId";
import RadioButton from "./radio-button";
const RadioGroup = React.forwardRef(({ name, items, ariaLabel, ariaLabelledby, ariaDescribedby, ariaRequired, onChange, }, ref) => {
    const { selectedSuggestionRange } = useAdvancedDatePicker();
    const generatedName = useUniqueId("date-range-picker-radio-");
    return (_jsx("div", { role: "radiogroup", "aria-labelledby": `radio-group-${String(ariaLabelledby)}`, "aria-label": `radio-group-${String(ariaLabel)}`, "aria-describedby": `radio-group-${String(ariaDescribedby)}`, "aria-required": ariaRequired, children: items
            ? items.map((item, index) => {
                return (_jsx(RadioButton, { name: name !== null && name !== void 0 ? name : generatedName, selectedTimeRange: item.selectedTimeRange, label: item.label, checked: selectedSuggestionRange.amount ===
                        item.selectedTimeRange.amount &&
                        selectedSuggestionRange.unit === item.selectedTimeRange.unit, onChange: onChange }, `date-range-picker-radio-${index}`));
            })
            : [] }));
});
RadioGroup.displayName = "InternalRadioGroup";
export default RadioGroup;
