import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ReactDatepicker from "react-datepicker";
import { Label } from "../label";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";
import { IconV2 } from "../../icon_v2";
import { PatternFormat } from "react-number-format";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export const Datepicker = ({ id, testId, className, name, label, bgColor = "white", dateFormat, onChange, onBlur, onFocus, maskInput = false, maskFormat = "", mask, showMask = true, minDate, maxDate, selected, selectsStart, selectsEnd, excludeDates, filterDate, endDate, invalid, readonly, startDate, convertTimezone, calendarStartDay, labelPosition = "top", disabled, required, optional, helperText, }) => {
    const [value, setValue] = useState(selected);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let handleChange = (localDate, event) => {
        if (localDate) {
            let date = localDate.toUTCString();
            if (convertTimezone !== undefined) {
                const convertedDate = dayjs.tz(date, convertTimezone).utc().format();
                onChange(convertedDate, event);
            }
            else {
                onChange(date, event);
            }
        }
        setValue(localDate);
    };
    console.log("invalid", invalid);
    const backgroundColor = bgColor === "grey" || bgColor === "gray" ? "gray" : "";
    const invalidClasses = invalid === true ? "invalid" : "";
    // conditionally includ the aria labeled by attr if input is not nested in the label
    // const labelledby =
    //   labelPosition === "side" ? { "aria-labelledby": props.name } : {};
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "datepicker", className: `stratos-datepicker ${invalidClasses} ${backgroundColor}`, children: [_jsx(Label, { name: name, label: label, labelPosition: labelPosition, required: required, optional: optional, disabled: disabled, children: _jsxs("div", { className: "relative", children: [_jsx(ReactDatepicker, { id: id === undefined ? "datepicker-input" : `${id}-input`, name: name, customInput: maskInput ? (_jsx(PatternFormat, { format: maskFormat, mask: mask, allowEmptyFormatting: showMask })) : undefined, ariaLabelledBy: label, readOnly: readonly, className: className, onChange: handleChange, onBlur: onBlur, onFocus: onFocus, selected: selected ? selected : value, selectsStart: selectsStart, selectsEnd: selectsEnd, calendarStartDay: calendarStartDay, dateFormat: dateFormat, minDate: minDate, maxDate: maxDate, excludeDates: excludeDates, filterDate: filterDate, startDate: startDate, endDate: endDate, disabled: disabled, wrapperClassName: "w-full" }), _jsxs("span", { className: "absolute right-3 top-3 flex", children: [_jsx(IconV2, { type: "fa", icon: {
                                        icon: ["far", "calendar"],
                                        fixedWidth: true,
                                        size: "lg",
                                    } }), invalid === true && (_jsx(IconV2, { type: "fa", icon: {
                                        icon: ["far", "circle-exclamation"],
                                        fixedWidth: true,
                                        size: "lg",
                                    }, color: "red" }))] })] }) }), helperText !== undefined && invalid !== true && (_jsx("p", { className: "mt-1 text-xs text-gray-700", children: helperText }))] }));
};
export default Datepicker;
