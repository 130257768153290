import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TimelineSlider } from "./TimelineSlider";
import { IconV2 } from "../icon_v2";
import { Popover } from "@headlessui/react";
import { PopoverPanel } from "./PopoverPanel";
export const TimelinePlayer = ({ id, testId, isPlaying, timeline, currentTimecode, timezone, locale, onPlay, onStop, onChange, children, }) => {
    const userTimezone = timezone !== null && timezone !== void 0 ? timezone : "UTC";
    const userLocale = locale !== null && locale !== void 0 ? locale : "en-US";
    const childrenArray = React.Children.toArray(children);
    const [mapLegend, layerSelector, presets, mapSettings] = childrenArray;
    let chronologicalTimeline = useMemo(() => Object.keys(timeline)
        .sort()
        .map((t) => ({
        timecode: Number(t),
        frame: timeline[t],
    })), [timeline]);
    let currentTimecodeIndex = useMemo(() => chronologicalTimeline.findIndex((t) => t.timecode === currentTimecode), [currentTimecode, chronologicalTimeline]);
    let [currentValueIndex, setCurrentValueIndex] = useState(currentTimecodeIndex);
    // console.log("chronologicalTimeline, currentIndex: ", chronologicalTimeline, currentValueIndex, currentTimecodeIndex)
    let isLast = currentValueIndex === chronologicalTimeline.length - 1;
    let isFirst = currentValueIndex === 0;
    useEffect(() => {
        setCurrentValueIndex(currentTimecodeIndex);
    }, [currentTimecode, currentTimecodeIndex, timeline, chronologicalTimeline]);
    const sliderMarks = chronologicalTimeline.map((i) => i.timecode);
    // console.log("sliderMarks, currentIndex: ", sliderMarks, currentValueIndex, currentTimecodeIndex)
    const handleAnimation = useCallback(() => {
        if (!isPlaying) {
            onPlay();
        }
        else {
            onStop();
        }
    }, [isPlaying, onPlay, onStop]);
    const handleOnChange = (index) => {
        setCurrentValueIndex(index);
        onChange(index);
    };
    const handleForwardStep = () => {
        let nextIndex;
        if (isLast) {
            nextIndex = 0;
        }
        else {
            nextIndex = currentValueIndex + 1;
        }
        onChange(nextIndex);
    };
    const handleBackwardStep = () => {
        let prevIndex;
        if (isFirst) {
            prevIndex = chronologicalTimeline.length - 1;
        }
        else {
            prevIndex = currentValueIndex - 1;
        }
        handleOnChange(prevIndex);
    };
    const dateFormatter = new Intl.DateTimeFormat(userLocale, {
        day: "2-digit",
        month: "short",
        year: "numeric",
        timeZoneName: "short",
        timeZone: userTimezone,
    });
    const dayTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        weekday: "short",
        hour: "2-digit",
        hourCycle: "h23",
        minute: "numeric",
        timeZone: userTimezone,
    });
    const timeFormatter = new Intl.DateTimeFormat(userLocale, {
        hour: "2-digit",
        hourCycle: "h23",
        minute: "numeric",
        timeZone: userTimezone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleKeydown = (e) => {
        switch (e.key) {
            case "J":
            case "j":
                handleBackwardStep();
                break;
            case "L":
            case "l":
                handleForwardStep();
                break;
            case "P":
            case "p":
                if (isPlaying) {
                    onStop();
                }
                else {
                    onPlay();
                }
                break;
            default:
                break;
        }
    };
    const getTimeNowIndex = () => {
        const timeNow = Date.now();
        for (let i = 0; i < sliderMarks.length; i++) {
            if (timeNow > sliderMarks[i] && timeNow < sliderMarks[i + 1]) {
                return i;
            }
        }
        return undefined;
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeydown);
        return function cleanup() {
            document.removeEventListener("keydown", handleKeydown);
        };
    }, [isPlaying, onPlay, onStop, onChange, handleKeydown]);
    const buttonStyle = "border border-buttons-secondary dark:border-dark-buttons-secondary rounded-md p-2.5 hover:bg-buttons-secondary-bg-hover";
    const sliderPadding = getTimeNowIndex() !== undefined ? "pt-4 pb-2" : "pb-2";
    return (_jsxs("div", { id: id, "data-test-id": testId, "data-eco-component": "eco-timeline-player", className: "bg-container-light dark:bg-dark-container-light relative flex w-full flex-row items-center space-x-3 px-3 pb-6 pt-2", children: [_jsx("button", { onClick: handleBackwardStep, className: "hidden lg:block", id: "rewindButton", children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: "backward-step",
                                fixedWidth: true,
                                size: "xl",
                            } }), _jsx("span", { className: "sr-only", children: "Step Backward" })] }) }), _jsx("button", { onClick: handleAnimation, id: "playerButton", children: isPlaying ? (_jsxs("div", { className: "text-link dark:text-dark-link", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: "circle-stop",
                                fixedWidth: true,
                                size: "3x",
                            } }), _jsx("span", { className: "sr-only", children: "Stop Animation" })] })) : (_jsxs("div", { className: "text-link dark:text-dark-link", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: "circle-play",
                                fixedWidth: true,
                                size: "3x",
                            } }), _jsx("span", { className: "sr-only", children: "Play Animation" })] })) }), _jsx("button", { onClick: handleForwardStep, className: "hidden lg:block", id: "fastForwardButton", children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: "forward-step",
                                fixedWidth: true,
                                size: "xl",
                            } }), _jsx("span", { className: "sr-only", children: "Step Forward" })] }) }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-nowrap text-2xl font-bold", children: dayTimeFormatter
                            .format(new Date(sliderMarks[currentValueIndex]))
                            .replace(",", " ") }), _jsx("div", { className: "text-nowrap text-sm font-normal", children: dateFormatter
                            .format(new Date(sliderMarks[currentValueIndex]))
                            .replace(",", " ") })] }), _jsxs("div", { className: "mb-5 flex w-full flex-col", children: [getTimeNowIndex() !== undefined && (_jsx("div", { className: "-mt-4 pb-4", children: _jsx(TimelineSlider, { id: "invisibleSlider", min: 0, max: chronologicalTimeline.length - 1, value: getTimeNowIndex(), onChange: () => void 0, customMarks: [getTimeNowIndex()], disabled: true, hide: true, label: "now", renderMark: (props) => {
                                const index = getTimeNowIndex();
                                const time = sliderMarks[index];
                                return (_jsx("div", Object.assign({}, props, { children: _jsxs("div", { className: "text-secondary dark:text-dark-secondary -mt-1 ml-2 flex flex-col text-nowrap text-xs", children: ["Now -", " ", timeFormatter.format(new Date(time)).replace(",", " ")] }) })));
                            } }) })), _jsx("div", { className: sliderPadding, children: _jsx(TimelineSlider, { id: "timelineSlider", "data-testId": "timelineSlider-testId", min: 0, max: chronologicalTimeline.length - 1, value: currentValueIndex, onChange: (value) => {
                                handleOnChange(value);
                            }, label: "animation timeline", renderMark: (props) => {
                                // console.log("props: ", props)
                                const index = props.key
                                    ? props.key
                                    : currentTimecodeIndex;
                                return (_jsx("div", Object.assign({}, props, { children: _jsx("div", { className: "text-secondary dark:text-dark-secondary ml-2 flex flex-col pr-2 text-xs", children: timeFormatter
                                            .format(new Date(sliderMarks[index]))
                                            .replace(",", " ") }) })));
                            } }) })] }), _jsx("button", { children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                icon: "angle-up",
                                fixedWidth: true,
                                size: "xl",
                            } }), _jsx("span", { className: "sr-only", children: "TBD" })] }) }), _jsxs(Popover.Group, { className: "flex space-x-3", children: [_jsx(Popover, { className: "hidden lg:block", children: ({ open, close }) => (_jsxs(_Fragment, { children: [_jsx(Popover.Button, { className: `
                    ${open
                                        ? "bg-buttons-secondary-bg-active dark:bg-dark-buttons-secondary-bg-active"
                                        : ""}
                    ${buttonStyle}`, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                                    icon: "circle-info",
                                                    fixedWidth: true,
                                                    size: "xl",
                                                } }), _jsx("span", { className: "sr-only", children: "Legend" })] }) }), _jsx(Popover.Panel, { className: "absolute bottom-full left-0 z-10 mb-1 w-[52rem]", children: _jsxs("div", { className: "relative rounded-md border-2 border-slate-300 bg-white p-1", children: [mapLegend, _jsx("button", { onClick: close, className: "absolute right-4 top-7", children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                                                icon: "xmark",
                                                                fixedWidth: true,
                                                                size: "lg",
                                                            } }), _jsx("span", { className: "sr-only", children: "Close" })] }) })] }) })] })) }), _jsx(Popover, { children: ({ open, close }) => (_jsxs(_Fragment, { children: [_jsx(Popover.Button, { className: `
                  ${open
                                        ? "bg-buttons-secondary-bg-active dark:bg-dark-buttons-secondary-bg-active"
                                        : ""}
                  ${buttonStyle}`, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                                    icon: "layer-group",
                                                    fixedWidth: true,
                                                    size: "xl",
                                                } }), _jsx("span", { className: "sr-only", children: "Layer Group" })] }) }), _jsx(Popover.Panel, { className: "absolute bottom-full right-0 z-10 mb-1 w-[26.5rem]", children: _jsx("div", { className: "overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5", children: _jsx("div", { className: "relative bg-white px-3 py-2", children: _jsxs(PopoverPanel, { onDismiss: close, children: [layerSelector, presets, mapSettings] }) }) }) })] })) }), _jsx(Popover, { children: ({ open, close }) => (_jsxs(_Fragment, { children: [_jsx(Popover.Button, { className: `
                  ${open
                                        ? "bg-buttons-secondary-bg-active dark:bg-dark-buttons-secondary-bg-active"
                                        : ""}
                  ${buttonStyle}`, children: _jsxs("div", { className: "text-tertiary dark:text-dark-tertiary", children: [_jsx(IconV2, { type: "fa", icon: {
                                                    icon: "gear",
                                                    fixedWidth: true,
                                                    size: "xl",
                                                } }), _jsx("span", { className: "sr-only", children: "Map Settings" })] }) }), _jsx(Popover.Panel, { className: "absolute bottom-full right-0 z-10 mb-1 w-[26.5rem]", children: _jsx("div", { className: "overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5", children: _jsx("div", { className: "relative bg-white px-3 py-2", children: _jsxs(PopoverPanel, { selectedTab: "map-settings", onDismiss: close, children: [layerSelector, presets, mapSettings] }) }) }) })] })) })] })] }));
};
