import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ErrorCode } from "react-dropzone";
export const DZErrors = ({ errorCodes, maxFiles }) => {
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [fileTooLarge, setFileTooLarge] = useState(false);
    const [fileTooSmall, setFileTooSmall] = useState(false);
    const [tooManyFiles, setTooManyFiles] = useState(false);
    useEffect(() => {
        errorCodes.forEach((err) => {
            switch (err) {
                case ErrorCode.FileInvalidType:
                    setInvalidFileType(true);
                    break;
                case ErrorCode.FileTooLarge:
                    setFileTooLarge(true);
                    break;
                case ErrorCode.FileTooSmall:
                    setFileTooSmall(true);
                    break;
                case ErrorCode.TooManyFiles:
                    setTooManyFiles(true);
                    break;
                default:
                    break;
            }
        });
    }, [errorCodes, tooManyFiles]);
    return (_jsxs("div", { className: "mt-2", children: [invalidFileType && (_jsx("div", { className: "text-bold text-error dark:text-dark-error mb-2 text-lg", children: "Invalid file type." })), fileTooLarge && (_jsx("div", { className: "text-bold text-error dark:text-dark-error mb-2 text-lg", children: "File too large." })), fileTooSmall && (_jsx("div", { className: "text-bold text-error dark:text-dark-error mb-2 text-lg", children: "File too small." })), tooManyFiles && (_jsxs("div", { className: "text-bold text-error dark:text-dark-error mb-2 text-lg", children: ["Upload limit of ", maxFiles, " ", maxFiles !== undefined && maxFiles > 1 ? "files" : "file", "."] }))] }));
};
export default DZErrors;
