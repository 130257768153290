var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu } from "@headlessui/react";
import { IconV2 } from "../icon_v2";
export const MenuOption = (_a) => {
    var { children, href, description, label, newTab, icon, rightIcon } = _a, props = __rest(_a, ["children", "href", "description", "label", "newTab", "icon", "rightIcon"]);
    const target = newTab !== undefined ? "_blank" : "_self";
    const renderOption = (_jsxs(_Fragment, { children: [icon !== undefined && (_jsx("span", { className: "flex max-h-5 items-center pr-4", children: _jsx(IconV2, { type: "fa", icon: { icon: icon, size: "lg" } }) })), _jsxs("span", { className: "w-full text-base", children: [label, description !== undefined && (_jsx("span", { className: "block min-w-[16rem] text-xs text-gray-500", children: description }))] }), rightIcon !== undefined && (_jsx("span", { className: "flex max-h-5 items-center pl-4", children: _jsx(IconV2, { type: "fa", icon: { icon: rightIcon, size: "xs" } }) }))] }));
    return (_jsx(Menu.Item, Object.assign({}, props, { "data-eco-component": "menu-option", children: ({ active }) => href !== undefined ? (_jsx("a", { href: href, target: target, className: `${active
                ? "bg-buttons-tertiary-bg-hover dark:text-dark-primaryText dark:bg-dark-buttons-tertiary-bg-active"
                : ""} 
            dark:text-dark-primaryText flex min-h-11 items-center justify-between rounded-md px-2 py-1 text-gray-700`, children: renderOption })) : ({ renderOption }) })));
};
