import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useCounter } from "usehooks-ts";
import { Label } from "../form";
import { Button } from "./Button";
export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export const NumberStepper = ({ id, testId, label, name, placeholder, value, trailingText, trailingIconOnClick, min = 0, max, step, readOnly, helperText, hideLabel = false, required = false, optional, disabled = false, invalid, onChange, size, }) => {
    const dimensions = {
        sm: "w-36",
        md: "w-44",
        lg: "w-52",
    };
    const { count, setCount, increment, decrement } = useCounter(value || min);
    const decrementDisabled = disabled || count === min || readOnly === true;
    const incrementDisabled = disabled || (max !== undefined && count === max) || readOnly === true;
    const handleButtonDecrement = step !== undefined
        ? () => setCount((x) => x - step)
        : decrement;
    const handleButtonIncrement = step !== undefined
        ? () => setCount((x) => x + step)
        : increment;
    const borderClasses = invalid === true
        ? ` border-1 border-error dark:border-dark-error focus-within:ring-red-500 focus-within:border-red-500 `
        : ` focus-within:border-blue-500 border-interactive focus-within:ring-blue-500 `;
    let backgroundColor;
    if (invalid === true) {
        backgroundColor =
            "bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10";
    }
    else {
        backgroundColor = `bg-container-light dark:bg-dark-container-light`;
    }
    const handleInputOnChange = (e) => {
        // We require this method since the buttons interact with useCounter hook.
        const updatedValue = parseInt(e.target.value, 10);
        setCount(updatedValue);
        if (onChange !== undefined) {
            onChange(updatedValue);
        }
    };
    useEffect(() => {
        if (onChange) {
            onChange(count);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);
    const inputNumberContainerClasses = size !== undefined ? `${dimensions[size]}` : `w-full`;
    return (_jsxs("div", { "data-eco-component": "number-stepper", id: id, "data-test-id": testId, className: `number-stepper ${inputNumberContainerClasses}`, children: [_jsx(Label, { id: "label-number-stepper", name: name, label: label, required: required, optional: optional, hideLabel: hideLabel, disabled: disabled }), _jsxs("div", { className: ` relative flex items-center justify-between rounded-md border font-normal ${disabled
                    ? "border-dark opacity-50 focus:ring-0"
                    : ` ${borderClasses} ${backgroundColor} focus-within:ring-1`}`, role: "group", 
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex: 0, children: [_jsx(Button, { icon: "fa-regular fa-minus", onClick: handleButtonDecrement, disabled: decrementDisabled, arialabel: "Decrease Quantity" }), _jsx("div", { className: `h-11 grow rounded-none border border-x border-y-0 ${disabled ? "border-dark" : "border-interactive"}`, children: _jsxs("div", { className: `flex items-stretch`, children: [_jsx("input", { className: `text-primaryText dark:text-dark-primaryText h-11  w-full grow border-none bg-transparent [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none`, id: `${id !== null && id !== void 0 ? id : "number-stepper"}-input-field`, name: name, type: "number", placeholder: placeholder, 
                                    // Using 0 as backup, this prevents NaN issues on input field since it isn't of type="number"
                                    value: count, readOnly: readOnly, required: required, disabled: disabled, onChange: handleInputOnChange, tabIndex: 0, min: min, max: max, step: step, "aria-required": required, "aria-readonly": readOnly }), trailingText !== undefined && (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                _jsx("div", { className: classNames(`${backgroundColor} mr-3 flex items-center`, trailingIconOnClick ? "pointer-events-none" : ""), onClick: () => trailingIconOnClick === null || trailingIconOnClick === void 0 ? void 0 : trailingIconOnClick(), children: _jsx("span", { className: "text-primaryText dark:text-dark-primaryText", children: trailingText }) }))] }) }), _jsx(Button, { icon: "fa-regular fa-plus", onClick: handleButtonIncrement, disabled: incrementDisabled, arialabel: "Increase Quantity" })] }), helperText !== undefined && invalid !== true && (_jsx("p", { className: "text-tertiary dark:text-dark-tertiary mt-1 text-xs", children: helperText }))] }));
};
export default NumberStepper;
