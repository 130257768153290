import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./table.css";
export const Table = ({ id, testId, columns, data, options, onRowSelect, }) => {
    var _a, _b, _c, _d, _e, _f;
    const layout = (_a = options === null || options === void 0 ? void 0 : options.layout) !== null && _a !== void 0 ? _a : "default";
    const highlight = (_b = options === null || options === void 0 ? void 0 : options.highlight) !== null && _b !== void 0 ? _b : true;
    const headerAlignment = (_c = options === null || options === void 0 ? void 0 : options.headerAlignment) !== null && _c !== void 0 ? _c : "left";
    const dataAlignment = (_d = options === null || options === void 0 ? void 0 : options.dataAlignment) !== null && _d !== void 0 ? _d : "left";
    let sticky = (_e = options === null || options === void 0 ? void 0 : options.stickyHeader) !== null && _e !== void 0 ? _e : true;
    const verticalLine = (_f = options === null || options === void 0 ? void 0 : options.verticalLine) !== null && _f !== void 0 ? _f : false;
    // maxTableHeight won't work unless the Table Header is sticky.
    if ((options === null || options === void 0 ? void 0 : options.maxTableHeight) !== undefined && !sticky) {
        sticky = true;
    }
    const handleRowSelect = (rowData) => {
        if (onRowSelect) {
            onRowSelect(rowData);
        }
    };
    return (_jsx("div", { id: id, "data-test-id": testId, className: "overflow-y-auto", "data-eco-component": "table", children: _jsx("div", { className: "flex flex-col", children: _jsxs("table", { id: `${id}-table`, className: "stratos-table relative min-w-full table-fixed border-collapse", 
                // NOTE: There are solutions for creating a fixed height table that use the "style" below ...
                style: {
                    borderSpacing: 0,
                    display: (options === null || options === void 0 ? void 0 : options.maxTableHeight) !== undefined ? "block" : "table",
                    maxHeight: (options === null || options === void 0 ? void 0 : options.maxTableHeight) !== undefined
                        ? options.maxTableHeight
                        : "",
                    overflow: "auto",
                }, children: [_jsx("thead", { className: "relative", children: _jsx("tr", { className: "z-sticky", children: columns
                                .filter((col) => { var _a; return !(((_a = col.options) === null || _a === void 0 ? void 0 : _a.hidden) !== undefined && col.options.hidden); })
                                .map((column, idx) => {
                                let headerPaddingStyle = "px-4 py-2";
                                let textSize = "text-sm";
                                if (layout === "compact") {
                                    headerPaddingStyle = "px-2 py-1";
                                    textSize = "text-xs";
                                    if (idx === 0) {
                                        headerPaddingStyle = "pl-2 py-1 pr-1";
                                    }
                                }
                                let headerStyle = `sticky top-0 ${headerPaddingStyle} bg-components-light dark:bg-dark-components-light ${textSize} font-medium text-primaryText dark:text-dark-primaryText font-semibold backdrop-blur backdrop-filter text-${headerAlignment} first-of-type:rounded-tl-md last-of-type:rounded-tr-md`;
                                return (_jsx("th", { scope: "col", className: headerStyle, "data-eco-component": "table-header", id: column.accessor, children: column.header }, idx));
                            }) }) }), _jsx("tbody", { className: `divide-y ${(options === null || options === void 0 ? void 0 : options.maxTableHeight) !== undefined ? "overflow-auto" : ""}`, children: data.map((row, rowIdx) => {
                            let dataTRStyle = "bg-container-light dark:bg-dark-container-style";
                            if (highlight) {
                                dataTRStyle = `${dataTRStyle} border-b border-light dark:border-dark-light transition duration-300 ease-in-out [&:nth-child(even)>*]:bg-container-baseContainer dark:[&:nth-child(even)>*]:bg-dark-container-baseContainer [&:nth-child(odd)>*]:bg-container-light dark:[&:nth-child(odd)>*]:bg-dark-container-light`;
                            }
                            return (_jsx("tr", { className: dataTRStyle, children: columns
                                    .filter((col) => {
                                    var _a;
                                    return !(((_a = col.options) === null || _a === void 0 ? void 0 : _a.hidden) !== undefined &&
                                        col.options.hidden);
                                })
                                    .map((col, keyIdx) => {
                                    const key = col.accessor;
                                    let cellPaddingStyle = "px-4 py-2";
                                    let textSize = "text-sm";
                                    if (layout === "compact") {
                                        cellPaddingStyle = "px-2 py-1";
                                        textSize = "text-xs";
                                    }
                                    let verticalLineStyle = "";
                                    if (verticalLine) {
                                        verticalLineStyle =
                                            "border-light dark:border-dark-light border-r";
                                    }
                                    let dataCellStyle = `${cellPaddingStyle} break-normal ${textSize} text-primaryText dark:text-dark-primaryText text-${dataAlignment} border-light dark:border-dark-light ${verticalLineStyle}`;
                                    if (onRowSelect) {
                                        dataCellStyle = `${dataCellStyle} cursor-pointer`;
                                    }
                                    //@ts-expect-error row is unknown
                                    let rowData = row[key];
                                    let tdId = `${key}-${rowData}`;
                                    if (typeof rowData !== "string") {
                                        tdId = `${key}-${rowIdx}`;
                                    }
                                    return (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                                    _jsx("td", { className: dataCellStyle, onClick: () => handleRowSelect(row), id: tdId, children: rowData }, `key-${keyIdx}`));
                                }) }, rowIdx));
                        }) })] }) }) }));
};
export default Table;
